.swapConfirmDiv {
  margin: 107px auto;
}
.swapConfirmDivMob {
  margin: 75px auto 48px auto;
}
.confirmContent {
  font-family: "jura";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  color: #ffffff;
  margin-top: 48px;
}
.confirmContentMob {
  font-size: 21px;
  line-height: 25px;
}
.swappingEth {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  text-align: center;
  margin-top: 4px;
}
.swappingEthMob {
  font-size: 14px;
  line-height: 16px;
  margin-top: 12px;
}
.transactionConfirm {
  font-family: "Jura";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #d1d1d1;
  text-align: center;
  margin-top: 15px;
}
.transactionConfirmMob {
  font-size: 12px;
  line-height: 14px;
  margin-top: 29px;
}

.loaderList {
  display: flex;
  flex-flow: row wrap;
  position: relative;
}
.loaderList li {
  display: block;
}
.loaderCircle span {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
}
.loaderCircle span::before,
.loaderCircle span::after {
  content: "";
  display: block;
  position: absolute;
  margin: auto;
  height: 50px;
  width: 50px;
  border: 3px solid #02aab0;
  border-radius: 50%;
  opacity: 0;
  animation: loader-6-1 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
}
.loaderCircleMob span::before,
.loaderCircleMob span::after {
  height: 40px;
  width: 40px;
}

@keyframes loader-6-1 {
  0% {
    transform: translate3d(0, 0, 0) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1.5);
    opacity: 0;
  }
}
.loaderCircle span::after {
  -webkit-animation: loader-6-2 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s
    infinite;
  animation: loader-6-2 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s infinite;
}
@keyframes loader-6-2 {
  0% {
    transform: translate3d(0, 0, 0) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 0;
  }
}
.errorReason {
  color: #fd5252;
  font-family: "Jura";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
}
