.bodyMobile {
  padding: 16px;
  margin-top: 100px;
}
.bodyDesktop {
  margin-top: 172px;
  margin-left: 20.8%;
}
.beforeConnected {
  margin-top: 0;
  margin-left: 0;
}
.modal {
  z-index: 2;
  position: fixed;
  height: 100%;
}
.modalContent {
  max-width: 650px;
  width: 50%;
  min-width: 300px;
  min-height: 300px;
  height: fit-content;
  max-height: 650px;
  background-color: #17263d;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
