.popupMainDiv {
  margin: 49px auto;
}
.popupMainDivMob {
  margin: 18px auto;
}
.payEthPopup {
  border: 1px solid #02aab0;
  border-radius: 20px;
  padding-left: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 46px;
  margin-right: 39px;
}
.payEthPopupMob {
  border-radius: 10px;
  padding-left: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 17px;
  margin-right: 17px;
}
.popupPayingEth {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #99b2c6;
  padding-bottom: 4px;
}
.popupPayingCryptoDiv {
  display: flex;
  flex-direction: row;
  margin-right: 15px;
}
.userEnteredAmount {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
}
.popupPayingVal {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  margin-left: 10px;
}
.popupPayingValMob {
  font-size: 22px;
  line-height: 26px;
}
.getRUDPopup {
  border: 1px solid #02aab0;
  border-radius: 20px;
  padding-left: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 10px;
  margin-left: 46px;
  margin-right: 39px;
}
.getRUDPopupMob {
  margin-top: 5px;
  border-radius: 10px;
  padding-left: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 17px;
  margin-right: 17px;
}
.popupReceivingRUD {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #99b2c6;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #99b2c6;
  padding-bottom: 4px;
}
.popupRUDVal {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
}
.popupRUDValMob {
  font-size: 22px;
  line-height: 26px;
}

.popupRUDCrypto {
  margin-right: 15px;
}
.ethValueInfoDiv {
  display: flex;
  flex-direction: row;
  margin-left: 75px;
}
.ethValueInfoDivMob {
  margin-left: 40px;
}
.oneEthVal {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: #ffffff;
  margin-top: 8px;
}
.oneEthValMob {
  font-size: 12px;
  line-height: 14px;
}
.infoSquareIcon {
  margin-top: 7px;
  padding-right: 4px;
}
.infoSquareIconMob {
  margin-top: 2px;
  padding-right: 3px;
}
.infoIcon {
  width: 15px;
  height: 15px;
}
.infoIconMob {
  width: 9px;
  height: 9px;
}
.popupButtonsDiv {
  margin-right: 10px;
  margin-top: 69px;
  display: flex;
  justify-content: center;
}
.popupButtonsDivMob {
  justify-content: space-between;
  margin-top: 30px;
  margin-right: 17px;
}
.cancelSwap {
  border-radius: 4px;
  border: 1px solid #212c3d;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  color: #fd5252;
  width: 40%;
  padding: 9px 0px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 123.8%;
  letter-spacing: 0.035em;
  margin-right: 19px;
}

.cancelSwapMob {
  font-size: 14px;
  line-height: 17px;

  margin-right: 9px;
  margin-left: 19px;

  color: #ffffff;
  font-weight: 600;
  height: 30px;
}
.confirmSwap {
  border-radius: 4px;
  border: 1px solid #212c3d;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  color: #02aab0;
  padding: 9px 0px;
  width: 40%;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 123.8%;
  letter-spacing: 0.035em;
}

.confirmSwapMob {
  font-size: 14px;
  padding: 8px 0px;
  line-height: 17px;
  width: 44%;
  height: 30px;
  font-weight: 600;
  color: #ffffff;
  width: 40%;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popupContent {
  background: #17263d;
  border-radius: 10px;
  width: 40%;
  height: auto;
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
.firstFlex {
  display: flex;
}
.dollarSymbol {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
}
.equivalentRUDval {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: #ffffff;
  margin-top: 8px;
  margin-left: 5px;
}
.equivalentRUDvalMob {
  font-size: 12px;
  line-height: 14px;
  margin-left: 3px;
}
.rudContent {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: #ffffff;
  margin-top: 8px;
  margin-left: 5px;
}
.rudContentMob {
  font-size: 12px;
  line-height: 14px;
  margin-left: 3px;
}
.skeletonContainer {
  padding-top: 4px;
}
