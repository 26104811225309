.liquidationTable {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-sizing: border-box;
}
.borrowTableHeading {
  background: #1c2736;
  font-family: "Metrophobic";
  height: 64px;
  font-weight: 400;
  color: #9c99ae;
}
.borrowTableHeadingWeb {
  font-size: 16px;
  line-height: 160%;
  padding-top: 24px;
  padding-bottom: 16px;
}

.borrowTableNest1 {
  height: 64px;
  font-family: "Metrophobic";
  background: linear-gradient(180deg, #2f3c51 0%, #1f2d44 100%);
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  cursor: pointer;
}
.tableHeading {
  width: 100%;
}

td {
  text-align: center;
}
table {
  border-collapse: collapse;
  box-sizing: border-box;
}
.liquidateButton {
  padding: 6px 13px;
  margin-top: 21px;
  margin-bottom: 21px;
  background: linear-gradient(180deg, #2f3c51 0%, #1f2d44 100%);
  border: 1px solid rgb(111, 111, 111, 0.3);
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  border-radius: 4px;
}
.liquidateButton:hover {
  border: 1px solid #02aab0;
}
.noPaymentsDefaualtsContent {
  padding-left: 32px;
  padding-bottom: 40px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  padding-top: 41px;
  padding-left: 32px;
  padding-right: 20px;
}
.safeCollRatio {
  color: #02aab0;
}
.toolTipStyle {
  background-color: #384455;
  border-radius: 12px;
  width: 270px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: white;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
  z-index: 2;
  text-align: justify;
}
.unit {
  font-size: 12px;
}
