.returnUserMainDiv {
  padding: 14px 18px 14px 32px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
}
.returnUserMainDivMob {
  padding: 10px 0px 10px 21px;
  font-size: 21px;
  line-height: 26px;
}
.stakedRUDInfo,
.vestingSchedule,
.stakedDate,
.maturityDate,
.accuredEarning,
.noOfDaysRemaining {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.maturityDateInMobile {
  display: flex;
  flex-direction: column;
}
.detailsHeading {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: #9c99ae;
}
.detailsHeadingInMobile,
.detailsHeadingInMob {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #9c99ae;
}
.detailsDiv {
  margin-left: 32px;
  margin-right: 10%;
  display: flex;
  justify-content: space-between;
}
.stakedDateRemainingDaysContainer {
  display: flex;
  margin-left: 32px;
  justify-content: space-between;
}
.stakedDateRemainingDaysContainerMob {
  margin-left: 18px;
}
.details {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: #02aab0;
}
.accountButton {
  padding: 12px 31px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: rgba(165, 162, 184, 1);
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
}

.accountButtonMob {
  padding: 6px 25px;
  font-size: 13px;
  line-height: 16px;
  margin-right: 8px;
}
.mintButton {
  padding: 16px 35px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: white;
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 5px;
}
.mintButton:hover {
  color: #02aab0;
}
.mintButtonMob {
  padding: 6px 25px;
  font-size: 13px;
  line-height: 16px;
  margin-right: 0px;
}
.mintButtonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  gap: 15px;
  @media screen and (max-width: 992px) {
    justify-content: center;
  }
}
.buttonContainer {
  margin-top: 16px;
  padding-bottom: 29px;
}
.buttonContainerMob {
  padding-bottom: 0px;
}

.maturityDateStakedDate {
  display: flex;
  justify-content: space-between;
}
.accruedRewards,
.accruedRewardsForecasted {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 160%;
  margin-bottom: 4px;
  color: #ffffff;
  margin-left: 32px;
}

.accruedRewardsMob,
.accruedRewardsForecastedMob {
  margin-top: 0px;
  font-size: 14px;
  line-height: 160%;
  margin-left: 18px;
}
.accruedRewardsValue {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: #ffffff;
  margin-left: 32px;
}
.accruedRewardsValueMob {
  font-size: 16px;
  line-height: 160%;
  margin-left: 18px;
}
.stakingRewardsContainer {
  margin-top: 42px;
}
.stakingRewardsContainerMob {
  margin-top: 20px;
}
.progressiveBarHeading {
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;
}
.progressiveBarContainer {
  margin-left: 28px;
  padding-top: 10px;
  padding-left: 21px;
  padding-right: 21px;
  // width: 80%;
  padding-bottom: 18px;
  background: #222933;
  margin-top: 11px;
  border-radius: 5px;
}
.progressiveBarContainerMob {
  margin-left: 18px;
  margin-right: 12px;
  width: 100%;
  margin-top: 20px;
}
.accuredEarningsValue {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  color: #9c99ae;
}
.rewardVested {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.rewardVestedMob {
  font-size: 11px;
}
.questionMark {
  margin-left: 8px;
  margin-top: 6px;
}
.questionMarkMob {
  margin-left: 0px;
  margin-right: 16px;
  margin-top: 22px;
}

.toolTipStyle {
  background-color: #384455;
  border-radius: 12px;
  width: 200px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: white;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
  z-index: 5;
}
.stakeDetailsValue {
  font-size: 20px;
}
.stakeDetailsValueInMobile {
  font-size: 16px;
}
.stakeDetailsValueMob {
  font-size: 16px;
}
.stakeDetailsValueMobile {
  font-size: 11px;
}
.stakedRUDInfoInMobile {
  display: flex;
  justify-content: space-between;
  margin-left: 18px;
  margin-right: 20px;
}
.detailsMob {
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
}
.progressiveBarDetailsValue {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 13px;
  line-height: 160%;
  color: #9c99ae;
}
.radeTokenText {
  margin-left: 3px;
}
.stakeDetailsContainer {
  background: linear-gradient(180deg, #2f3c51 0%, #1f2d44 100%);
}
.headingContainer {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 12px 32px;
  @media screen and (max-width: 992px) {
    padding: 16px 16px 8px 18px;
  }
}

.upArrow {
  transform: rotate(270deg);
}
.upArrow:hover {
  cursor: pointer;
}
.stakingRewards {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 160%;
  color: #02aab0;
}
.stakingRewardsMob {
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
}
.rewardContainer {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 8px 32px;
  @media screen and (max-width: 992px) {
    padding: 0px 20px 8px 18px;
  }
}
.statusContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.statusContainerMob {
  margin-left: 18px;
  margin-top: 22px;
  margin-right: 20px;
}
.locked {
  color: #f9b959;
}
.unlocked {
  color: #31c5a0;
}

.maturityDateVestingSchedule {
  margin-left: 32px;
  display: flex;
  margin-top: 22px;
  gap: 92px;
}
.maturityDateVestingScheduleInMobile {
  display: flex;
  justify-content: space-between;
  margin-left: 18px;
  margin-right: 20px;
  gap: 0;
}
.stakeButtonInLockWeb {
  padding: 16px 71px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: #a5a2b8;
  border-radius: 4px;
}
.stakeButtonInLockWeb:hover {
  color: #fd5252;
}
.stakeButtonInUnlockWeb {
  padding: 16px 71px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);

  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  color: #fd5252;
  border-radius: 4px;
}
.stakeButtonInUnlockWeb:hover {
  color: white;
}
.stakeButtonInUnlock {
  padding: 9px 15px;
  font-size: 12px;
  line-height: 15px;
}
.stakeButtonInLock {
  padding: 9px 15px;
  font-size: 12px;
  line-height: 15px;
}

.tooltip {
  margin-bottom: -3px;
}
.note {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #f9b959;
  padding-bottom: 12px;
  padding-left: 21px;
}
.disabled {
  color: #a5a2b8;
}
.lastUpdated {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: #f9b959;
  padding-bottom: 12px;
  padding-top: 10px;
}
.accruedRewardsContainer,
.progressiveBarMainContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-right: 20px;
}
.progressiveBarMainContainerStakeComplete {
  display: grid;
  grid-template-columns: 90%;
  margin-right: 20px;
}
