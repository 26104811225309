.personalInformationMainDiv {
  background: #06152b;
}
.butttonContent {
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 30.7%;
}
.butttonContentMob {
  margin-right: 0%;
}
.stepsButton1 {
  padding: 3px 26px;
  border: 1px solid #02aab0;
  background: transparent;
  border-radius: 5px;
  margin-left: 21px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
}
.stepsButton1Mob {
  padding: 1px 17px;
}
.stepsButton2 {
  border: 1px solid #02aab0;
  background: transparent;
  padding: 3px 26px;

  border-radius: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: #ffffff;
}
.stepsButton2Mob {
  padding: 1px 17px;
}
.stepsButton3 {
  border: 1px solid #02aab0;
  background: transparent;
  padding: 3px 26px;

  border-radius: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: #ffffff;
}
.stepsButton3Mob {
  padding: 1px 17px;
}
.line {
  border-bottom: 1px solid #02aab0;
  width: 100%;
}
.stepsButton4 {
  border: 1px solid #02aab0;
  background: #02aab0;
  padding: 3px 26px;
  border-radius: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: #ffffff;
}
.stepsButton5 {
  border: 1px solid #02aab0;
  background: transparent;
  padding: 3px 26px;

  border-radius: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: #ffffff;
  margin-right: 21px;
}
.stepsButton4Mob,
.stepsButton5Mob {
  padding: 1px 17px;
}
.gasTrackerContainer {
  position: fixed;
  bottom: 10px;
  right: 10px;
  left: 10px;
}
.step1Text {
  font-family: "Jura";
  font-weight: 400;
  color: #99b2c6;
}
.step1TextWeb {
  margin-left: 21px;
  margin-top: 22px;
  font-size: 16px;
  line-height: 19px;
}
.step1TextMob {
  margin-left: 21px;
  margin-top: 25px;
  font-size: 15px;
  line-height: 18px;
}
.personalInfo {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  color: #02aab0;
}
.personalInfoWeb {
  margin-left: 21px;
  margin-top: 9px;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 15px;
}
.personalInfoMob {
  margin-top: 3px;
  margin-left: 21px;
  margin-bottom: 0px;
  font-size: 13px;
  line-height: 16px;
}
.uploadYourPassport {
  font-family: "Metrophobic";
  color: #ffffff;
}
.uploadYourPassportWeb {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-left: 21px;
}
.uploadYourPassportMob {
  font-size: 16px;
  line-height: 20px;
  margin-left: 21px;
  margin-top: 8px;
}
.passportFrontUpload,
.passportBackSideUpload,
.passportHoldingUpload {
  display: flex;
  justify-content: center;

  align-items: center;
}
.IDUploadMainContainer {
  border: 1px solid #02aab0;
  border-radius: 8px;
  padding-top: 15px;
  padding-left: 15px;
  width: 40%;
  height: 261px;
  @media screen and (max-width: 992px) {
    width: 91%;
  }
}
.KYCIDUploadMainContainer {
  border: 1px solid #02aab0;
  border-radius: 8px;
  padding-top: 15px;
  padding-left: 15px;
  width: 27%;
  height: 261px;
  margin-top: 15px;
  margin-left: 20px;
  @media screen and (max-width: 992px) {
    width: 86%;
  }
}
.passportHoldingUpload {
  margin-left: 21px;
  margin-top: 8px;
  margin-bottom: 30px;
}
.passportUploadContainer {
  display: flex;
  gap: 46px;
  margin-right: 30.7%;
  margin-top: 33px;
  margin-left: 21px;
}
.passportUploadContainerMob {
  flex-direction: column;
  gap: 18px;
  margin-right: 0;
}
.supportedFile {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 15px;
  line-height: 112.4%;
  color: #f9b959;
  margin-top: 7px;
  margin-left: 21px;
  margin-bottom: 20px;
}
.supportedFileMob {
  font-size: 11px;
  line-height: 112.4%;
  margin-top: 18px;
}
.passportLastPage,
.passportFirstPage,
.holdingPassportText {
  margin-bottom: 9px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #99b2c6;
}

.cancelButton,
.nextButton {
  font-family: "Metrophobic";
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.cancelButtonWeb {
  font-size: 16px;
  line-height: 20px;
  padding: 11px 60px;
  border-radius: 4px;
}
.cancelButtonWeb:hover {
  color: #fd5252;
}
.nextButtonWeb:hover {
  color: #02aab0;
}
.nextButtonWeb {
  padding: 11px 61px;
  font-size: 16px;
  border-radius: 4px;
}
.cancelButtonMob,
.nextButtonMob {
  padding: 11px 0;
  width: 50%;
}
.bottomButtonDiv {
  display: flex;
  justify-content: flex-end;
  margin-right: 30.7%;
  gap: 20px;
  margin-top: 54px;
}
.bottomButtonDivMob {
  justify-content: center;
  margin-right: 20px;
  margin-left: 20px;
}
.arrowInNextButton {
  margin-left: 6px;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popupContent {
  border-radius: 10px;
  height: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
.bottomButtonDiv {
  margin-bottom: 50px;
}
.selfieIDContainer {
  display: flex;
  gap: 26px;
  @media screen and (max-width: 992px) {
    flex-direction: column;
    gap: 16px;
  }
}
.spinnerOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
  margin-left: 16px;
}

.spinnerContainer {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid rgb(148, 148, 189);
  border-radius: 50%;
  border-top-color: rgb(64, 64, 64);
  animation: spin 1.5s ease-in-out infinite;
  -webkit-animation: spin 1.5s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}
.loading {
  display: flex;
  margin-top: -6px;
  margin-right: 22px;
  margin-bottom: 10px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #99b2c6;
  justify-content: space-between;
}
.spinnerLoading {
  display: flex;
}
.errorMessage {
  margin-top: 16px;
  margin-left: 22px;
  font-size: 14px;
  line-height: 17px;
  color: #fd5252;
}
.clickThumbnail {
  color: #8d98aa;
  font-family: Metrophobic;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 112.4%;
  margin-left: 21px;
  margin-top: 16px;
}
