.popUpContent {
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  font-family: "metrophobic";
  font-weight: 400;
  color: #ffffff;
  margin-top: 55px;
}
.popUpButtonDiv {
  margin-right: 25px;
  margin-left: 25px;
  margin-top: 45px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}
.cancelButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  width: 50%;
  height: 50px;
  color: #fd5252;
  margin-right: 15px;
  border-radius: 4px;
}
.continueButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  width: 50%;
  height: 50px;
  color: #02aab0;
  border-radius: 4px;
}
