.modal {
  background: #081527;
  border-radius: 10px;
  width: 460px;
  padding-right: 20px;
  padding-left: 20px;
}
.enterUserName {
  padding-left: 21px;
  padding-top: 40px;
  font-weight: 400;
  font-size: 18px;
  line-height: 123.8%;
  letter-spacing: 0.035em;
  color: #ffffff;
  font-family: "Metrophobic";
}
.userNameInputBox::placeholder {
  font-family: "Metrophobic";
  font-weight: 500;
  color: #8d98aa;
  font-size: 14px;
  line-height: 112.4%;
}
.userNameInputBox {
  border: 1px solid rgba(2, 170, 176, 1);
  border-radius: 10px;
  font-size: 14px;
  line-height: 112.4%;
  padding: 12px 18px;
  width: 85%;
}
.userNameInputBoxContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 15px;
}
.noteText {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #f9b959;
  margin-left: 21px;
  margin-right: 21px;
  margin-bottom: 40px;
}
.cancelButton,
.nextButton {
  font-family: "Metrophobic";
  font-weight: 400;
  color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  padding: 11px 60px;
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
}
.cancelButton {
  padding: 11px 50px;
}
.cancelButton:hover {
  color: #fd5252;
}
.nextButton:hover {
  color: #02aab0;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding-bottom: 20px;
}
.lastUsernameChangeDateError {
  margin-top: 16px;
  margin-left: 19px;
  font-size: 14px;
  line-height: 17px;
  color: #fd5252;
  margin-bottom: 10px;
}
