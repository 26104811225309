.headingText {
  font-family: "Jura";
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: #ffffff;
  padding: 10px 38px;
}
.headingTextMob {
  font-size: 21px;
  line-height: 25px;
  padding: 4px 19px;
}
.returnUserRudBuyDiv {
  display: flex;
  padding-top: 27px;
  margin-left: 40px;
  padding-bottom: 45px;
  @media screen and (max-width: 1180px) {
    margin-left: 20px;
  }
}
.rudBalanceDiv {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.rudBalanceDivMob {
  width: 30%;
}
.rudBalanceContentDiv {
  display: flex;
}
.rudBalanceValue {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
  @media screen and (max-width: 1180px) {
    font-size: 22px;
    line-height: 25px;
  }
  @media screen and (max-width: 1080px) {
    font-size: 18px;
    line-height: 21px;
  }
}
.rudBalanceValueMob {
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 15px;
  }
}
.rudContent {
  padding-left: 4px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
  @media screen and (max-width: 1180px) {
    font-size: 22px;
    line-height: 25px;
  }
  @media screen and (max-width: 1080px) {
    font-size: 18px;
    line-height: 21px;
  }
}
.rudContentMob {
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 15px;
  }
}
.rudBalancekey {
  font-family: "Jura";
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #02aab0;
  margin-bottom: 11px;
}
.rudBalancekeyMob {
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 15px;
  }
}
.stakedValueDiv {
  display: flex;
  flex-direction: column;
  width: 50%;
  @media screen and (max-width: 1250px) {
    padding-left: 50px;
  }
  @media screen and (max-width: 1080px) {
    font-size: 18px;
    line-height: 21px;
  }
}
.stakedValueDivMob {
  width: 30%;
  padding-left: 20px;
}
.stakedKey {
  font-family: "Jura";
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #02aab0;
  margin-bottom: 11px;
}
.stakedKeyMob {
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 15px;
  }
}
.stakedValue {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
  @media screen and (max-width: 1180px) {
    font-size: 22px;
    line-height: 25px;
  }
  @media screen and (max-width: 1080px) {
    font-size: 18px;
    line-height: 21px;
  }
}
.stakedValueMob {
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 15px;
  }
}
.borrowedValueDiv {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.borrowedValueDivMob {
  width: 30%;
}
.borrowedKey {
  font-family: "Jura";
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #02aab0;
  margin-bottom: 11px;
}
.borrowedKeyMob {
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 15px;
  }
}
.borrowedValue {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
  @media screen and (max-width: 1180px) {
    font-size: 22px;
    line-height: 25px;
  }
  @media screen and (max-width: 1080px) {
    font-size: 18px;
    line-height: 21px;
  }
}
.borrowedValueMob {
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 15px;
  }
}
