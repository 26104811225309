.popUpContent {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  border-radius: 10px;
  margin-right: 0px;
  padding-top: 68px;
  padding-left: 58px;
  border: none;
  padding-right: 58px;
  padding-bottom: 25px;
}

.cancelButton {
  height: 48px;
  width: 50%;
  color: #ffffff;
  border-radius: 4px;
  font-family: "metrophobic";
  font-size: 24px;
  margin-right: 20px;
}
.cancelButton:hover {
  color: #fd5252;
}
.cancelButtonMob {
  color: #fd5252;
  height: 40px;
  font-size: 20px;
}
.unstakeButton {
  height: 48px;
  width: 50%;
  color: #ffffff;
  border-radius: 4px;
  font-family: "metrophobic";
  font-size: 24px;
}
.unstakeButton:hover {
  color: #fd5252;
}
.unstakeButtonMob {
  color: #fd5252;
  height: 40px;
  font-size: 20px;
}
.popUpDivMob {
  width: 100%;
  font-size: 13px;
  font-family: "Jura";
}
.popUpContentMob {
  text-align: center;
  padding-top: 41px;
  font-size: 19px;
  font-family: "Metrophobic";
  padding-left: 28px;
  padding-right: 28px;
}
.popUpButtonDiv {
  display: flex;
  flex-direction: row;
  margin-top: 70px;
  padding-bottom: 35px;
  margin-left: 58px;
  margin-right: 58px;
}
.popUpButtonDivMob {
  margin-top: 45px;
  padding-bottom: 26px;
  margin-left: 22px;
  margin-right: 22px;
}
.stakeRUD {
  border: 1px solid #02aab0;
  border-radius: 10px;
  padding-left: 27px;
  padding-bottom: 13px;
  margin-left: 58px;
  margin-right: 58px;
  margin-bottom: 11px;
}
.stakeRUDMob {
  padding-left: 20px;
  margin-left: 22px;
  margin-right: 22px;
  padding-bottom: 11px;
  margin-bottom: 10px;
}
.stakeRUDInLPDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.depositingRUD {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #99b2c6;
  padding-bottom: 4px;
  padding-top: 15px;
}
.depositingRUDMob {
  padding-top: 9px;
}
.maxButton {
  padding: 3px 17px;
  border: 1px solid #02aab0;
  box-shadow: 0 0 2px #02aab0;
  font-family: "metrophobic";
  background: transparent;
  color: white;
  font-size: 16px;
  line-height: 19px;
  border-radius: 5px;
  font-weight: 400;
  margin-right: 27px;
  margin-top: 11px;
}
.maxButton:hover {
  background: #02aab0;
}
.maxButtonMob {
  font-size: 14px;
  line-height: 17px;
  margin-top: 9px;
  padding: 2px 12px;
  margin-right: 9px;
  border-radius: 8px;
}
input {
  background: transparent;
  border: none;
  color: #ffffff;
}

.payingEthValue {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.inputAmount {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  width: 100%;
}
.inputAmountMob {
  font-size: 24px;
  line-height: 28px;
}
.payingCrypto {
  font-family: "roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  margin-right: 32px;
}
.payingCryptoMob {
  font-size: 28px;
  line-height: 33px;
  margin-right: 10px;
}
.dollarSymbol {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
}
.dollarSymbolMob {
  font-size: 24px;
  line-height: 33px;
}
