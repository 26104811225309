.navBar {
  display: flex;
  position: fixed;
  flex-direction: column;
  text-decoration: none;
  // min-height: 500px;
  // height: calc(100% - 95px);
  width: 18.5%;
  top: 140px;
  box-sizing: border-box;
  background-color: #081527;
  border-right: 1px solid #343339;
}
.navDiv,
.activeNavDiv {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-left: 16px;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 15px;
  @media screen and (max-width: 1250px) {
    gap: 5px;
    padding-left: 6px;
  }
  @media screen and (min-width: 1680px) {
    gap: 15px;
  }
}
.activeNavDiv {
  background: linear-gradient(90deg, #212d3d 0%, #0e1a2c 100%);
}
.activeNav {
  display: block;
  width: 100%;
  // background: linear-gradient(90deg, #212d3d 0%, #0e1a2c 100%);
  border-radius: 5px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 22px;
  line-height: 20px;
  color: #02aab0;
  & > span {
    font-family: "Jura";
    text-align: center;
  }
  @media screen and (max-width: 993px) {
    font-size: 18px;
    line-height: 23px;
  }
  @media screen and (max-width: 1193px) {
    font-size: 20px;
    line-height: 23px;
  }
  @media screen and (min-width: 1500px) {
    font-size: 26px;
    line-height: 21px;
  }
  @media screen and (min-width: 1680px) {
    font-size: 28px;
    line-height: 20px;
  }
}
.nav,
.nonActive {
  padding-top: 7px;
  padding-bottom: 10px;
  text-decoration: none;
  color: #a5a2b8;
  height: 17px;
  font-family: "Metrophobic";
  font-weight: 400;

  font-size: 22px;
  line-height: 20px;
  text-decoration: none;
  & > span {
    font-family: "Jura";
  }
  @media screen and (max-width: 993px) {
    font-size: 18px;
    line-height: 23px;
  }
  @media screen and (max-width: 1193px) {
    font-size: 20px;
    line-height: 23px;
  }
  @media screen and (min-width: 1500px) {
    font-size: 26px;
    line-height: 21px;
  }
  @media screen and (min-width: 1680px) {
    font-size: 28px;
    line-height: 20px;
  }
}

.activeNav path {
  fill: #02aab0;
}

.activeNav rect {
  stroke: #02aab0;
}

.navDiv:hover path,
.navDiv:hover span,
.navDiv:hover {
  fill: #02aab0;
  color: #02aab0;
}

.navDiv:hover rect {
  stroke: #02aab0;
}
.activeNavDiv:hover path,
.activeNavDiv:hover span,
.activeNavDiv:hover {
  fill: #02aab0;
  color: #02aab0;
}

.activeNavDiv:hover rect {
  stroke: #02aab0;
}
.subNavDiv:hover path,
.subNavDiv:hover span,
.subNavDiv:hover {
  fill: #02aab0;
  color: #02aab0;
}
.image {
  width: 18px;
}

.profileImageDiv {
  position: fixed;
  width: 3.8%;
  right: 7.4%;
}

.statistics {
  position: fixed;
  right: 36px;
  width: 13.6%;
  top: 291px;
  gap: 18.34px;
  height: 180px;
}
.column1Statis {
  color: #387d6d;
  font-family: "Metrophobic";
  font-size: 12px;
  font-weight: 400;
  max-width: 50%;
}
.column2Statis {
  font-size: 12px;
  max-width: 50%;
}
.statisticsInDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 42px;
}
.routes {
  width: 84.4%;
  display: block;
  margin: 0px auto auto auto;
}
.subNavDiv {
  margin-top: 10px;
  margin-bottom: 16px;
  margin-left: 63px;
}
.nextIcon {
  margin-left: 20px;
}
.nextIconContainer {
  display: flex;
  justify-content: space-between;
  width: 76%;
}
.nextIcon {
  &.rotated {
    transform: rotate(90deg);
  }
}
.spanInNav {
  display: flex;
  align-items: center;
  padding: 2px 12px;
  width: 95px;
  height: 30px;
  border-radius: 5px;
}
.activeSpanInNav {
  display: flex;
  align-items: center;
  padding: 2px 12px;
  width: 130px;
  height: 30px;
  background: linear-gradient(90deg, #212d3d 0%, #0e1a2c 100%);
  border-radius: 5px;
}
.lineContainer {
  height: 81px;
  width: 3px;
  position: absolute;
  margin-left: 27px;
  // margin-top: -10px;
  background: #162233;
}
.straightLineContainer {
  height: 3px;
  width: 20px;
  position: absolute;
  margin-left: -35px;
  margin-top: 17px;
  background: #162233;
}
// Add these styles to your existing SCSS file (index.module.scss)

.subRoutesContainer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-in-out; // Adjust the duration and easing as needed
}

.subRoutesContainerOpen {
  max-height: 200px; // Set an appropriate value based on your content height
}

.subRoutesContainerClosed {
  max-height: 0;
}

@keyframes slideDown {
  from {
    max-height: 0;
  }
  to {
    max-height: 200px;
  }
}

@keyframes slideUp {
  from {
    max-height: 200px;
  }
  to {
    max-height: 0;
  }
}

.subRoutesContainer {
  animation: slideDown 0.6s ease-in-out;
  opacity: 1;
}

.subRoutesContainerClosed {
  animation: slideUp 0.6s ease-in-out;
  opacity: 0;
}
