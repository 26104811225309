.footer {
  height: 18px;
  color: white;
  font-family: "Jura";
  font-size: 0.86vw;
  text-align: center;
  margin-bottom: 61px;
}
.footerMob {
  margin: 0;
}
