.titleDiv {
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: "Metrophobic";
  font-weight: 400;
}

.titleDivWeb {
  padding: 15px 0px 15px 32px;
  font-size: 28px;
  line-height: 43px;
}
.titleDivMob {
  padding: 16px 22px;
  font-size: 21px;
  line-height: 25px;
}
.nestCount {
  margin-left: 3px;
}
.payButton {
  font-family: "Metrophobic";
  font-weight: 400;
  color: #ffffff;
}
.payButtonWeb {
  border-radius: 6px;
  padding: 12px 48px;
  font-size: 16px;
  line-height: 17px;

  @media only screen and (max-width: 1090px) {
    padding: 10px 20px;
  }
}
.payButtonMob {
  padding: 5px 35px;
  font-size: 15px;
  border-radius: 6px;
  margin-left: 8px;
}
.payButtonWeb:hover {
  color: #02aab0;
}
.showNests {
  font-family: "Metrophobic";
  font-weight: 400;
  color: #ffffff;
}

.showNestsWeb {
  border-radius: 4px;
  padding: 12px 45px;
  font-size: 16px;
  line-height: 20px;
  margin-right: 20px;
  @media only screen and (max-width: 1090px) {
    padding: 10px 20px;
  }
}
.showNestsMob {
  border-radius: 6px;
  padding: 6px 15px;
  font-size: 15px;
}
.showNestsWeb:hover {
  color: #02aab0;
}
.loanDetails {
  color: #02aab0;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  @media only screen and (min-width: 1300px) {
    font-size: 17px;
  }
}
.loanDetailsMob {
  font-size: 18px;
}

.nestDetailsHeading {
  margin-top: 5px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #9c99ae;
  @media only screen and (min-width: 1300px) {
    font-size: 17px;
  }
}

.nestDetails {
  display: flex;
  flex-direction: column;
}
.nestDetailsValueWeb {
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #ffffff;
  @media only screen and (min-width: 1300px) {
    font-size: 19px;
  }
}
.paymentDetails {
  margin-left: 32px;
  color: #02aab0;
  font-family: "Metrophobic";
  font-weight: 400;

  line-height: 160%;
}
.paymentDetailsWeb {
  font-size: 14px;
  line-height: 160%;
  margin-top: 34px;
  @media only screen and (min-width: 1300px) {
    font-size: 17px;
  }
}

.paymentDetailsMob {
  font-size: 18px;
  margin-top: 16px;
  margin-left: 22px;
}
.nestPaymentDetails {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}
.nestPaymentDetailsMob {
  margin-left: 0px;
}
.nestTablePaymentHeading {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  font-family: "Metrophobic";
  padding-right: 41px;
  padding-bottom: 17px;
  @media only screen and (max-width: 500px) {
    padding-right: 10px;
  }
}
.nestTablePaymentHeadingMob {
  margin-left: 10px;
}
.nestPaymentDetailsHeading {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #9c99ae;
  @media only screen and (min-width: 1300px) {
    font-size: 17px;
  }
}
.alertContainer {
  display: flex;
  flex-direction: row-reverse;
  width: 330px;
  font-weight: 400;
  line-height: 16px;
  text-align: justify;
  margin: 12px 0px 0px auto;
  padding: 14px 10px 10px 8.6px;
  font-family: "metrophobic";
  font-size: 14px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 10px;

  @media only screen and (max-width: 789px) {
    width: auto;
  }
}
.bodyContainer {
  background: linear-gradient(180deg, #2f3c51 0%, #1f2d44 100%);
}
.bellIcon {
  margin-right: 9px;
}
.nestTableHeadingMob {
  margin-right: 92px;
}

.borrowRUDPaymentSchedule {
  display: flex;
  flex-direction: column;
  margin-left: 21px;
}
.collatRatio,
.loanPayment {
  margin-top: 16px;
  margin-left: 21px;
  margin-right: 21px;
  display: flex;
  justify-content: column;
}
.nestDetailsDiv {
  display: flex;
  flex-direction: column;
}
.loanDetailsMainDiv {
  display: flex;
  justify-content: space-between;
  margin-right: 81px;
  margin-left: 11px;
}
.remainingNoTerm {
  margin-left: 21px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 17px;
}
.nestDetailsValue {
  font-family: "metrophobic";
  font-size: 16px;
  line-height: 160%;
  @media only screen and (min-width: 1300px) {
    font-size: 19px;
  }
}
.borrowRUDCollat {
  display: flex;
  justify-content: space-between;
  margin-right: 94px;
  @media only screen and (max-width: 500px) {
    margin-right: 40px;
  }
}
.loanBalancePaymentSchedule {
  margin-left: 21px;
  display: flex;
  justify-content: space-between;
  margin-right: 96px;
  @media only screen and (max-width: 500px) {
    margin-right: 40px;
  }
}
.collatMob {
  margin-left: 21px;
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  padding-bottom: 20px;
  @media screen and (max-width: 992px) {
    padding-right: 20px;
  }
}
.headingContainer {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 8px 32px;
  @media screen and (max-width: 992px) {
    padding: 20px 20px 8px 21px;
  }
}
