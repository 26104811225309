.accountDiv {
  background-color: #06152b;
  padding-left: 37px;
  font-family: "Metrophobic";
  font-weight: 500;
  color: #ffffff;
}
.accountDivWeb {
  margin-top: 23px;
  font-size: 17px;
  line-height: 21px;
}
.accountDivMob {
  margin-top: 20px;
  font-size: 10px;
  line-height: 12px;
  padding-left: 14px;
}
.accountMainDiv {
  background-color: #06152b;
}
.uploadPhotoPlaceholder {
  font-family: "Jura";
  font-weight: 500;

  color: #8d98aa;
}
.uploadPhotoPlaceholderWeb {
  font-size: 12px;
  line-height: 14px;
  margin-top: 15px;
}
.uploadPhotoPlaceholderMob {
  margin-top: 10px;
  font-size: 10px;
  line-height: 12px;
}

.profileNameContainer,
.emailContainer {
  font-family: "Metrophobic";
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 6px;
  color: rgba(153, 178, 198, 1);
}
.profileNameContainerWeb,
.emailContainerWeb {
  margin-top: 15px;
  margin-left: 38px;
}
.profileNameContainerMob,
.emailContainerMob {
  margin-top: 12px;
  margin-left: 16px;
  margin-bottom: 0px;
}

.inputName,
.inputEmail {
  padding-left: 38px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 12px;
}
.inputNameWeb,
.inputEmailWeb {
  width: 227px;
  height: 15px;

  padding-bottom: 16px;
  @media only screen and (max-width: 1230px) {
    width: 200px;
    height: 52px;
  }
  @media only screen and (max-width: 1178px) {
    width: 150px;
    height: 52px;
  }
  @media only screen and (max-width: 1077px) {
    width: 100px;
    height: 52px;
  }
}
.inputNameMob,
.inputEmailMob {
  width: 326px;
  height: 45px;
  padding-left: 16px;
  @media only screen and (max-width: 375px) {
    width: 250px;
    height: 45px;
  }
}
.profileName {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 38px;
  border: 1px solid rgba(2, 170, 176, 1);
  border-radius: 10px;
  color: rgba(153, 178, 198, 1);
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.profileNameMob {
  margin-top: 16px;
  margin-left: 14px;
}
.emailWeb {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  border: 1px solid #02aab0;
  margin-top: 50px;
  border-radius: 10px;
}
.emailMob {
  margin-left: 14px;
  margin-top: 20px;
  border: 1px solid #02aab0;

  border-radius: 10px;
}
.inputName::placeholder,
.inputEmail::placeholder {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 112.4%;
  color: rgba(153, 178, 198, 1);
}
.profileNameEmailContainerWeb {
  display: flex;
  margin-right: 30.7%;
}
.profileNameEmailContainerMob {
  display: flex;
  flex-direction: column;
}
.emailContainer {
  display: flex;
}
.updateProfileButton {
  color: white;
  border-radius: 8px;
  font-family: "Jura";
  font-weight: 600;
}
.updateProfileButton:hover {
  color: #02aab0;
}
.updateProfileButtonWeb {
  margin-top: 46px;
  padding: 12px 40px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-left: 40px;
}
.updateProfileButtonMob {
  margin-top: 54px;
  font-size: 12px;
  line-height: 14px;
  padding: 11px 22px;
}
.updateProfileButtonDivMob {
  display: flex;
  justify-content: center;
}
.profileImageDiv {
  position: relative;

  padding: 4px;
  cursor: pointer;
  border-radius: 50%;
  border: dashed 1px #8d98aa;
}

.profileImageDivMob {
  padding: 2px;
}
.avatarUploadButton {
  background-color: transparent;
  border: none;

  position: relative;
  cursor: pointer;
}

.avatarUploadButtonMob {
  margin-left: 14px;
}
.profileImageDivMob {
  margin: 20px auto 0;
}

.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popupContent {
  border-radius: 10px;
  height: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
.alertSpan {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: #fd5252;
  padding-bottom: 10px;
}
.profileAvatarPreview {
  border-radius: 50%;
  border: 1px dashed white;
  padding: 4px;
}
.profileImagePreview {
  position: relative;
  cursor: pointer;
}
.updateSuccessMessage {
  font-family: "Metrophobic";
  font-weight: 400;
  color: #31c5a0;
  margin-bottom: 20px;
}
.updateSuccessMessageWeb {
  margin-top: 28px;
  font-size: 18px;
  line-height: 21px;
}
.updateSuccessMessageMob {
  font-size: 12px;
  margin-top: 20px;
}
.profileImagePreviewDiv {
  display: "flex";
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profileImagePreviewDivWeb {
  width: 103px;
  height: 103px;
  display: "flex";
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profileImagePreviewDivMob {
  width: 94px;
  height: 95px;
}
.imageUploadContainerWeb {
  height: 36px;
  width: 36px;
}
.imageUploadMob {
  width: 25px;
  height: 25px;
}

.onHoverOverlay {
  position: absolute;
  top: 61px;
  left: 23px;
  padding: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 9px;
  line-height: 13px;
  color: #ffffff;
  width: 70px;
  border-radius: 0 0 150px 150px;
  height: 39px;
  background: #1c2736;
  opacity: 0.7;
  pointer-events: none;
}
.onHoverOverlayMob {
  top: 250px;
}
.emailValidationError {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  color: #fd5252;
  margin-top: 8px;
  margin-left: 20px;
  margin-bottom: 50px;
}
.thisWillBeYourUniqueUserNameDiv {
  font-family: "Metrophobic";
  font-weight: 400;
  color: white;
}
.thisWillBeYourUniqueUserNameDivWeb {
  font-size: 13px;
  line-height: 136.4%;
  margin-left: 38px;
  margin-top: 4px;
}
.thisWillBeYourUniqueUserNameDivMob {
  margin-left: 20px;
  margin-top: 4px;
}
.toolTipInProfile {
  margin-bottom: -6px;
  margin-left: 3px;
}
.selectAvatar {
  color: white;
  font-family: "Metrophobic";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: 22px;
  line-height: 112.4%; /* 13.488px */
}
.clickToChangeAvatar {
  text-align: center;
}
