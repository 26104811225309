.profileNamePopUp {
  background: #081527;
  border-radius: 10px;
}
.profileNamePopUpWeb {
  width: 577px;
}
.profileNamePopUpMob {
  width: 100%;
}
.cancelButton {
  font-family: "Jura";
  font-weight: 700;
  color: #fd5252;
  border-radius: 4px;
}
.cancelButtonWeb {
  width: 235px;
  height: 54.99px;
  font-size: 24px;
  line-height: 123.8%;
  letter-spacing: 0.035em;
}
.cancelButtonWeb:hover {
  color: #ffffff;
  background: #fd5252;
  border: 1px solid #fd5252;
}
.cancelButtonMob {
  width: 40%;
  height: 30px;
  font-size: 16px;
  line-height: 123.8%;
  letter-spacing: 0.035em;
}
.attachFileButton {
  font-family: "Jura";
  font-weight: 700;
  color: #00cdac;
  border-radius: 4px;
}
.attachFileButton:hover {
  background: #00cdac;
  border: 1px solid #00cdac;
  color: white;
}
.attachFileButtonWeb {
  width: 235px;
  height: 54.99px;
  font-size: 24px;
  line-height: 123.8%;
  margin-left: 19px;
  letter-spacing: 0.035em;
}
.attachFileButtonMob {
  width: 40%;
  height: 30px;
  font-size: 16px;
  margin-left: 11px;
  line-height: 123.8%;
  letter-spacing: 0.035em;
}
.buttonDivWeb {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 37px;
  margin-top: 20px;
}
.buttonDivMob {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
}
.uploadFiles {
  font-family: "Jura";
  font-weight: 400;

  color: #99b2c6;
}
.uploadFilesWeb {
  padding-top: 17px;
  padding-left: 60px;
  font-size: 16px;
  line-height: 19px;
}
.uploadFilesMob {
  padding-top: 10px;
  padding-left: 35px;
  font-size: 11px;
  line-height: 13px;
}
.alertSpan {
  margin-right: auto;
  margin-left: auto;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #fd5252;
  padding-bottom: 10px;
}
.alertSpanDiv {
  display: flex;
  justify-content: center;
}
