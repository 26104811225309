.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popupContent {
  background: #081527;
  width: 40%;
  border-radius: 10px;
  height: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
