.transactionSubmitDiv {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.transactionSubmitDivMob {
  width: 250px;
  margin-right: auto;
  margin-left: auto;
}
.congrats {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 35px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #02aab0;
  text-align: center;
}
.transactionSubmit {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 8px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #02aab0;
  text-align: center;
}
.transactionSubmitMob {
  font-size: 21px;
  line-height: 25px;
  margin-top: 35px;
}
.gasCostContainer {
  margin-top: 30px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
}
.okayButtonDiv {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 75px;
}
.okayButtonDivMob {
  margin-top: 36px;
  margin-bottom: 43px;
}
.closeButton {
  padding: 8px 50px;
  font-family: "Metrophobic";
  color: white;
  font-size: 16px;
  line-height: 19px;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
}
.closeButton:hover {
  color: #fd5252;
}
.closeButtonMob {
  padding: 5px 45px;
  color: #fd5252;
}
.uploadIconDiv {
  display: flex;
  justify-content: center;
  margin-top: 75px;
}
.uploadIconDivMob {
  margin-top: 35px;
}
.uploadIcon {
  width: 92px;
  height: 92px;
}
.uploadIconMob {
  width: 40px;
  height: 40px;
}
.bonusContainer {
  margin-top: 15px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
}
