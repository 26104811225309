.cardTitle {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #334453;
  border: 1px solid #334453;
}
.buttonHeadingDiv {
  background: #334453;
  border: 1px solid #334453;

  height: 62px;
}
.settingsButtons {
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 10px 10px 0px 0px;
  padding-left: 32px;
  margin-right: 30.7%;
  padding-top: 17px;
  padding-bottom: 22px;
  border-bottom: 2px solid #02aab0;
}
.settingsButtonsMob {
  padding-left: 15px;
  padding-top: 14px;
  padding-right: 8px;
  margin-right: 0px;
  margin-top: 0px;
}
.accountButton {
  font-family: "Metrophobic";
  color: white;
  background: transparent;
  border-radius: 5px;
  border: 1px solid #02aab0;
}
.accountButtonWeb {
  padding: 2px 33px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;

  @media only screen and (max-width: 1212px) {
    padding: 3px 20px;
    font-size: 15px;
  }
  @media only screen and (max-width: 1075px) {
    padding: 3px 10px;
    font-size: 15px;
  }
}
.accountButton:hover,
.securityButton:hover,
.notificationButton:hover,
.KYCButton:hover {
  background: #02aab0;
}
.accountButtonMob {
  font-size: 12px;
  line-height: 14px;
  padding: 9px 16px;
  margin-right: 17px;
  margin-left: 8px;
}
.securityButton {
  font-family: "Metrophobic";
  font-weight: 400;
  color: #ffffff;
  border-radius: 5px;
  border: 1px solid #02aab0;
}
.securityButtonWeb {
  font-size: 18px;
  line-height: 21px;
  padding: 2px 28px;
  @media only screen and (max-width: 1212px) {
    padding: 3px 20px;
    font-size: 15px;
  }
  @media only screen and (max-width: 1075px) {
    padding: 3px 10px;
    font-size: 15px;
  }
}
.securityButtonMob {
  font-size: 12px;
  line-height: 14px;
  padding: 9px 16px;
  margin-right: 17px;
}
.securityButton:hover {
  background: linear-gradient(47.47deg, #02aab0 5.98%, #00cdac 85.82%);
}
.notificationButton {
  font-family: "Metrophobic";
  border: 1px solid #02aab0;
  color: #ffffff;
  border-radius: 5px;
}
.notificationButtonWeb {
  padding: 4px 19px;
  margin-left: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  @media only screen and (max-width: 1212px) {
    padding: 3px 20px;
    font-size: 15px;
  }
  @media only screen and (max-width: 1075px) {
    padding: 3px 10px;
    font-size: 15px;
  }
}

.notificationButtonMob {
  padding: 9px 7px;
  font-size: 12px;
  line-height: 14px;
  margin-right: 17px;
  margin-left: 14px;
}
.KYCButton {
  font-family: "Metrophobic";

  border: 1px solid #02aab0;
  color: #ffffff;
  border-radius: 5px;
}
.KYCButtonWeb {
  padding: 4px 50px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-right: 20px;
}
.KYCButtonMob {
  padding: 9px 25px;
  font-size: 12px;
  line-height: 14px;
  margin-right: 12px;
}
.saveButtonDiv {
  display: flex;
  justify-content: flex-end;
  margin-right: 30.7%;
}
.saveButton {
  color: white;
  border-radius: 8px;
  padding: 6px 20px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-right: 20px;
  margin-top: 30px;
}
.saveButton:hover {
  color: #02aab0;
}
