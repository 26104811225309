.borrowTitleContainer {
  display: flex;
  flex-direction: row;
}
.borrowHeadingText {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 43px;
  color: #ffffff;
  display: flex;
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 10px;
}
.borrowHeadingTextMob {
  font-size: 24px;
  line-height: 28px;
  padding-top: 10px;
  padding-left: 14px;
  padding-bottom: 10px;
}
.ButttonContent {
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.questionIcon {
  margin-left: 6px;
  width: 20px;
  height: 20px;
  margin-top: 6px;
}
.questionIcon:hover {
  cursor: pointer;
}
.stepsButton1 {
  padding: 3px 26px;

  border: 1px solid #02aab0;
  background: transparent;
  border-radius: 5px;
  margin-left: 21px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
}
.stepsButton1Mob {
  padding: 1px 17px;
}
.stepsButton2 {
  border: 1px solid #02aab0;
  background: transparent;
  padding: 3px 26px;

  border-radius: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: #ffffff;
}
.stepsButton2Mob {
  padding: 1px 17px;
}
.stepsButton3 {
  padding: 3px 26px;

  background: linear-gradient(47.47deg, #02aab0 5.98%, #00cdac 85.82%);
  border-radius: 5px;
  margin-right: 21px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: white;
}
.stepsButton3Mob {
  padding: 1px 17px;
}
.line {
  border-bottom: 1px solid #02aab0;
  width: 100%;
}
.step1 {
  margin-top: 22px;
  margin-left: 21px;
  font-family: "Jura";
  font-weight: 400;
  font-size: 16px;
  color: #99b2c6;
}
.selectInStep3 {
  margin: 9px 0px 27px 21px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  @media screen and (min-width: 1300px) {
    font-size: 18px;
  }
  @media screen and (max-width: 1134px) {
    font-size: 15px;
  }
  @media screen and (max-width: 1080px) {
    font-size: 13px;
  }
  @media screen and (max-width: 1020px) {
    font-size: 11px;
  }
}
.selectInStep3Mob {
  font-size: 13px;
  @media screen and (max-width: 471px) {
    font-size: 11px;
  }
  @media screen and (max-width: 413px) {
    font-size: 9px;
  }
}
.step3 {
  margin-top: 22px;
  margin-left: 21px;
  font-family: "Jura";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
}
.addRudInLPMob {
  font-size: 12px;
  line-height: 14px;
  padding-top: 27px;
  padding-left: 16px;
  padding-right: 8px;
}
.stakeButtonsDiv {
  padding-top: 55px;
  display: flex;
  justify-content: flex-end;
  margin-right: 49px;
  margin-bottom: 25px;
}
.stakeButtonsDivMob {
  justify-content: center;
  padding-top: 65px;
  margin-right: 13px;
  margin-bottom: 15px;
}
.liveEthStake {
  padding-right: 300px;
  margin-top: 405px;
}
.liveEthStakeMob {
  padding-right: 0px;
  margin-top: 265px;
  margin-bottom: 36px;
}
.getRUDButton {
  font-family: "Jura";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  background: transparent;
  color: white;
  padding: 10px 25px;
  border-radius: 8px;
  margin-right: 12px;
}
.getRUDButton:hover {
  background: linear-gradient(47.47deg, #02aab0 5.98%, #00cdac 85.82%);
}
.getRUDButtonMob {
  font-size: 13px;
  line-height: 15px;
  padding: 6px 19px;
  border-radius: 5px;
}
.depositButton {
  font-family: "Jura";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  background: transparent;
  color: white;
  padding: 10px 31px;
  border-radius: 8px;
}
.depositButton:hover {
  background: linear-gradient(47.47deg, #02aab0 5.98%, #00cdac 85.82%);
}
.depositButtonMob {
  border-radius: 5px;
  padding: 6px 24px;
  font-size: 13px;
  line-height: 15px;
}
.alertBeforeDeposit {
  font-size: 12px;
  line-height: 14px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  color: #fd5252;
  margin: auto auto auto 47px;
}
.questionIconDiv {
  margin: 22px auto 20px 9px;
}
.questionIconDivMob {
  margin: 16px auto 17px 10px;
}
.questionIcon {
  width: 20px;
  height: 20px;
}
.questionIconMob {
  width: 16px;
  height: 16px;
}
.depositRUD {
  border: 1px solid #02aab0;
  border-radius: 10px;
  padding-left: 39px;
  padding-bottom: 15px;
  margin-left: 21px;
  margin-right: 21px;
  margin-bottom: 16px;
}
.depositRUDMob {
  padding-left: 26px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 10px;
}
.depositRUDInLPDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.depositingRUD {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
  padding-bottom: 4px;
  padding-top: 15px;
}
.depositingRUDMob {
  padding-top: 11px;
}
.maxButton {
  padding: 3px 17px;
  font-family: "jura";
  background: transparent;
  border: 1px solid #00cdac;
  color: white;
  font-size: 16px;
  line-height: 19px;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  margin-right: 31px;
  margin-top: 15px;
}
.maxButton:hover {
  background: #00cdac;
}
.maxButtonMob {
  font-size: 12px;
  line-height: 14px;
  margin-top: 11px;
  padding: 2px 12px;
  margin-right: 25px;
}
input {
  background: transparent;
  border: none;
  color: #ffffff;
}

.payingEthValue {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.inputAmount {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  width: 100%;
}
.inputAmountMob {
  font-size: 24px;
  line-height: 28px;
}
.payingCrypto {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  margin-right: 39px;
}
.payingCryptoMob {
  font-size: 24px;
  line-height: 28px;
  margin-right: 25px;
}
.dollarSymbol {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
}
.dollarSymbolMob {
  font-size: 24px;
  line-height: 28px;
}
.poolShareDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 76px;
  margin-right: 200px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  @media screen and (max-width: 1356px) {
    margin-left: 24px;
    margin-right: 122px;
  }

  @media screen and (max-width: 1137px) {
    margin-left: 24px;
    margin-right: 139px;
    font-size: 20px;
  }
  @media screen and (max-width: 1100px) {
    margin-left: 21px;
    margin-right: 115px;
    font-size: 16px;
  }
  @media screen and (max-width: 1033px) {
    margin-left: 0px;
    margin-right: 15px;
  }
}
.poolShareDivMob {
  margin-right: 32px;
  margin-left: 24px;
}

.sharePercentage {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
  color: #02aab0;
  margin-right: -15px;
  @media screen and (max-width: 1137px) {
    font-size: 20px;
  }
  @media screen and (max-width: 1100px) {
    font-size: 17px;
  }
}
.ButtonsDiv {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 28px;
  margin-right: 40px;
  margin-bottom: 30px;
}
.ButtonsDivMob {
  margin-top: 45px;
  margin-right: 20px;
  margin-left: 20px;
}
.cancelButton {
  border-radius: 8px;
  font-family: "Metrophobic";
  font-weight: 500;
  font-size: 21px;
  line-height: 19px;
  color: #ffffff;
  padding: 10px 52px;
  margin-right: 10px;
}
.cancelButtonMob {
  padding: 7px 0px;
  width: 50%;
}
.cancelButton:hover {
  color: #fd5252;
}
.confirmButton {
  border-radius: 8px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #ffffff;
  padding: 10px 55px;
}
.confirmButtonMob {
  padding: 7px 0px;
  width: 50%;
}
.confirmButton:hover {
  color: #02aab0;
}
.alertBeforeConfirm {
  font-size: 12px;
  line-height: 14px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  color: #fd5252;
  margin: 14px auto auto 64px;
}
.GetBorrowUSDContainer {
  border-radius: 10px;
  border: 1px solid #02aab0;
  margin-right: 21px;
  margin-left: 21px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 39px;
}
.GetBorrowUSDContainerMob {
  margin-right: 16px;
  margin-left: 16px;
  border-radius: 10px;
  padding-left: 24px;
}
.GetBorrowAmount {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
  padding-bottom: 4px;
}
.selectContainer {
  display: flex;
}
.questionIconSelect {
  margin-left: 6px;
  margin-top: 9px;
  height: 17px;
  width: 17px;
}
.questionIconSelect:hover {
  cursor: pointer;
}
.hiddenDiv {
  display: flex;
  flex-direction: row;
  margin-top: 13px;
  margin-left: 64px;
  margin-right: 101px;
}
.hiddenDivMob {
  flex-direction: column;
  gap: 10px;
  margin-left: 20px;
  margin-right: 5px;
}
.liquidationReserveBorrowingFee {
  display: flex;
  justify-content: space-between;
  margin-right: 14px;
  margin-left: 14px;
}
.liquidationReserveDiv {
  display: flex;
  flex-direction: column;
}
.borrowingFeeDiv {
  display: flex;
  flex-direction: column;
}

.GetBorrowAmountValue {
  display: flex;
  justify-content: space-between;
  margin-right: 39px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
}
.GetBorrowAmountValueMob {
  margin-right: 25px;
  font-size: 24px;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popupContent {
  background: #081527;
  width: 40%;
  border-radius: 10px;
  height: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
.bellIcon {
  margin-right: 8.73px;
}
.alertContainer {
  display: flex;
  flex-direction: row-reverse;
  width: 358px;
  margin: 12px 30.7% 30px auto;
  padding: 14px 10px 10px 8.6px;
  font-family: "Metrophobic";
  font-size: 14px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 10px;
}

.alertContainerMob {
  width: 255px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-right: auto;
  margin-left: auto;
  @media only screen and (max-width: 992px) {
    margin-right: 0;
    margin-left: auto;
  }
  @media only screen and (max-width: 450px) {
    margin-right: auto;
    margin-left: auto;
  }
  margin-bottom: 40px;
}
.input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
}
.inputAmount::placeholder {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
}
::-webkit-input-placeholder {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
}
.quesIcon {
  width: 19px;
  height: 19px;
  margin-left: 6px;
  margin-top: 16px;
}
.quesIcon:hover {
  cursor: pointer;
}
.toolTipStyle1 {
  background-color: #384455;
  border-radius: 12px;
  width: 192px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "Metrophobic";
}
.toolTipStyle2 {
  background-color: #384455;
  border-radius: 12px;
  width: 214px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "Metrophobic";
}
.toolTipStyle3 {
  background-color: #384455;
  border-radius: 12px;
  width: 285px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "Metrophobic";
}
.toolTipStyle4 {
  background-color: #384455;
  border-radius: 12px;
  width: 260px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "Metrophobic";
  z-index: 2;
}
.borrowingFeeDiv {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.questionIconHiddenDiv {
  margin-left: 4px;
  width: 14px;
  margin-bottom: -2px;
}
.borrowingFee {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
  @media screen and (max-width: 1300px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1062px) {
    font-size: 12px;
  }
}
.borrowingFeeMob {
  font-size: 14px;
  line-height: 17px;
}
.borrowFeeValue {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;

  color: #ffffff;
  @media screen and (max-width: 1300px) {
    font-size: 20px;
  }
  @media screen and (max-width: 1062px) {
    font-size: 18px;
  }
}
.borrowFeeValueMob {
  font-size: 15px;
  line-height: 18px;
}
.poolShareBorrowPercValue {
  font-size: 18px;
  @media screen and (max-width: 1300px) {
    font-size: 12px;
  }
  @media screen and (max-width: 1062px) {
    font-size: 10px;
  }
}
.poolShareBorrowPercValueMob {
  font-size: 12px;
}
.tooltip {
  background-color: #384455;
  opacity: 1;
  white-space: normal;
  border-radius: 12px;
  width: 176px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  padding: 10px 4px;
  font-family: "Roboto";
}

.collateralizationRatioDiv {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.collateralizationRatioDivMob {
  width: 100%;
}
.collateralizationMainConatiner {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
  @media screen and (max-width: 1300px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1062px) {
    font-size: 12px;
  }
}
.collateralizationMainConatiner {
  font-size: 14px;
  line-height: 17px;
}
.questionIconHiddenDiv {
  margin-left: 4px;
  width: 14px;
}
.questionIconHiddenDiv:hover {
  cursor: pointer;
}
.poolSharePercentageValue {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #00cdac;
  @media screen and (max-width: 1300px) {
    font-size: 20px;
  }
  @media screen and (max-width: 1062px) {
    font-size: 18px;
  }
}
.poolSharePercentageValueMob {
  font-size: 15px;
  line-height: 18px;
}
.maxBorrowAmountDiv {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.maxBorrowAmount {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
  @media screen and (max-width: 1300px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1062px) {
    font-size: 12px;
  }
}
.maxBorrowAmountMob {
  font-size: 14px;
  line-height: 17px;
}
.maxBorrowAmountValue {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;

  color: #ffffff;
  @media screen and (max-width: 1300px) {
    font-size: 20px;
  }
  @media screen and (max-width: 1062px) {
    font-size: 18px;
  }
}
.maxBorrowAmountValueMob {
  font-size: 15px;
  line-height: 18px;
}
.underCollateral {
  color: #fd5252;
}
.skeletonContainer {
  padding-top: 4px;
}
