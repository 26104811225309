.buySellOptionDIv {
  margin-top: 30px;
}
.buySellOptionDIvMob {
  width: auto;
  padding-left: 0px;
  padding-right: 0px;
}
.buttonTableDiv {
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}
.buttonTableDivMob {
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.buttonHeadingDiv {
  background: #334453;
  border: 1px solid #334453;
  border-radius: 10px 10px 0px 0px;
  height: 62px;
}
.buySellButtons {
  padding-left: 47px;
  padding-top: 12px;
}
.buySellButtonsMob {
  padding-left: 16px;
}

.labelClass {
  margin-top: 20px;
  display: flex;
}

.currencyChooseDiv {
  display: flex;
  flex-direction: row;
}

.currencyOptions {
  padding-top: 25px;
  padding-left: 32px;
}
.currencyOptionsMob {
  padding-top: 16px;
  padding-left: 22px;
}
.fiatOption {
  border-radius: 8px;
  font-family: "Jura";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: transparent;
  border: 1px solid #02aab0;
  padding: 10px 22px;
  margin-right: 12px;
}
.fiatOption:hover {
  background: #02aab0;
}
.fiatOptionMob {
  font-size: 13px;
  line-height: 15px;
  padding: 6px 17px;
}
.sellButton {
  width: 78px;
  border-radius: 8px;
  border: 1px solid #02aab0;
  background: #02aab0;
  font-family: "Jura";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  color: #ffffff;
  padding: 3px 15px;
}
.buyButton {
  width: 78px;
  border-radius: 8px;
  border: 1px solid #02aab0;
  background: transparent;
  font-family: "Jura";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  color: #ffffff;
  padding: 3px 15px;
  margin-right: 20px;
}
.cryptoOption {
  border-radius: 8px;
  font-family: "Jura";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: transparent;
  border: 1px solid #02aab0;
  padding: 10px 22px;
}
.cryptoOptionMob {
  font-size: 13px;
  line-height: 15px;
  padding: 6px 17px;
}
.proceedButtonDiv {
  margin-top: 21px;
  padding-bottom: 20px;
  margin-right: 24px;
  display: flex;
  justify-content: flex-end;
}
.proceedButtonDivMob {
  margin-top: 0px;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 20px;
  margin-right: 19px;
}
.proceedContent {
  padding-top: 10px;
}
input[type="radio"] {
  appearance: none;
  content: "";
  width: 11px;
  height: 11px;
  border: 1px solid #02aab0;
  border-radius: 50%;
  opacity: 0.6;
  transition: 0.3s;
}

input[type="radio"]:hover {
  background: #02aab0;
  border-radius: 50%;
  border: 1px solid #ffffff;
  width: 11px;
  cursor: pointer;
  height: 11px;
}
input[type="radio"]:checked {
  background: #02aab0;
  border-radius: 50%;
  display: block;
  border: 1px solid #ffffff;
  width: 11px;
  height: 11px;
}
.bottomButton {
  display: flex;
  justify-content: flex-end;
}

.proceedButtonSec {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 23px;
}
.currencyChoose {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  padding-top: 25px;
  margin-left: 32px;
}
.currencyChooseMob {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin-left: 22px;
  padding-top: 20px;
}
.proceedIcon {
  padding-left: 6px;
}
.proceedIconMob {
  width: 5px;
  height: 9px;
}
.cancelButton {
  padding: 10px 45px;
  font-family: "metrophobic";
  color: white;
  font-size: 21px;
  line-height: 26px;
  border-radius: 4px;
  font-weight: 400;
  margin-right: 15px;
}
.cancelButtonMob {
  color: #fd5252;
  font-size: 22px;
  line-height: 27px;
  padding: 7px 0px;
  width: 100%;
}
.cancelButton:hover {
  color: #fd5252;
}
.proceedButton {
  padding: 10px 38px;
  font-family: "Metrophobic";
  color: white;
  font-size: 21px;
  line-height: 26px;
  border-radius: 4px;
  font-weight: 400;
}
.proceedIcon {
  padding-left: 6px;
}
.proceedButton:hover path,
.proceedButton:hover {
  color: #02aab0;
  fill: #02aab0;
}
.proceedButtonMob,
.proceedButtonMob path {
  color: #02aab0;
  fill: #02aab0;
  font-size: 22px;
  line-height: 27px;
  padding: 7px 0px;
  width: 100%;
}
.toolTipStyleForMemberVouch {
  background-color: #384455;
  border-radius: 12px;
  width: 100px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}
.dummyMemberVouchRadioButton {
  display: flex;
  border-radius: 6px;
  border: 1px solid #02aab0;
  padding: 8px 50px 8px 11px;
  gap: 6px;
  height: 21px;
  margin-left: 30px;
  cursor: pointer;
}
.dummyMemberVouchRadioButtonMob {
  margin-left: 0px;
  width: 92%;
  padding: 8px 0px 8px 11px;
}
.circle {
  border-radius: 50%;
  border: 0.5px solid #02aab0;
  width: 10px;
  height: 10px;
  display: block;
  margin-top: 6px;
}
.memberVouchButton {
  color: #808fa5;
}
.currencyOptions {
  display: flex;
}
