.securityDiv {
  padding-top: 27px;
  background-color: #06152b;
  padding-left: 31px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-right: 30.7%;
}
.securityDivMob {
  padding-left: 14px;
  font-size: 12px;
}
