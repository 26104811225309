.ethTable {
  margin-top: 300px;
  margin-right: 300px;
}
.collateralizationheading {
  padding: 12px 12px 0 29px;
  margin-bottom: 14px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
}
.collateralizationheadingMob {
  padding: 16px 20px;
  font-family: "Jura";
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 0px;
}
.questionmarkInHeader {
  width: 20px;
  height: 20px;
  margin-left: 8px;
  margin-bottom: -1px;
}
.questionmarkInHeader:hover {
  cursor: pointer;
}
.questionmarkInHeaderMob {
  margin-bottom: -3px;
}
.ButttonContent {
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.stepsButton1 {
  padding: 3px 26px;

  background: linear-gradient(47.47deg, #02aab0 5.98%, #00cdac 85.82%);
  border-radius: 5px;
  margin-left: 29px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  color: #000000;
}
.stepsButton1Mob {
  padding: 1px 17px;
  margin-left: 20px;
}
.stepsButton2 {
  border: 1px solid #02aab0;
  padding: 3px 26px;

  background: transparent;
  border-radius: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: #99b2c6;
}
.stepsButton2Mob {
  padding: 0px 17px;
}
.stepsButton3 {
  border: 1px solid #02aab0;
  padding: 3px 26px;
  background: transparent;
  border-radius: 5px;
  margin-right: 21px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: #99b2c6;
}
.stepsButton3Mob {
  padding: 0px 17px;
}
.step1 {
  margin-top: 20px;
  margin-left: 29px;
  font-family: "Jura";
  font-weight: 400;
  font-size: 16px;
  color: #99b2c6;
}
.step1Mob {
  font-size: 15px;
  margin-left: 20px;
}
.step1SelectBorrow {
  margin-left: 29px;
  margin-top: 9px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  @media only screen and (min-width: 1300px) {
    font-size: 18px;
  }
}
.step1SelectBorrowMob {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  margin-left: 20px;
}

.nextIcon {
  margin-left: 6px;
  margin-top: 2px;
}

.radioButtonDiv {
  display: flex;
  margin-top: 20px;
  margin-left: 29px;
}
.radioButtonDivMob {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 20px;
}
.radioButtonCrypto,
.radioButtonMemberVouch {
  display: flex;
}
.questionmark {
  margin-left: 6px;
  margin-top: 11px;
  margin-right: 26px;
  width: 20px;
  height: 20px;
}
.questionmarkMob {
  margin-top: 14px;
  width: 14px;
}
.arrowSymbol {
  margin-left: 6px;
}
.bottomSectionButton {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 54px;
  margin-right: 21px;
  padding-bottom: 30px;
  margin-left: 0;
}
.bottomSectionButtonMob {
  margin-top: 73px;
  margin-bottom: 14px;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: center;
}
.nextButton {
  padding: 10px 55px;
  color: #ffffff;
  border-radius: 4px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
}
.nextButton:hover {
  color: #02aab0;
  stroke: #02aab0;
}
.nextButtonMob {
  padding: 8px 0px;
  font-size: 16px;
  width: 50%;
}
.cancelButton {
  padding: 10px 52px;
  font-family: "Metrophobic";
  font-size: 21px;
  line-height: 26px;
  color: #ffffff;
  border-radius: 4px;
  margin-right: 12px;
}
.cancelButtonMob {
  padding: 8px 0px;
  font-size: 16px;
  width: 50%;
}
.cancelButton:hover {
  color: #fd5252;
}
hr {
  position: fixed;
  width: 52%;
  top: 206px;
}
.alertContainer {
  display: flex;
  flex-direction: row-reverse;
  width: 317px;
  margin-top: 30px;
  margin-right: 30.7%;
  margin-bottom: 30px;
  margin-left: auto;
  padding: 14px 10px 10px 8.6px;
  font-family: "Metrophobic";
  font-weight: 300;
  font-size: 14px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 10px;
}
.alertContainerMob {
  width: 255px;
  font-size: 11px;
  line-height: 13px;
  margin-right: auto;
  margin-left: auto;
  @media only screen and (max-width: 992px) {
    margin-right: 0;
    margin-left: auto;
  }
  @media only screen and (max-width: 450px) {
    margin-right: auto;
    margin-left: auto;
  }
}
.line {
  border-bottom: 1px solid #02aab0;
  width: 100%;
}
.bellIcon {
  margin-right: 8.73px;
}
.errorMessage {
  margin-left: 34px;
  margin-top: 20px;
  font-family: "Metrophobic";
  color: #fd5252;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.errorMessageMob {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin-top: 10px;
  margin-left: 20px;
}
.tooltip {
  background-color: #384455;
  opacity: 1;
  white-space: normal;
  border-radius: 12px;
  width: 176px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  padding: 10px 4px;
  font-family: "Roboto";
}
.toolTipStyle {
  background-color: #384455;
  border-radius: 12px;
  width: 192px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}
.toolTipStyleForMemberVouch {
  background-color: #384455;
  border-radius: 12px;
  width: 100px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}

.dummyMemberVouchRadioButton {
  display: flex;
  border-radius: 6px;
  border: 1px solid #02aab0;
  padding: 8px 50px 8px 11px;
  gap: 6px;
  margin-left: 30px;
  cursor: pointer;
}
.dummyMemberVouchRadioButtonMob {
  margin-left: 0px;
  width: 92%;
  padding: 8px 0px 8px 11px;
}
.circle {
  border-radius: 50%;
  border: 0.5px solid #02aab0;
  width: 10px;
  height: 10px;
  display: block;
  margin-top: 6px;
}
.memberVouchButton {
  color: #808fa5;
}
