.notificationDiv {
  display: flex;
  margin-right: 30.7%;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 30px;
}
.notificationDivMob {
  justify-content: center;
  margin-right: 0px;
  margin-top: 40px;
}
.alertMsg {
  margin: auto;
  font-family: "roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}
.alertMsgMob {
  font-size: 11px;
  line-height: 15px;
}
.alertImageAndMsg {
  display: flex;
  flex-direction: row;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 10px;
  width: 360px;
}
.alertImageAndMsgMob {
  width: 255px;
}
.bellIcon {
  margin: 10px auto 8px 8px;
}
.bellIconMob {
  margin: 5px auto 5px auto;
}
.healthPrediction {
  margin-top: 5px;
  display: flex;
}
.riskyCircle {
  margin: auto 3px;
  border-radius: 50%;
  background: #fd5252;
  width: 8px;
  height: 8px;
}
.mediumHealthyCircle {
  margin: auto 3px;
  border-radius: 50%;
  background: #f9b959;
  width: 8px;
  height: 8px;
}
.lowHealth {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #fd5252;
  margin-right: 15px;
}
.mediumHealth {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #f9b959;
}
.liquidateCardTitle {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
}
.liquidateCardTitleDiv {
  display: flex;
  justify-content: space-between;
  padding-left: 32px;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
}
.buttonsDiv {
  display: flex;
  background: #243958;
  border-radius: 5px;
  padding: 6px 10px;
}
.paymentMissedButton {
  border: none;
  color: #ffffff;
  padding: 1px 8px;
  border-radius: 5px;
  margin-right: 5px;
}
.assetDevaluationButton {
  border: none;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 5px;
}
.myNestTableDiv {
  margin-bottom: 40px;
}
.spinnerContainer {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 1px solid rgb(148, 148, 189);
  border-radius: 50%;
  border-top-color: rgb(64, 64, 64);
  animation: spin 1.5s ease-in-out infinite;
  -webkit-animation: spin 1.5s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}
.spinnerOverlay {
  height: 15vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
