.transactionSubmitDiv {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.transactionSubmitDivMob {
  width: 250px;
  margin-right: auto;
  margin-left: auto;
}
.transactionSubmit {
  margin-top: 75px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  text-align: center;
}
.transactionSubmitMob {
  font-size: 21px;
  line-height: 25px;
  margin-top: 35px;
}

.okayButtonDiv {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 75px;
}
.okayButtonDivMob {
  margin-top: 36px;
  margin-bottom: 43px;
}
.closeButton {
  padding: 8px 50px;
  font-family: "Metrophobic";
  color: white;
  font-size: 16px;
  line-height: 19px;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
}
.closeButton:hover {
  color: #fd5252;
}
.closeButtonMob {
  padding: 5px 45px;
  color: #fd5252;
}
