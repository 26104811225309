.butttonContent {
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 30.7%;
}
.butttonContentMob {
  margin-right: 0%;
}
.stepsButton1 {
  padding: 3px 26px;
  border: 1px solid #02aab0;
  background: transparent;
  border-radius: 5px;
  margin-left: 21px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
}
.stepsButton1Mob {
  padding: 1px 17px;
}
.stepsButton2 {
  border: 1px solid #02aab0;
  background: transparent;
  padding: 3px 26px;

  border-radius: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: #ffffff;
}
.stepsButton2Mob {
  padding: 1px 17px;
}
.stepsButton3 {
  padding: 3px 26px;
  background: #02aab0;
  border: 1px solid #02aab0;
  border-radius: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: white;
}
.stepsButton3Mob {
  padding: 1px 17px;
}
.line {
  border-bottom: 1px solid #02aab0;
  width: 100%;
}
.stepsButton4 {
  border: 1px solid #02aab0;
  background: transparent;
  padding: 3px 26px;
  border-radius: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: #ffffff;
}
.stepsButton4Mob,
.stepsButton5Mob {
  padding: 1px 17px;
}
.stepsButton5 {
  border: 1px solid #02aab0;
  background: transparent;
  padding: 3px 26px;
  margin-right: 21px;
  border-radius: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: #ffffff;
}
.gasTrackerContainer {
  position: fixed;
  bottom: 10px;
  right: 10px;
  left: 10px;
}
.step3Text {
  font-family: "Jura";
  font-weight: 400;
  color: #99b2c6;
}
.step3TextWeb {
  margin-left: 21px;
  margin-top: 22px;
  font-size: 16px;
  line-height: 19px;
}
.step3TextMob {
  margin-left: 21px;
  margin-top: 25px;
  font-size: 15px;
  line-height: 18px;
}
.personalInfo {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  color: #02aab0;
}
.personalInfoWeb {
  margin-left: 21px;
  margin-top: 9px;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 37px;
}
.personalInfoMob {
  margin-top: 3px;
  margin-left: 21px;
  margin-bottom: 21px;
  font-size: 13px;
  line-height: 16px;
}
.OTPDiv {
  display: flex;
  flex-direction: column;
  margin-left: 21px;
  border: 1px solid #02aab0;
  padding: 15px 0px 15px 38px;
  width: 306px;
  border-radius: 10px;
}
.OTPDivMob {
  flex-direction: column;
  gap: 0px;
  border: none;
  width: auto;
  padding: 0;
  margin-left: 10px;
}
.OTPText {
  font-family: "Metrophobic";
  font-weight: 400;
  color: #99b2c6;
}

.OTPTextWeb {
  font-size: 16px;
  line-height: 20px;
}
.OTPTextMob {
  margin-left: 11px;
  margin-bottom: 6px;
}
.OTPInput {
  width: 100%;
}
.OTPInput::placeholder {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 13px;
  line-height: 112.4%;
  color: #8d98aa;
}
.OTPInputMob {
  border: 1px solid #02aab0;
  width: 91%;
  padding: 17px 0px 15px 15px;
  border-radius: 8px;
}
.cancelButton,
.nextButton {
  font-family: "Metrophobic";
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.cancelButtonWeb {
  font-size: 16px;
  line-height: 20px;
  padding: 11px 60px;
  border-radius: 4px;
}
.cancelButtonWeb:hover {
  color: #fd5252;
}
.nextButtonWeb {
  padding: 11px 61px;
  font-size: 16px;
  border-radius: 4px;
}
.nextButtonWeb:hover {
  color: #02aab0;
}
.cancelButtonMob,
.nextButtonMob {
  width: 50%;
  padding: 11px 0px;
}
.bottomButtonDiv {
  display: flex;
  justify-content: flex-end;
  margin-right: 30.7%;
  gap: 20px;
  margin-top: 42px;
}
.bottomButtonDivMob {
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
}
.resendOTPDiv {
  display: flex;
  gap: 3px;
  margin-left: 61px;
  margin-top: 9px;
}
.resendOTPDivMob {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.didnotGet {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
}
.resendOTP {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: rgba(2, 170, 176, 1);
}
.arrowInNextButton {
  margin-left: 6px;
}
.error {
  margin-top: 16px;
  margin-left: 59px;
  font-size: 14px;
  line-height: 17px;
  color: #fd5252;
}
