.popUpDiv {
  padding: 38px 20px 29px 43px;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  font-family: "Metrophobic";
  color: #ffffff;
}

.popUpContent {
  color: #ffffff;
  font-family: "Metrophobic";
  font-size: 24px;
  font-weight: 500;
  border-radius: 0 0 10px 10px;
  margin-right: 0px;
}
.cancelButton {
  padding: 9px 78px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border: 1px solid #212c3d;
  color: #ffffff;
  border-radius: 4px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 123.8%;
}
.cancelButtonMob {
  padding: 10px 44px;
}
.cancelButton:hover {
  color: #fd5252;
}
.confirmButton {
  border: 1px solid #212c3d;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  padding: 9px 72px;
  color: #ffffff;
  border-radius: 4px;
  margin-left: 20px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 123.8%;
}
.confirmButtonMob {
  padding: 10px 40px;
}
.confirmButton:hover {
  color: #02aab0;
}
.popUpDivMob {
  padding-top: 26px;
  font-size: 18px;
  padding-bottom: 17px;
  font-family: "Metrophobic";
}
.popUpContentMob {
  font-size: 12px;
  font-family: "Metrophobic";
}
.popUpButtonDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 48px;
  padding-bottom: 33px;
}
.popUpButtonDivMob {
  padding-top: 40px;
  padding-bottom: 19px;
}
.popUpContentDiv {
  font-family: "Metrophobic";
  padding-left: 43px;
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  padding-bottom: 4px;
  color: #99b2c6;
  display: grid;
  grid-template-columns: 2fr 3fr 3fr;
}
.popUpContentDivMob {
  font-size: 12px;
  @media only screen and (max-width: 500px) {
    padding-right: 30px;
  }
}
.popUpContentValue {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #ffffff;
  padding-top: 8px;
}
.popUpContentValueMob {
  font-size: 14px;
}
.popUpContentValueDiv {
  font-family: "Metrophobic";
  font-weight: 400;
  margin-top: 5px;
  font-size: 21px;
  line-height: 26px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.popUpContentValueDivMob {
  font-size: 14px;
  margin-right: 81px;
  @media only screen and (max-width: 500px) {
    margin-right: 30px;
  }
}
.popUpContentDiv3 {
  margin-right: 87px;
}
.popUpContentDiv3Mob {
  margin-right: 7px;
}
.alertDeniedTransaction {
  font-size: 15px;
  font-weight: 400px;
  color: #fd5252;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 8px;
}
.alertDeniedTransactionMob {
  margin-top: 8px;
  font-size: 12px;
}
