.GastableDiv {
  margin: 40px auto auto auto;
  height: auto;
  width: 100%;
}
.GastableDivMob {
  margin: 0px;
}
.gasTableFirstRow {
  padding-top: 14px;
  padding-bottom: 6px;
  padding-left: 10px;
  background: #212c3d;
  border-bottom: 2px solid #02aab0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.gasTableThDiv {
  display: flex;
  background: linear-gradient(47.47deg, #02aab0 5.98%, #00cdac 85.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 15px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: "Metrophobic";
  @media screen and (min-width: 1900px) {
    font-size: 19px;
  }
}
.gasTrackerImage {
  height: 13px;
  width: 15px;
  margin-top: 3px;
  @media screen and (min-width: 1900px) {
    height: 15px;
    width: 17px;
  }
}
.gasTrackerImageMob {
  padding-right: -3px;
}
.gasTableColumn11 {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: space-between;
  font-size: 10px;
  padding-right: 49px;
  font-weight: 100;
  font-family: "Roboto";
  padding-left: 10px;
  color: #a5a2b8;
  @media screen and (min-width: 1600px) {
    padding-top: 25px;
    font-size: 12px;
  }
  @media screen and (min-width: 1900px) {
    padding-top: 38px;
    font-size: 14px;
  }
}
.gasTrackerKeys {
  gap: 100px;
  @media screen and (max-width: 370px) {
    gap: 78px;
  }
}
.gasTableColumn21 {
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: row;
  padding-right: 24px;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 400;
  font-family: "Metrophobic";
  color: #ffffff;
  @media screen and (min-width: 1600px) {
    font-size: 14px;
    padding-bottom: 16px;
  }
  @media screen and (min-width: 1900px) {
    padding-top: 7px;
    font-size: 15px;
    padding-bottom: 18px;
  }
}
.gasTrackerValues {
  gap: 63px;
  @media screen and (max-width: 370px) {
    gap: 40px;
  }
}
.gasTableSecondRow {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  line-height: 11.25px;
  font-style: bold;
}
.gasTableRow21 {
  padding-left: 7.5px;
}
.liveEth {
  display: flex;
  justify-content: space-between;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding-top: 7px;
  padding-left: 10px;
  padding-right: 16px;
  padding-bottom: 1px;
  @media screen and (min-width: 1600px) {
    padding-bottom: 4px;
    font-size: 17px;
  }
  @media screen and (min-width: 1900px) {
    padding-bottom: 6px;
    font-size: 18px;
  }
}
.liveEthVar {
  display: flex;
  justify-content: space-between;
}
.ETHToken {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #a5a2b8;
  padding-left: 10px;
  @media screen and (min-width: 1600px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1900px) {
    font-size: 13px;
  }
}
.ETHChangeVal {
  font-family: "Metrophobic";
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #fd5252;
  padding-right: 16px;
  @media screen and (min-width: 1600px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1900px) {
    font-size: 13px;
  }
}
.arrowIcon {
  width: 7px;
  padding-left: 5px;
}
