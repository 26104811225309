.avatarListMain {
  border: 1px dashed #00cdac;
  border-radius: 20px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin-left: 39px;
  margin-right: 39px;
  padding: 8px;

  margin-top: 14px;
}

.avatarIcon:hover {
  border: 2px solid #00cdac;
  cursor: pointer;
  border-radius: 50%;
}

.avatarIcon {
  border: 2px solid transparent;
  padding: 8px;
  border-radius: 50%;
  @media screen and (max-width: 992px) {
    width: 60px;
    height: 60px;
  }
}
