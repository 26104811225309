.contentSellDiv {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.enterAnAmountOfRUDToSell {
  padding-top: 32px;
  padding-bottom: 32px;
  margin-left: 32px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
}
.enterAnAmountOfRUDToSellSkeleton {
  padding: 32px 0 0 32px;
}
.enterAnAmountOfRUDToSellMob {
  font-size: 15px;
  line-height: 18px;
  padding-top: 20px;
  padding-bottom: 0px;
  margin-left: 16px;
}
input {
  background: transparent;
  border: none;
  color: #ffffff;
}
.rudpayh3 {
  font-size: 16px;
  font-family: "Metrophobic";
  font-weight: 400;
  padding-left: 38px;
  margin-bottom: 0;
  color: #99b2c6;
}
.rudpayh3Mob {
  padding-left: 26px;
  font-size: 16px;
  font-family: "Metrophobic";
  font-weight: 400;
}

.rudGeth3Result {
  font-family: "Metrophobic";
  font-size: 28px;
  font-weight: 400;
  color: #ffffff;
}

.rudgetDiv {
  height: 86px;
  margin-left: 32px;
  border-radius: 10px;
  margin-top: 30px;
  border: 1px solid #02aab0;
  margin-right: 32px;
}
.rudgetDivSkeleton {
  border: none;
  margin: 0;
}
.rudpayDiv {
  height: 86px;
  margin-left: 32px;
  border-radius: 10px;
  border: 1px solid #02aab0;
  margin-right: 32px;
}
.rudpayDivMob {
  margin-right: 16px;
  margin-left: 16px;
  margin-top: 20px;
}
.rudpayh3Div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 31px;
}
.rudpayh3DivMob {
  margin-right: 26px;
}
.maxButton {
  padding: 3px 17px;
  margin-top: 10px;
  border: 1px solid #02aab0;
  color: white;
  font-family: "Jura";
  font-size: 16px;
  border-radius: 5px;
}
.maxButton:hover {
  background: #02aab0;
}
.maxButtonMob {
  font-size: 12px;
  line-height: 14px;
  margin-top: 11px;
  padding: 2px 12px;
}
.rudgeth3 {
  font-size: 16px;
  font-family: "Metrophobic";
  font-weight: 400;
  padding-left: 38px;
  margin-bottom: 0;
  color: #99b2c6;
}
.rudgeth3Mob {
  padding-left: 26px;
  font-size: 16px;
  font-family: "Metrophobic";
  font-weight: 400;
}
.KycAlertDiv {
  display: flex;
  margin: 12px 342px 0px auto;
  width: 296.36px;
  height: 66px;
  border-radius: 10px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  align-items: center;
  @media screen and (max-width: 992px) {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
}
.BellIconImage {
  width: 25.88px;
  height: auto;
  padding-left: 13.95px;
}
.BellIconSpan {
  padding-left: 8.17px;
  font-size: 14px;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}
.EtherImageDiv {
  position: fixed;
  right: 363px;
  padding-bottom: 20px;
}
.EtherImage {
  position: relative;
  padding-bottom: 20px;
}
.alertinproceed {
  font-size: 12px;
  font-weight: 400;
  color: #fd5252;
  padding: 8px 0 0 86px;
}
.accordionContent {
  font-family: "Jura";
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.alertSpan {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #fd5252;
  margin-left: 86px;
  margin-top: 15px;
}
.accordionContentDiv {
  display: flex;
}
.accordionTitle {
  font-family: "Jura";
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.accordionTitleMob {
  font-size: 11px;
}
.accordionTitleDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
.accordionContentLineOne {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
::placeholder {
  color: #ffffff;
  font-family: "Metrophobic";
  font-size: 28px;
}
.rudGeth3ResultSpanOne {
  padding-left: 38px;
  font-size: 28px;
  font-family: "Metrophobic";
}
.rudGeth3ResultSpan {
  font-family: "Metrophobic";
  font-size: 28px;
  font-weight: 400;
  border: none;
  padding: 0px;
}
.rudpayInput {
  font-family: "Metrophobic";
  font-size: 28px;
  font-weight: 400;
  width: 100%;
}
.rudpayInputMob {
  font-family: "Metrophobic";
  font-size: 24px;
  font-weight: 400;
}
.inputFieldDiv {
  display: flex;
  flex-direction: row;
  font-family: "Metrophobic";
  font-size: 28px;
  font-weight: 400;
  margin-top: 4px;
}
.inputFieldDivMob {
  font-family: "Metrophobic";
  font-size: 24px;
  font-weight: 400;
}
.inpurPaySpan {
  margin-left: 39px;
  font-family: "Metrophobic";
  font-size: 28px;
  font-weight: 400;
}
.inputgetDivOneMain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 38px;
  font-size: 28px;
  font-family: "Roboto";
  padding-right: 38px;

  color: #99b2c6;
}
.inputgetDivOneMainMob {
  margin-left: 26px;
  padding-right: 26px;
}
.inputFieldDivOne {
  margin-left: 38px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 38px;
}
.inputgetDivOne {
  display: flex;
  flex-direction: row;
  font-family: "Metrophobic";
}
.inputgetDivMob {
  font-size: 24px;
}
.inputFieldDivOneMob {
  margin-left: 26px;
  font-size: 24px;
  padding-right: 26px;
}
.spanRUDGet {
  font-family: "Metrophobic";
  font-size: 28px;
  font-weight: 400;
  margin: 0px;
}
.spanRUDGetMob {
  font-family: "Metrophobic";
  font-size: 24px;
  font-weight: 400;
}
.accordionItem {
  border: 0.2px solid #a5a2b8;
  border-radius: 5px;
  margin: 15px 34px 0 43px;
  padding-right: 9px;
  padding-left: 23px;
}
.accordionItemMob {
  padding-right: 5px;
  padding-left: 5px;
  margin-right: 16px;
  margin-left: 16px;
}
.accordion {
  height: 5px;
}

.kycAlert {
  display: flex;
  justify-content: flex-end;
  padding-right: 300px;
  @media screen and (max-width: 992px) {
    padding: 0px;
  }
}
.accordionTitle:hover {
  color: #02aab0;
}
.accordionTitle:active {
  color: #02aab0;
}
.downIcon:hover {
  fill: #a5a2b8;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popupContent {
  border-radius: 10px;
  height: auto;
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
.accordionDivOne {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.feeSection {
  @media screen and (max-width: 338px) {
    width: 29%;
  }
}
.accordionDivOneMob {
  font-size: 9px;
}
.proceedCurrencyChangeMob {
  padding: 3px 16px;
  margin: 40px 16px 18px auto;
  font-size: 14px;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 40%;
  height: auto;
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
.proceedButtonDiv {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 22px;
  margin-right: 42px;
  margin-top: 38px;
}
.proceedButtonDivMob {
  padding-bottom: 16px;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 33px;
}
.proceedButton {
  padding: 10px 38px;
  border-radius: 4px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: white;
}
.proceedButton:hover {
  color: #02aab0;
  stroke: #02aab0;
}
.proceedButtonMob {
  font-size: 22px;
  line-height: 27px;
  padding: 7px 0px;
  width: 100%;
  color: #02aab0;
  stroke: #02aab0;
}
.proceedIcon {
  padding-left: 6px;
}
.proceedIconMob {
  width: 5px;
}
.backButton {
  padding: 10px 45px;
  font-family: "metrophobic";
  color: white;
  font-size: 21px;
  line-height: 26px;
  border-radius: 4px;
  font-weight: 400;
  margin-right: 15px;
}
.backButtonMob {
  color: #fd5252;
  font-size: 22px;
  line-height: 27px;
  padding: 7px 0px;
  width: 100%;
}
.backButton:hover {
  color: #fd5252;
}
