.headingText {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 43px;
  color: #ffffff;
  padding: 3px 32px;
}
.headingTextMob {
  font-size: 21px;
  line-height: 25px;
  padding: 4px 19px;
}
.rudContent {
  display: flex;
  justify-content: space-between;
  padding-right: 44px;
}
.rudContentMob {
  display: flex;
  flex-direction: column;
  padding-right: 0px;
}
.contentText {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #f9b959;
  padding: 35px 5px 41px 32px;
}
.contentTextMob {
  font-size: 13px;
  line-height: 15px;
  padding: 16px 5px 27px 21px;
}
.buyRudButtonDiv {
  padding-top: 24px;
  @media screen and (max-width: 1130px) {
    padding-top: 33px;
  }
  @media screen and (max-width: 1070px) {
    padding-top: 42px;
  }
}
.buyRudButtonDivMob {
  padding-top: 0px;
  margin-left: 21px;
  margin-right: 21px;
  padding-bottom: 21px;
}
.buyButton {
  padding: 7px 63px;
  font-family: "Metrophobic";
  color: white;
  font-size: 24px;
  line-height: 30px;
  border-radius: 4px;
  font-weight: 400;
  @media screen and (max-width: 1200px) {
    padding: 6px 40px;
  }
}
.buyButton:hover {
  color: #02aab0;
}
.buyButtonMob {
  color: #02aab0;
  padding: 9px 0px;
  width: 100%;
}
.gasTrackerContainer {
  position: fixed;
  bottom: 20px;
  margin: 0px;
  right: 28px;
  left: 28px;
  width: auto;
}
.contentAfterRUDBuy {
  padding-right: 20px;
  flex-direction: column;
}
.rudReturnUserContent {
  padding-top: 35px;
  padding-bottom: 46px;
  margin-left: 32px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #f9b959;
  padding-right: 10px;
}
.returnUserContentMob {
  padding-top: 16px;
  padding-bottom: 30px;
  margin-left: 21px;
  font-size: 13px;
  line-height: 16px;
  margin-top: 0px;
  padding-right: 5px;
}
.buySellButtons {
  margin: auto 0px;
}
.buySellButtonsMob {
  padding-top: 0px;
  margin-left: 21px;
  margin-right: 21px;
  padding-bottom: 21px;
}
.sellRudButton {
  font-family: "Metrophobic";
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  padding: 7px 65px;
  border-radius: 5px;
  @media screen and (max-width: 1250px) {
    padding: 6px 50px;
  }
}
.sellRudButton:hover {
  color: #02aab0;
}
.sellRudButtonMob {
  color: #02aab0;
  width: 100%;
  padding: 9px 0px;
}
.buttonsAfterRudBuy {
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}
.buyButtonAfterRudBuy {
  margin-right: 20px;
}
.contentAfterRUDBuyMob {
  padding-right: 0px;
}
.buyButtonAfterRudBuyMob {
  margin-right: 15px;
}
.buttonsAfterRudBuyMob {
  padding-bottom: 15px;
}
