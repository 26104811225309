.titleDiv {
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 400;
  font-family: "Metrophobic";
}
.titleDivWeb {
  padding: 9px 21px;
  font-size: 28px;
  line-height: 35px;
}
.titleDivMob {
  padding: 10px 21px;
  font-size: 21px;
  line-height: 26px;
}
.bottomButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-right: 44px;
  padding-bottom: 20px;
}
.bottomButtonContainerWeb {
  margin-top: 63px;
}
.bottomButtonContainerMob {
  margin-top: 44px;
  margin-right: 0px;
  justify-content: center;
}
.loanDetails {
  margin-left: 20px;
  color: #02aab0;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
}
.loanDetailsWeb {
  padding-top: 20px;
  font-size: 18px;
  @media only screen and (min-width: 1300px) {
    font-size: 20px;
  }
}
.loanDetailsMob {
  padding-top: 9px;
  padding-bottom: 8px;
}
.nestTableHeading {
  margin-left: 20px;
  margin-right: 84px;
  display: flex;
  gap: 175px;
  margin-bottom: 22px;
}

.nestDetailsHeading {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #9c99ae;
  @media only screen and (min-width: 1300px) {
    font-size: 16px;
  }
}

.nestDetails {
  display: flex;
  flex-direction: column;
}
.nestDetailsValue {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #ffffff;
  @media only screen and (min-width: 1300px) {
    font-size: 18px;
  }
}

.selectPaymentTermDiv {
  font-family: "Metrophobic";
  font-weight: 400;

  color: #ffffff;
}
.selectPaymentTermDivWeb {
  margin-top: 37px;
  margin-left: 21px;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: 26px;
}
.selectPaymentTermDivMob {
  margin-top: 20px;
  font-size: 14px;
  margin-left: 21px;
}
.paymentTermRadioButton {
  display: flex;
  gap: 40px;
}
.paymentTermRadioButtonWeb {
  margin-top: 20px;
}
.paymentTermRadioButtonMob {
  flex-direction: column;
  gap: 11px;
  margin-top: 18px;
}
.paymentOptionInMobile {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #99b2c6;
  margin-left: 10px;
}
.alertContainer {
  display: flex;
  flex-direction: row-reverse;
  width: 330px;
  font-weight: 400;
  line-height: 16px;

  margin: 12px 0px 0px auto;
  padding: 14px 10px 10px 8.6px;
  font-family: "metrophobic";
  font-size: 14px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 10px;
  @media only screen and (max-width: 495px) {
    width: 298px;
  }
}
.bodyContainer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: auto;
  margin-bottom: 30px;
}
.bellIcon {
  margin-right: 9px;
}
.nestDetailsMainDivMob {
  margin-top: 70px;
  border-radius: 10px;
}
.paymentOptionOne,
.paymentOptionTwo {
  border: 1px solid #02aab0;
}

.paymentOptionTwoWeb {
  display: flex;
  gap: 7px;
  padding: 11px 92px 17px 11px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  border-radius: 8px;
  margin-left: 30px;
  border: 1px solid #02aab0;
  cursor: pointer;

  @media only screen and (max-width: 992px) {
    width: 93%;
    gap: 0px;
    margin-left: 0px;
    padding: 11px 0px 17px 11px;
  }
}

.activeLabelClassOptionTwo {
  display: flex;
  gap: 7px;
  padding: 11px 92px 17px 11px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  border-radius: 8px;
  margin-left: 30px;
  border: 1px solid #02aab0;
  cursor: pointer;
  background: linear-gradient(
    47.47deg,
    rgba(2, 170, 176, 0.2) 5.98%,
    rgba(0, 205, 172, 0.2) 85.82%
  );
  @media only screen and (max-width: 992px) {
    width: 93%;
    margin-left: 0px;
    gap: 0px;
    padding: 11px 0px 17px 11px;
  }
}
.paymentOptionOneWeb {
  display: flex;
  gap: 7px;
  padding: 11px 92px 17px 11px;

  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #02aab0;
  @media only screen and (max-width: 992px) {
    font-size: 13px;
    width: 93%;
    gap: 0px;
    padding: 11px 0px 17px 11px;
  }
}

.activeLabelClassOptionOne {
  padding: 11px 92px 17px 11px;
  display: flex;
  gap: 7px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  border-radius: 8px;
  border: 1px solid #02aab0;
  cursor: pointer;
  background: linear-gradient(
    47.47deg,
    rgba(2, 170, 176, 0.2) 5.98%,
    rgba(0, 205, 172, 0.2) 85.82%
  );
  @media only screen and (max-width: 992px) {
    width: 93%;
    padding: 11px 0px 17px 11px;
  }
}

.paymentOptions {
  display: flex;
  gap: 101px;
  margin-left: 21px;
  margin-top: 6px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #99b2c6;
}
.nextPaymentTermSpan,
.fullPaymentTermSpan {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  color: #99b2c6;
  margin-left: 10px;
}
.cancelButton,
.confirmButton {
  font-family: "Metrophobic";
  font-weight: 400;
  color: #ffffff;
  border-radius: 8px;
}
.cancelButtonWeb {
  padding: 10px 52px;
  font-size: 21px;
  line-height: 26px;
}
.cancelButtonMob {
  padding: 13px 45px;
  font-size: 18px;
  color: #fd5252;
}
.confirmButtonWeb {
  padding: 10px 47px;
  font-size: 21px;
  line-height: 26px;
  margin-left: 15px;
}
.confirmButtonMob {
  padding: 13px 35px;
  font-size: 18px;
  margin-left: 10px;
  color: #02aab0;
}
.confirmButtonWeb:hover {
  color: #02aab0;
}
.cancelButtonWeb:hover {
  color: #fd5252;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popupContent {
  border-radius: 10px;
  height: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
.errorMessage {
  font-size: 14px;
  line-height: 14px;
  font-family: "Metrophobic";
  font-weight: 400;
  color: #fd5252;
  margin-top: 16px;
  margin-left: 21px;
}
.loanBalancePaymentSchedule {
  display: flex;
  gap: 152px;
  margin-top: 10px;
  margin-left: 21px;
  margin-right: 21px;
}
.radioButtonStyles {
  width: 20px;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 40%;
  height: auto;
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
.repayAmountDiv {
  border: 1px solid #02aab0;
  border-radius: 10px;
  padding-left: 32px;
  padding-bottom: 13px;
  margin-left: 21px;
  margin-right: 29px;
  margin-top: 20px;
}
.repayAmountDivMob {
  padding-left: 20px;
  margin-left: 17px;
  margin-right: 19px;
  padding-bottom: 11px;
  margin-bottom: 10px;
}
.repayAmount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.repayingAmount {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #99b2c6;
  padding-bottom: 4px;
  padding-top: 15px;
}
.repayingAmount {
  padding-top: 9px;
}
.maxButton {
  padding: 3px 17px;
  border: 1px solid #02aab0;
  box-shadow: 0 0 2px #02aab0;
  font-family: "metrophobic";
  background: transparent;
  color: white;
  font-size: 16px;
  line-height: 19px;
  border-radius: 5px;
  font-weight: 400;
  margin-right: 39px;
  margin-top: 11px;
}
.maxButton:hover {
  background: #02aab0;
}
.maxButtonMob {
  font-size: 14px;
  line-height: 17px;
  margin-top: 9px;
  padding: 2px 12px;
  margin-right: 9px;
  border-radius: 8px;
}
input {
  background: transparent;
  border: none;
  color: #ffffff;
}

.repayingValue {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.inputAmount {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  width: 100%;
}
.inputAmountMob {
  font-size: 24px;
  line-height: 28px;
}
.payingCrypto {
  font-family: "roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  margin-right: 44px;
}
.payingCryptoMob {
  font-size: 28px;
  line-height: 33px;
  margin-right: 10px;
}
.radioButtonDiv {
  display: flex;
  margin-top: 20px;
  gap: 153px;
}
.radioButtonDivMob {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.radioButtonNextPayment,
.radioButtonFullPayment {
  display: flex;
}
