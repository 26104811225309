.getSignerMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.getSignerMainContainerWeb {
  padding-top: 102px;
  padding-bottom: 62px;
}
.getSignerMainContainerMob {
  padding-top: 71px;
  padding-bottom: 65px;
  padding-left: 15px;
  padding-right: 15px;
}
.youCancelledTheSignature {
  font-family: "Metrophobic";
  font-weight: 400;
  color: #ffffff;
  margin-top: 22px;
  text-align: center;
}
.youCancelledTheSignatureWeb {
  font-size: 25px;
}
.youCancelledTheSignatureMob {
  font-size: 20px;
}
.closeAndInitiate {
  margin-top: 36px;
  font-family: "Jura";
  font-weight: 400;
  text-align: center;
  color: #ffffff;
}
.closeAndInitiateWeb {
  font-size: 21px;
  line-height: 25px;
}
.closeAndInitiateMob {
  font-size: 20px;
}
.closeButton {
  padding: 10px 49px;
  border-radius: 10px;
  border: 1px solid rgba(2, 170, 176, 1);
  font-family: "Roboto";
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
  margin-top: 21px;
  background: none;
}

.FailedSignerTransactionIcon {
  opacity: 0;
  animation: appear 1s ease-in-out forwards;
}
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
