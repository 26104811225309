.transactionSubmitDiv {
  background: rgba(8, 21, 39, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
}
.crossIconDiv {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  margin-right: 23px;
}
.crossIconDivMob {
  margin-top: 16px;
  margin-right: 15px;
}
.crossIcon {
  width: 27px;
  height: 27px;
}
.crossIconMob {
  width: 17px;
  height: 17px;
}
.uploadIconDiv {
  display: flex;
  justify-content: center;
  padding-top: 75px;
}
.uploadIconDivMob {
  padding-top: 45px;
}
.uploadIcon {
  width: 92px;
  height: 92px;
}
.uploadIconMob {
  width: 66px;
  height: 66px;
}
.nestIsClosed {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 30px;
  line-height: 37px;
  color: #02aab0;
  text-align: center;
  margin-top: 3px;
}
.nestIsClosedMob {
  font-size: 21px;
}
.transactionSubmit {
  color: #ffffff;
  text-align: center;
  margin-top: 15px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
}
.transactionSubmitMob {
  font-size: 21px;
  line-height: 25px;
  margin-top: 14px;
}
.viewTransactionDetails {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 24px;
  color: white;
  text-align: center;
  padding-top: 31px;
  padding-bottom: 31px;
  padding-left: 33px;
  padding-right: 33px;
}
.viewTransactionDetailsMob {
  padding-top: 17px;
  padding-bottom: 17px;
}
.OKButtonDiv {
  padding-bottom: 28px;
  display: flex;
  justify-content: center;
}
.OKButtonDivMob {
  margin-top: 10px;
  margin-bottom: 13px;
}
.OKButton {
  padding: 9px 99px;
  font-family: "Metrophobic";
  color: white;
  font-weight: 400;
  font-size: 24px;
  line-height: 123.8%;
  border-radius: 4px;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
}
.OKButtonMob {
  padding: 9px 60px;
  font-size: 20px;
}
.OKButton:hover {
  color: #02aab0;
}
