.mainDiv {
  box-sizing: border-box;
  width: 21%;
  display: flex;
  flex-direction: column;
  position: fixed;
  min-height: 500px;
  right: 20px;
  top: 0;
  padding-top: 39px;
  height: 100%;
  padding-left: 20px;
  border-left: 1px solid #343339;
  z-index: 2;
  @media screen and (min-width: 993px) {
    padding-left: 10px;
    right: 15px;
  }
  @media screen and (min-width: 1240px) {
    padding-left: 15px;
  }
  @media screen and (min-width: 1400px) {
    padding-left: 25px;
  }
}
.mainDivMob {
  width: 100%;
  position: unset;
}
.statistics {
  background: linear-gradient(180deg, #0d192b 0%, #101d2e 100%);
  padding: 17px 10px;
  border-radius: 10px;

  @media screen and (min-width: 993px) {
    margin-top: 37px;
  }

  @media screen and (min-width: 1440px) {
    // margin-top: 63px;
  }
}
.userStatsDiv {
  display: flex;
  justify-content: space-between;
}
.userKyc {
  display: flex;
  justify-content: flex-end;
}
