.stakeTitleContainer {
  display: flex;
  flex-direction: row;
}
.stakeHeadingText {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
  padding-top: 10px;
  padding-left: 47px;
  padding-bottom: 10px;
}
.stakeHeadingTextMob {
  font-size: 21px;
  line-height: 18px;
  padding-top: 10px;
  padding-left: 21px;
  padding-bottom: 5px;
}
.addRudInLP {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  padding-top: 25px;
  padding-left: 32px;
  padding-right: 20px;
  padding-bottom: 21px;
}
.addRudInLPMob {
  font-size: 15px;
  line-height: 18px;
  padding-top: 16px;
  padding-left: 21px;
  padding-right: 8px;
  padding-bottom: 19px;
}
.stakeButtonsDiv {
  padding-top: 55px;
  display: flex;
  justify-content: flex-end;
  margin-right: 49px;
  margin-bottom: 25px;
}
.stakeButtonsDivMob {
  padding-top: 65px;
  margin-right: 13px;
  margin-bottom: 15px;
}
.getRUDButton {
  font-family: "Jura";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  background: transparent;
  color: white;
  padding: 10px 25px;
  border-radius: 8px;
  margin-right: 12px;
}
.getRUDButton:hover {
  background: #02aab0;
}
.getRUDButtonMob {
  font-size: 13px;
  line-height: 15px;
  padding: 6px 19px;
  border-radius: 5px;
}
.depositButton {
  font-family: "Jura";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  background: transparent;
  color: white;
  padding: 10px 31px;
  border-radius: 8px;
}
.depositButton:hover {
  background: #02aab0;
}
.depositButtonMob {
  border-radius: 5px;
  padding: 6px 24px;
  font-size: 13px;
  line-height: 15px;
}
.questionIconDiv {
  margin: 17px auto 14px 11px;
}
.questionIconDivMob {
  margin: 10px auto 2px 8px;
}
.questionIcon {
  width: 20px;
  height: 20px;
}
.questionIcon:hover {
  cursor: pointer;
}
.questionIconMob {
  width: 16px;
  height: 16px;
}
.stakeRUD {
  border: 1px solid #02aab0;
  border-radius: 10px;
  padding-left: 39px;
  padding-bottom: 13px;
  margin-left: 32px;
  margin-right: 29px;
  margin-bottom: 11px;
}
.stakeRUDMob {
  padding-left: 20px;
  margin-left: 17px;
  margin-right: 19px;
  padding-bottom: 11px;
  margin-bottom: 10px;
}
.stakeRUDInLPDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.depositingRUD {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #99b2c6;
  padding-bottom: 4px;
  padding-top: 15px;
}
.depositingRUDMob {
  padding-top: 9px;
}
.maxButton {
  padding: 3px 17px;
  border: 1px solid #02aab0;
  box-shadow: 0 0 2px #02aab0;
  font-family: "metrophobic";
  background: transparent;
  color: white;
  font-size: 16px;
  line-height: 19px;
  border-radius: 5px;
  font-weight: 400;
  margin-right: 39px;
  margin-top: 11px;
}
.maxButton:hover {
  background: #02aab0;
}
.maxButtonMob {
  font-size: 14px;
  line-height: 17px;
  margin-top: 9px;
  padding: 2px 12px;
  margin-right: 9px;
  border-radius: 8px;
}
input {
  background: transparent;
  border: none;
  color: #ffffff;
}

.payingEthValue {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.inputAmount {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  width: 100%;
}
.inputAmountMob {
  font-size: 24px;
  line-height: 28px;
}
.payingCrypto {
  font-family: "roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  margin-right: 44px;
}
.payingCryptoMob {
  font-size: 28px;
  line-height: 33px;
  margin-right: 10px;
}
.dollarSymbol {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
}
.dollarSymbolMob {
  font-size: 24px;
  line-height: 33px;
}
.poolShareDiv {
  display: flex;
  flex-direction: column;
  margin-left: 68px;
  margin-bottom: 25px;
}
.poolShareDivMob {
  margin-left: 37px;
  margin-bottom: 30px;
}
.poolShare {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
  margin-bottom: 4px;
}
.poolShareMob {
  font-size: 14px;
  line-height: 17px;
}
.sharePercentage {
  font-family: "roboto";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.sharePercentageMob {
  font-size: 16px;
  line-height: 19px;
}
.ButtonsDiv {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  margin-right: 29px;
}
.ButtonsDivMob {
  justify-content: center;
  margin-bottom: 19px;
  margin-right: 19px;
}
.alert {
  justify-self: flex-start;
  margin-left: 68px;
}
.cancelButton {
  border-radius: 8px;
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #ffffff;
  padding: 10px 52px;
  margin-right: 15px;
}
.cancelButton:hover {
  color: #fd5252;
}
.cancelButtonMob {
  font-family: "metrophobic";
  width: 50%;
  padding: 8px 45px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-right: 16px;
  color: #fd5252;
  @media screen and (max-width: 360px) {
    padding: 8px 15px;
  }
}

.confirmButton {
  border-radius: 8px;
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #ffffff;
  padding: 10px 47px;
}
.confirmButton:hover {
  color: #02aab0;
}
.confirmButtonMob {
  font-family: "metrophobic";
  width: 50%;
  padding: 8px 41px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #02aab0;
  @media screen and (max-width: 360px) {
    padding: 8px 15px;
  }
}
.alertBeforeConfirm {
  font-size: 12px;
  line-height: 14px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  color: #fd5252;
  margin-top: 12px;
}
.alertBeforeConfirmMob {
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  margin: 11px 13px auto auto;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  color: #fd5252;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 45%;
  height: auto;
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
.notificationDiv {
  display: flex;
  margin-right: 30.7%;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 30px;
}
.notificationDivMob {
  justify-content: center;
  margin-right: 0px;
  margin-top: 40px;
}
.alertMsg {
  margin: auto;
  font-family: "roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}
.alertMsgMob {
  font-size: 11px;
  line-height: 15px;
}
.alertImageAndMsg {
  display: flex;
  flex-direction: row;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 10px;
  width: 320px;
}
.alertImageAndMsgMob {
  width: 255px;
}
.bellIcon {
  margin: 10px auto 8px 8px;
}
.bellIconMob {
  margin: 5px auto 5px auto;
}
.mobButtonsDiv {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 25px;
}
.toolTipStyle {
  background-color: #384455;
  border-radius: 12px;
  width: 290px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}
.stakingDurationDropdownDiv {
  margin-left: 68px;
  margin-bottom: 40px;
}
.stakingDurationDropdownDivMob {
  margin-left: 20px;
  margin-bottom: 30px;
}
