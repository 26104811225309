.personalInformationMainDiv {
  background: #06152b;
}
.butttonContent {
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 30.7%;
}
.butttonContentMob {
  margin-right: 0%;
}
.stepsButton1 {
  padding: 3px 26px;
  border: 1px solid #02aab0;
  background: transparent;
  border-radius: 5px;
  margin-left: 21px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
}
.stepsButton1Mob {
  padding: 1px 17px;
}
.stepsButton2 {
  border: 1px solid #02aab0;

  padding: 3px 26px;
  background: #02aab0;
  border-radius: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: #ffffff;
}
.stepsButton2Mob {
  padding: 1px 17px;
}
.stepsButton3 {
  padding: 3px 26px;
  background: transparent;
  border: 1px solid #02aab0;
  border-radius: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: white;
}
.stepsButton3Mob {
  padding: 1px 17px;
}
.line {
  border-bottom: 1px solid #02aab0;
  width: 100%;
}
.stepsButton4 {
  border: 1px solid #02aab0;
  background: transparent;
  padding: 3px 26px;
  border-radius: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: #ffffff;
}
.stepsButton4Mob,
.stepsButton5Mob {
  padding: 1px 17px;
}
.stepsButton5 {
  border: 1px solid #02aab0;
  background: transparent;
  padding: 3px 26px;
  margin-right: 21px;
  border-radius: 5px;
  margin-right: 21px;

  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: #ffffff;
}
.gasTrackerContainer {
  position: fixed;
  bottom: 10px;
  right: 10px;
  left: 10px;
}
.step1Text {
  font-family: "Jura";
  font-weight: 400;
  color: #99b2c6;
}
.step1TextWeb {
  margin-left: 21px;
  margin-top: 22px;
  font-size: 16px;
  line-height: 19px;
}
.step1TextMob {
  margin-left: 24px;
  margin-top: 25px;
  font-size: 15px;
  line-height: 18px;
}
.personalInfo {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  color: #02aab0;
}
.personalInfoWeb {
  margin-left: 21px;
  margin-top: 9px;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 37px;
}
.personalInfoMob {
  margin-top: 3px;
  margin-left: 24px;
  margin-bottom: 16px;
  font-size: 13px;
  line-height: 16px;
}
.countryDiv,
.regionDiv,
.addressLineOneDiv,
.addressLineTwoDiv,
.cityDiv,
.zipCodeDiv {
  display: flex;
  flex-direction: column;
  margin-left: 21px;
  border: 1px solid #02aab0;
  padding: 15px 0px 15px 21px;

  border-radius: 10px;
}
.countryContainer {
  display: flex;
  gap: 98px;
  margin-right: 30.6%;
  margin-left: 21px;
}
.countryContainerMob {
  flex-direction: column;
  gap: 36px;
  margin-left: 25px;
  margin-right: 0;
}
.addressContainer,
.cityPinCodeContainer {
  display: flex;
  gap: 78px;
  margin-top: 30px;
}
.addressContainerMob,
.cityPinCodeContainerMob {
  flex-direction: column;
  gap: 0px;
  margin-top: 0px;
  margin-left: 25px;
}
.countryDivMob,
.regionDivMob,
.addressLineOneDivMob,
.addressLineTwoDivMob,
.cityDivMob,
.zipCodeDivMob {
  flex-direction: column;
  gap: 0px;
  border: none;
  margin-left: 0px;
  padding: 0;
}
.countryText,
.regionText,
.addressLineOnetext,
.addressLineTwotext,
.cityText,
.zipCodeText {
  font-family: "Metrophobic";
  font-weight: 400;

  color: #99b2c6;
}
.countryTextWeb,
.regionTextWeb {
  font-size: 16px;
  line-height: 20px;
}
.countryTextMob,
.regionTextMob,
.addressLineOnetextMob,
.addressLineTwotextMob,
.cityTextMob,
.zipCodeTextMob {
  margin-top: 21px;
  margin-bottom: 6px;
}
.countryInput,
.regionInput,
.addressLineOneInput,
.addressLineTwoInput,
.cityInput,
.zipCodeInput {
  background: transparent;
  width: 270px;
  @media screen and (max-width: 1381px) {
    width: 250px;
  }
  @media screen and (max-width: 1214px) {
    width: 226px;
  }
}
.countryInput::placeholder,
.regionInput::placeholder,
.addressLineOneInput::placeholder,
.addressLineTwoInput::placeholder,
.cityInput::placeholder,
.zipCodeInput::placeholder {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 13px;
  line-height: 112.4%;
  color: #8d98aa;
}
.countryInputMob,
.regionInputMob,
.addressLineOneInputMob,
.addressLineTwoInputMob,
.cityInputMob,
.zipCodeInputMob {
  padding: 17px 0px 15px 17px;
  width: 89%;
  border: 1px solid #02aab0;
  border-radius: 5px;
}
.cancelButton,
.nextButton {
  font-family: "Metrophobic";
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.cancelButtonWeb {
  font-size: 16px;
  line-height: 20px;
  padding: 11px 60px;
  border-radius: 4px;
}

.nextButtonWeb {
  padding: 11px 61px;
  font-size: 16px;
  border-radius: 4px;
}
.nextButtonWeb:hover {
  color: #02aab0;
}
.cancelButtonWeb:hover {
  color: #fd5252;
}
.cancelButtonMob {
  width: 50%;
  padding: 11px 0px;
  border-radius: 4px;
}

.nextButtonMob {
  padding: 11px 0px;
  width: 50%;
  border-radius: 4px;
}
.bottomButtonDiv {
  display: flex;
  justify-content: flex-end;
  margin-right: 30.7%;
  gap: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.bottomButtonDivMob {
  margin-right: 20px;
  margin-left: 20px;
  justify-content: center;
  margin-top: 30px;
}
.arrowInNextButton {
  margin-left: 6px;
}
.error {
  margin-top: 16px;
  margin-left: 22px;
  font-size: 14px;
  line-height: 17px;
  color: #fd5252;
}
