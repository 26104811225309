.menuBar {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 100%;
  z-index: 2;

  background-color: #121c2b;
}

.nav {
  text-decoration: none;
  color: #a5a2b8;
  height: 17px;
  font-family: "Jura";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  width: 100%;
}
.activeNav {
  text-decoration: none;
  height: 17px;
  font-family: "Jura";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #02aab0;
  filter: drop-shadow(0 0 1px);
}
.MainmenuBar {
  position: fixed;
  width: 100%;
  top: 111px;
  height: 276px;
  background-color: #121c2b;
}
.menuBarEnterActive {
  display: block;
  left: 100%;
}

.menuBarEnter {
  display: block;
  left: 0px;
  transition: 0.5s ease;
}
.menuBarExitActive {
  display: block;
  left: 0px;
}
.menuBarExit {
  display: block;
  left: 100%;
  transition: 0.5s ease;
}
.menuIcon {
  width: 22px;
  height: 22px;
}
.primaryNav {
  display: flex;
  flex-direction: column;
  gap: 24px;
  list-style: none;
  margin-top: 20px;
  margin: 0;
  padding-left: 6px;
  li {
    font-family: "Jura";
    font-size: 18px;
  }
  li:hover {
    color: #02aab0;
  }
}
.profileNameInMobile {
  margin-top: 16px;
  margin-left: 12px;
}
.mobileButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: -55px;
}
.hamburgerButton {
  justify-self: end;
  background: linear-gradient(90deg, #212d3d 0%, #0e1a2c 100%);
  border-radius: 5px;
  width: 39px;
  height: 39px;
  padding: 5px 5px 2px;
  margin-right: 14px;
  margin-left: 12px;
  z-index: 3;
}
.hbWhite {
  border: none;
}

.hbGreen {
  border: none;
}
.mainDivMob {
  width: 100%;
}
.ProfileSecMob {
  top: 19px;
  position: fixed;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
}
.kYCMob {
  position: absolute;
  top: 415px;
  right: 26px;
}
.StatisticsMob {
  display: flex;
  flex-direction: column-reverse;
  gap: 11px;
  position: relative;
  top: 696px;
  font-size: 12px;
  padding: 29px 26px 0 26px;
}
.docButttonMob {
  margin: 745px auto auto;
  padding-right: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerMob {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ulDiv {
  padding-left: 26px;
  border-bottom: 1px solid #a5a2b8;
  padding-bottom: 24px;
  width: 100%;
  padding-top: 24px;
}
.profileNameMob {
  margin: auto 0 auto 12px;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 100%;
  height: auto;
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
