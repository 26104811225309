.liquidateHeaderContainer {
  padding-top: 50px;
  text-align: center;
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
}
.passingParameters {
  display: flex;
  margin-top: 30px;
}
.passingParametersMob {
  margin-top: 22px;
}
.rudPriceinfo {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.currentRudPrice {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #99b2c6;
  padding-left: 45px;
}
.currentRudPriceMob {
  padding-left: 17px;
  font-size: 15px;
  line-height: 18px;
  @media screen and (max-width: 360px) {
    font-size: 13px;
    line-height: 15px;
  }
}
.currentRudPriceValue {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin-top: 4px;
  padding-left: 45px;
}
.currentRudPriceValueMob {
  padding-left: 17px;
  font-size: 15px;
  line-height: 18px;
}
.rudPriceValues {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.minimumReceivingRUD {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #99b2c6;
  padding-left: 40px;
}
.minimumReceivingRUDMob {
  padding-left: 17px;
  font-size: 15px;
  line-height: 18px;
  @media screen and (max-width: 360px) {
    font-size: 13px;
    line-height: 15px;
  }
}
.minimumReceivedRudvalue {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin-top: 4px;
  padding-left: 40px;
}
.minimumReceivedRudvalueMob {
  padding-left: 17px;
  font-size: 15px;
  line-height: 18px;
}
.popupButtonsDiv {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}
.popupButtonsDivMob {
  justify-content: space-between;
  margin-top: 40px;
  margin-right: 17px;
}
.cancelSwap {
  border-radius: 4px;
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.035em;
  color: #ffffff;
  margin-right: 20px;
  width: 40.7%;
  height: 50px;
}
.cancelSwap:hover {
  color: #fd5252;
}
.cancelSwapMob {
  color: #fd5252;
  font-size: 16px;
  line-height: 19px;
  width: 50%;
  margin-right: 9px;
  margin-left: 19px;
  height: 40px;
}
.confirmSwap {
  border-radius: 4px;
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.035em;
  color: #ffffff;
  width: 40.7%;
}
.confirmSwap:hover {
  color: #02aab0;
}
.confirmSwapMob {
  color: #02aab0;
  font-size: 16px;
  line-height: 19px;
  width: 50%;
  height: 40px;
}
.note {
  color: #f9b959;
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-top: 25px;
  margin-left: 45px;
  margin-right: 45px;
  text-align: justify;
}
