.contentSellDiv {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.enterTheamountOfRUD {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  padding-top: 32px;
  margin-bottom: 32px;
  margin-left: 32px;
}
.enterTheamountOfRUDMob {
  padding-top: 20px;
  margin-left: 16px;
  margin-bottom: 18px;
  font-size: 15px;
  line-height: 18px;
}
input {
  background: transparent;
  border: none;
  color: #ffffff;
}
.rudpayh3 {
  padding-left: 38px;
  margin-bottom: 0;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #99b2c6;
}
.rudpayh3Mob {
  padding-left: 26px;
  font-size: 16px;
  line-height: 20px;
  font-family: "Metrophobic";
  font-weight: 400;
}

.rudGeth3Result {
  font-family: "Metrophobic";
  font-size: 28px;
  font-weight: 400;
  color: #ffffff;
}

.rudpayDiv {
  height: 86px;
  margin-left: 32px;
  border-radius: 10px;
  border: 1px solid #02aab0;
  margin-right: 34px;
}
.rudgetDiv {
  height: 86px;
  margin-left: 32px;
  border-radius: 10px;
  margin-top: 30px;
  border: 1px solid #02aab0;
  margin-right: 34px;
}
.rudgetDivMob {
  margin-left: 16px;
  margin-right: 16px;
  height: 86px;
  margin-top: 20px;
}
.rudpayDivMob {
  margin-right: 16px;
  margin-left: 16px;
  height: 86px;
}
.rudpayh3Div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 31px;
}
.maxButton {
  padding: 3px 17px;
  margin-top: 10px;
  background-color: transparent;
  border: 1px solid #02aab0;
  color: white;
  font-family: "Jura";
  font-size: 16px;
  border-radius: 5px;
}
.maxButton:hover {
  background: #02aab0;
}
.maxButtonMob {
  width: 47px;
  font-size: 12px;
  height: 18px;
}

.EtherImageDiv {
  position: fixed;
  right: 363px;
  padding-bottom: 20px;
}
.EtherImage {
  position: relative;
  padding-bottom: 20px;
}
.alertinproceed {
  font-size: 12px;
  font-weight: 400;
  color: #fd5252;
  padding: 8px 0 0 86px;
}

.proceedButtonDiv {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 22px;
  margin-right: 42px;
  margin-top: 48px;
}
.proceedButtonDivMob {
  padding-bottom: 21px;
  margin-top: 33px;
  margin-left: 16px;
  margin-right: 16px;
}
.proceedButton {
  padding: 10px 38px;
  color: white;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  border-radius: 4px;
}
.proceedButton:hover {
  color: #02aab0;
  stroke: #02aab0;
}
.proceedButtonMob {
  font-size: 22px;
  line-height: 27px;
  padding: 7px 0px;
  width: 100%;
  color: #02aab0;
}
.proceedIcon {
  padding-left: 6px;
}
.proceedIconMob {
  width: 5px;
}
.accordionContent {
  font-family: "Jura";
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.alertSpan {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #fd5252;
  margin-left: 86px;
  margin-top: 15px;
}
.alertSpanMob {
  margin-left: 36px;
  margin-top: 10px;
}
.accordionContentDiv {
  display: flex;
}
.accordionTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  @media screen and (max-width: 1085px) {
    font-size: 12px;
  }
}
.accordionContent {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
.accordionTitle:hover {
  color: #02aab0;
  fill: #02aab0;
}
.accordionTitleMob {
  font-size: 11px;
}
.accordionTitleDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
.accordionContentLineOne {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 5px;
}
.accordionContentLineOneMob {
  font-size: 11px;
}

.rudGeth3ResultSpanOne {
  padding-left: 38px;
  font-size: 28px;
  font-family: "Metrophobic";
}
.rudGeth3ResultSpan {
  font-family: "Metrophobic";
  font-size: 28px;
  font-weight: 400;
  border: none;
  padding: 0px;
}
.rudpay {
  width: 80%;
  font-family: "Metrophobic";
  font-size: 28px;
  font-weight: 400;
}
.rudpayMob {
  font-family: "Metrophobic";
  font-size: 24px;
  font-weight: 400;
}
.inputFieldDiv {
  display: flex;
  flex-direction: row;
  font-family: "Metrophobic";
  font-size: 28px;
  font-weight: 400;
  width: 100%;
}
.inputFieldDivMob {
  font-family: "Metrophobic";
  font-size: 24px;
  font-weight: 400;
}
.inpurPaySpan {
  margin-left: 39px;
  font-family: "Metrophobic";
  font-size: 28px;
  font-weight: 400;
}
.inputgetDivOneMain {
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 38px;
  font-size: 28px;
  color: rgba(153, 178, 198, 1);
  padding-right: 38px;
}
.inputgetDivOneMainMob {
  margin-left: 26px;
  padding-right: 26px;
}
.inputFieldDivOne {
  margin-left: 38px;
  display: flex;
  margin-top: 4px;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 38px;
}
.inputgetDivOne {
  display: flex;
  flex-direction: row;
  font-family: "Metrophobic";
  font-size: 28px;
}
.inputgetDivMob {
  font-size: 24px;
}
.inputFieldDivOneMob {
  margin-left: 26px;
  font-size: 24px;
  padding-right: 26px;
}
.spanRUDGet {
  font-family: "Metrophobic";
  font-size: 28px;
  font-weight: 400;
  margin: 0px;
}
.spanRUDGetMob {
  font-family: "Metrophobic";
  font-size: 24px;
  font-weight: 400;
}
.accordionItem {
  border: 0.2px solid #a5a2b8;
  border-radius: 5px;
  margin: 15px 31px 0 32px;
  padding-right: 13px;
  padding-left: 23px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.accordionItemMob {
  padding-top: 0px;
  padding-right: 5px;
  padding-left: 5px;
  margin-right: 16px;
  margin-left: 16px;
}
.accordion {
  height: 5px;
}
.kycAlert {
  display: flex;
  justify-content: flex-end;
  padding-right: 300px;
  @media screen and (max-width: 992px) {
    padding: 0px;
  }
}

.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popupContent {
  border-radius: 10px;
  height: auto;
  background: rgba(8, 21, 39, 1);
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
.accordionDivOne {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.feeSection {
  @media screen and (max-width: 338px) {
    width: 29%;
  }
}
.accordionDivOneMob {
  font-size: 9px;
}
.input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
.proceedCurrencyChangeMob {
  padding: 3px 16px;
  font-size: 14px;
  margin: 54px 16px 14px auto;
}

.rudpayh3DivMob {
  margin-right: 26px;
}

input::-webkit-input-placeholder {
  color: #ffffff;
  font-family: "Metrophobic";
  font-size: 28px;
  @media only screen and (max-width: 990px) {
    font-size: 24px;
  }
}
.backButton {
  padding: 10px 45px;
  font-family: "metrophobic";
  color: white;
  font-size: 21px;
  line-height: 26px;
  border-radius: 4px;
  font-weight: 400;
  margin-right: 15px;
}
.backButtonMob {
  color: #fd5252;
  font-size: 22px;
  line-height: 27px;
  padding: 7px 0px;
  width: 100%;
}
.backButton:hover {
  color: #fd5252;
}
