.getRUDAmountEnter {
  margin-left: 32px;
  padding-top: 32px;
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
}
.getRUDAmountEnterMob {
  margin-left: 22px;
  padding-top: 12px;
  padding-right: 10px;
  font-size: 14px;
  line-height: 16px;
}
.payUSD {
  margin-top: 30px;
  border: 1px solid #02aab0;
  border-radius: 10px;
  padding-left: 39px;
  padding-bottom: 15px;
  margin-left: 32px;
  margin-right: 42px;
}
.payUSDMob {
  padding-left: 20px;
  margin-top: 10px;
  margin-left: 22px;
  margin-right: 19px;
  border-radius: 5px;
  padding-bottom: 11px;
}
.dollarSymbol {
  font-family: "roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
}
.dollarSymbolMob {
  font-size: 24px;
  line-height: 28px;
}
.payCryptoDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.payingUSD {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
  padding-bottom: 4px;
  padding-top: 15px;
}
.payingUSDMob {
  padding-top: 11px;
}

input {
  background: transparent;
  border: none;
  color: #ffffff;
  outline: none;
}

.payingUSDValue {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.inputAmount {
  font-family: "roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  width: 100%;
}
.inputAmountMob {
  font-size: 24px;
  line-height: 28px;
}
.payingCrypto {
  font-family: "roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  margin-right: 39px;
}
.payingCryptoMob {
  font-size: 24px;
  line-height: 28px;
  margin-right: 16px;
}
.getRUD {
  border: 1px solid #02aab0;
  border-radius: 10px;
  padding-left: 39px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 30px;
  margin-left: 32px;
  margin-right: 42px;
  margin-bottom: 15px;
}
.getRUDMob {
  margin-top: 20px;
  padding-left: 20px;
  padding-top: 10px;
  margin-left: 22px;
  margin-right: 19px;
  border-radius: 5px;
  padding-bottom: 11px;
}
.recievingRUD {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
  padding-bottom: 4px;
}
.recievingRUDVal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.equivalentRUD {
  font-family: "roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
}
.equivalentRUDMob {
  font-size: 24px;
  line-height: 28px;
}
.cryptoRUD {
  font-family: "roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  margin-right: 39px;
}
.cryptoRUDMob {
  font-size: 24px;
  line-height: 28px;
  margin-right: 16px;
}
.alertBeforeProceed {
  font-size: 12px;
  font-weight: 400;
  color: #fd5252;
  margin-left: 71px;
}
.proceedButtonDiv {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 22px;
  margin-right: 42px;
  margin-top: 38px;
}
.proceedButtonDivMob {
  padding-bottom: 21px;
  margin-right: 21px;
  margin-left: 21px;
  margin-top: 20px;
}
.proceedButton {
  padding: 10px 38px;
  font-family: "metrophobic";
  color: white;
  font-size: 21px;
  line-height: 26px;
  border-radius: 4px;
  font-weight: 400;
}
.proceedButton:hover path,
.proceedButton:hover {
  color: #02aab0;
  fill: #02aab0;
}
.proceedButtonMob,
.proceedButtonMob path {
  color: #02aab0;
  fill: #02aab0;
  font-size: 22px;
  line-height: 27px;
  padding: 7px 0px;
  width: 100%;
}
.alertBeforeProceedMob {
  margin-left: 20px;
}

.accordionContainer {
  border: 0.2px solid #a5a2b8;
  border-radius: 5px;
  margin: 15px 42px 0 32px;
  padding-right: 13px;
  padding-left: 23px;
}
.accordionContainerMob {
  padding-right: 5px;
  padding-left: 5px;
  margin-right: 20px;
  margin-left: 23px;
}
.accordionTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}
.accordionTitleContainerMob {
  padding-bottom: 0px;
}
.accordionTitle {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.accordionTitleMob {
  font-size: 11px;
}
.accordionTitleDetails {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.accordionTitleDetailsMob {
  font-size: 11px;
  line-height: 13px;
}
.accordionContentOne {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-top: 2px;
}
.accordionContentOneMob {
  font-size: 11px;
  line-height: 13px;
}
.detailsDiv {
  display: flex;
}
.detailsDiv:hover,
.detailsDiv:hover path {
  color: #02aab0;
  fill: #02aab0;
}
.accordionContentTwo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-top: 7px;
  padding-bottom: 9px;
}
.accordionContentTwoMob {
  font-size: 9px;
  line-height: 11px;
  padding-top: 8px;
  padding-bottom: 10px;
}
.detailsDiv {
  display: flex;
}
.accordionTitleDetails:hover {
  color: #02aab0;
  fill: #02aab0;
}
.stripeModal {
  background: #081527;
  border-radius: 15px;
  width: 100%;
  height: auto;
  overflow: auto;
}
.rudBuyMainDiv {
  margin-bottom: 30px;
}
.backButton {
  padding: 10px 45px;
  font-family: "metrophobic";
  color: white;
  font-size: 21px;
  line-height: 26px;
  border-radius: 4px;
  font-weight: 400;
  margin-right: 15px;
}
.backButtonMob {
  color: #fd5252;
  font-size: 22px;
  line-height: 27px;
  padding: 7px 0px;
  width: 100%;
}
.backButton:hover {
  color: #fd5252;
}
