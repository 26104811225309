.mainDiv {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 300px;
}

.confirmMessageMainDiv {
  border-radius: 10px;
  width: 621px;
  display: flex;
  flex-direction: column;
  height: 276px;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid #02aab0;
  border-radius: 10px 10px 10px 10px;
}
.confirmMessageMainDivMob {
  width: 100%;
  margin-left: 35px;
  margin-right: 35px;
  height: 200px;
}
.dummyButtonDivMob {
  margin-top: 70px;
}

.confirmMessage {
  display: flex;
  justify-content: space-between;
  padding: 24px 105px 13px 154px;
  border-bottom: 1px solid #02aab0;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 125%;
  letter-spacing: -0.02em;

  color: #ffffff;
}
.confirmMessageMob {
  padding: 20px;
  font-size: 14px;
}
.mobileConfirmMessage {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 11px;
  margin-top: 26px;
}
.confirmMessageContent {
  margin-top: 33px;
  margin-left: 56px;
  margin-right: 36px;
  margin-bottom: 13px;

  font-weight: 300;
  font-size: 18px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #ffffff;
  font-family: "Metrophobic";
}
.confirmMessageContentMob {
  font-size: 14px;
}
.confirmLoader {
  display: flex;
  justify-content: center;
  margin-left: 60px;
  @media only screen and (max-width: 992px) {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
}
.loadIcon {
  background: #181b2c;
  margin-right: 23px;
  padding-top: 23px;
}
.loaderContent {
  padding-top: 23px;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.loaderContentMob {
  margin-top: 51px;
  padding-top: 0px;
}
.loaderList {
  padding-top: 60px;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  padding-left: 16px;
}
.loaderList li {
  display: block;
}
.loaderCircle span {
  position: absolute;
  left: -50px;
  bottom: 22px;
  margin: auto;
  height: 32px;
  width: 32px;
}
.crossIcon {
  position: relative;
  right: -69px;
}
.loaderCircle span::before,
.loaderCircle span::after {
  content: "";
  display: block;
  position: absolute;
  margin: auto;
  height: 35px;
  width: 35px;
  border: 3px solid #02aab0;
  border-radius: 50%;
  opacity: 0;
  animation: loader-6-1 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
}
.loaderCircleMob span::before,
.loaderCircleMob span::after {
  height: 40px;
  width: 40px;
}
.dummyButtonDiv {
  display: flex;
}
@keyframes loader-6-1 {
  0% {
    transform: translate3d(0, 0, 0) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1.5);
    opacity: 0;
  }
}
.loaderCircle span::after {
  -webkit-animation: loader-6-2 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s
    infinite;
  animation: loader-6-2 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s infinite;
}
@keyframes loader-6-2 {
  0% {
    transform: translate3d(0, 0, 0) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 0;
  }
}
.failed {
  color: #ffffff;
  font-family: "Jura";
  margin-left: auto;
  margin-right: auto;
  background: transparent;
  border: 1px solid #02aab0;
  border-radius: 10px;
  margin-bottom: 10px;
}
