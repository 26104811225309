.docButton {
  color: #ffffff;
  background: linear-gradient(90deg, #212d3d 0%, #0e1a2c 100%);
  border-radius: 5px;
  font-family: "Metrophobic";
  font-size: 1.25vw;
  font-weight: 400;
  width: 70.4%;
  height: 45px;
  border: none;
  margin: auto auto 10px auto;
  padding: 0 0;
}
.docButton:hover,
.docButton:hover path,
.docButton:hover rect {
  color: #02aab0;
  fill: #02aab0;
}

.docImage {
  position: -webkit-sticky;
  position: relative;
  right: 6px;
  border-radius: 0px;
  top: 1px;
}
.docImageDiv {
  position: -webkit-sticky;
}
.docsLink {
  display: flex;
  justify-content: center;
}
