.BorrowHeadingText {
  font-family: "Metrophobic";
  font-weight: 400;
  color: #ffffff;
}
.BorrowHeadingTextWeb {
  padding-top: 9px;
  padding-left: 17px;
  font-size: 28px;
  line-height: 35px;
  @media only screen and (max-width: 1143px) {
    padding-left: 10px;
  }
}

.BorrowHeadingTextMob {
  padding: 24px 33px;
  font-size: 21px;
  line-height: 26px;
  @media only screen and (max-width: 555px) {
    padding-left: 11px;
  }
}
.openNewNestButton {
  font-family: "Metrophobic";
  font-weight: 400;
  color: #ffffff;
}
.openNewNestButtonWeb {
  margin-top: 10px;
  margin-right: 18px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 16px;
  line-height: 20px;
  padding: 6px 27px;
}
.openNewNestButtonMob {
  margin-top: 20px;
  margin-right: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 6px 8px;
  font-size: 13px;
  line-height: 16px;
}
.openNewNestButtonWeb:hover {
  color: #02aab0;
}
.titleDiv {
  display: flex;
  justify-content: space-between;
}
.returnUserBorrowTable {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-sizing: border-box;
}
.borrowTableHeading {
  background: #1c2736;
  font-family: "Metrophobic";
  height: 64px;
  font-weight: 400;
  color: #9c99ae;
}
.borrowTableHeadingWeb {
  font-size: 16px;
  line-height: 160%;
  padding-top: 24px;
  padding-bottom: 16px;
}

.borrowTableNest1:hover,
.borrowTableNest2:hover,
.borrowTableNest4:hover,
.borrowTableNest3:hover,
.borrowTableNest5:hover {
  box-shadow: 0px 0px 0px 2px #02aab0 inset;
}
.borrowTableNest5:hover {
  box-shadow: 0px 0px 0px 2px #02aab0 inset;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.borrowTableNest1,
.borrowTableNest3,
.borrowTableNest5 {
  height: 64px;
  font-family: "Metrophobic";
  background: linear-gradient(180deg, #2f3c51 0%, #1f2d44 100%);
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  cursor: pointer;
}
.borrowTableNest2,
.borrowTableNest4 {
  height: 64px;
  background: #1c2736;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  cursor: pointer;
}
.tableHeading {
  width: 100%;
}

td {
  text-align: center;
}
table {
  border-collapse: collapse;
  box-sizing: border-box;
}

.alertContainer {
  display: flex;
  flex-direction: row-reverse;
  width: 330px;
  font-weight: 400;
  line-height: 16px;
  text-align: justify;
  margin: 20px 30.7% 30px auto;
  padding: 14px 10px 10px 8.6px;
  font-family: "metrophobic";
  font-size: 14px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 10px;
  @media only screen and (max-width: 992px) {
    width: auto;
    margin: 20px 0 30px 0;
  }
}
.bellIcon {
  margin-right: 9px;
}
.dueInCounter {
  width: 20%;
}
.accordionTd {
  text-align: left;
}
.rowHidden {
  display: none;
}
