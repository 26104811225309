.KYCMainDiv {
  background: #06152b;
  display: flex;
  justify-content: space-between;
  margin-right: 30.7%;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    margin-right: 15px;
  }
}
.KYCMainDivWeb {
  padding-top: 27px;
}
.KYCDiv {
  font-family: "Metrophobic";
  font-weight: 500;
  color: #ffffff;
}
.KYCDivWeb {
  margin-left: 10px;
  font-size: 16px;
  line-height: 25px;
}
.KYCDivMob {
  font-size: 13px;
  margin-left: 15px;
  margin-top: 20px;
  line-height: 23px;
}
.KYCAMLButton {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
}

.KYCAMLButtonWeb {
  margin-left: 40px;
  border-radius: 8px;
  margin-top: 3px;
  padding: 10px 41px;
  font-size: 14px;
  line-height: 17px;
}
.KYCAMLButtonMob {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 20px;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 17px;
}
.KYCAMLButtonWeb:hover {
  color: #02aab0;
}
.KYCApplicationSubmitted {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  background: linear-gradient(47.47deg, #02aab0 5.98%, #00cdac 85.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 16px;
}
.KYCApplicationSubmittedMob {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
.weWillLetYouKnow {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 34px;
}
.weWillLetYouKnowMob {
  font-size: 14px;
  line-height: 17px;
  padding-left: 10px;
  margin-bottom: 49px;
}
.KYCButtonDivMob {
  align-self: center;
}
.KYCButton {
  background: transparent;
  border: 0.5px solid rgba(255, 255, 255, 1);
  padding: 7px 13px;
  margin-bottom: 22px;
  color: #ffffff;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  border-radius: 3px;
  margin-top: 31px;
  width: 70px;
}

.KYCButtonMob {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
}
.KYCInProgess {
  margin-left: 11px;
}
.statusText {
  color: var(--white, #fff);
  font-family: "Metrophobic";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.statusTextInRed {
  color: var(--red, #fd5252);
}
.rejectedtextContainer {
  margin-left: 51px;
}
.reSubmitKYC {
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border: 1px solid #212c3d;
  padding: 11px 61px;
  color: var(--white, #fff);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Metrophobic";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 41px;
  border-radius: 5px;
  cursor: pointer;
}
.reSubmitKYC:hover {
  color: #02aab0;
}
.toolTipStyle {
  background-color: #384455;
  border-radius: 12px;
  width: 170px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: white;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
  z-index: 5;
}
