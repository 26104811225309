.confirmMessge {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
}
.failedToConnectHeading {
  padding: 24px 32px 24px 32px;

  width: 485px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #ffffff;
  background: #212c3d;
  border-bottom: 2px solid #02aab0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  @media only screen and (max-width: 560px) {
    width: 240px;
  }
  @media only screen and (max-width: 457px) {
    width: 140px;
  }
  @media only screen and (max-width: 342px) {
    width: 100px;
  }
}
.failedToConnectHeadingMob {
  font-size: 14px;
  display: flex;
}
.installMetamask {
  margin-top: 22px;
  margin-left: 33px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.installMetamaskMob {
  font-size: 14px;
}
.getMetamaskLink {
  background: transparent;
  border: none;
  color: #02aab0;
  padding-left: 0px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  cursor: pointer;
}
.cancelButton {
  padding: 8px 43px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  border-radius: 5px;
  color: #ffffff;
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
}

.retryButton {
  padding: 8px 48px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-left: 12px;
  border-radius: 5px;
  color: #ffffff;
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
}
.cancelButtonMob,
.retryButtonMob {
  padding: 5px 19px;
  font-size: 10px;
}

.mainDiv {
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  width: 550px;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  @media only screen and (max-width: 560px) {
    width: 422px;
  }
  @media only screen and (max-width: 457px) {
    width: 322px;
  }
  @media only screen and (max-width: 342px) {
    width: 282px;
  }
}
.installMetamaskLink {
  margin-top: 16px;
}
.installMetamaskIcon {
  margin-left: 8px;
  margin-bottom: -2px;
}
.failedToConnectIcon {
  margin-left: 8px;
  margin-bottom: -2px;
}
.bottomButtonDiv {
  margin-top: 31px;
  display: flex;
  justify-content: flex-end;
  margin-right: 31px;
  margin-bottom: 20px;
}
.failedMessageMainDiv {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 80%;
}
.cancelButton:hover {
  color: #fd5252;
}
.retryButton:hover {
  color: #02aab0;
}
