.selectSlippageTolerance {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  padding-top: 27px;
  margin-left: 51px;
  padding-right: 8px;
  @media screen and (max-width: 1180px) {
    margin-left: 47px;
  }
}
.selectSlippageToleranceMob {
  padding-top: 20px;
  font-size: 14px;
  line-height: 16px;
  margin-left: 16px;
}
.minimumReceivedInRUDDiv,
.payingETHDiv {
  margin-top: 20px;
  margin-left: 51px;
  margin-bottom: 4px;
  @media screen and (max-width: 1180px) {
    margin-left: 47px;
  }
}
.minimumReceivedInRUDDivMob,
.payingETHDivMob {
  margin-left: 16px;
}
.minimumReceivedKey,
.payETH {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
}
.minimumReceivedKeyMob,
.payETHMob {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 5px;
}
.currentPriceInRUD {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
  display: flex;
}
.currentPriceInRUDMob {
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 16px;
}
.userEnteredAmount {
  margin-top: 20px;
  margin-left: 32px;
  justify-content: space-between;
  @media screen and (max-width: 1180px) {
    margin-left: 47px;
  }
}
.userEnteredAmountMob {
  margin-left: 16px;
  margin-right: 30px;
  @media screen and (max-width: 370px) {
    margin-right: 66px;
  }
}
.payingETHValue {
  display: flex;
}
.ethValue {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  padding-right: 6px;
  @media screen and (max-width: 1110px) {
    font-size: 24px;
    line-height: 26px;
  }
}
.ethValueMob {
  font-size: 20px;
  line-height: 24px;
  @media screen and (max-width: 370px) {
    font-size: 14px;
    line-height: 18px;
  }
}
.ethContent {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  @media screen and (max-width: 1110px) {
    font-size: 24px;
    line-height: 26px;
  }
}
.ethContentMob {
  font-size: 20px;
  line-height: 24px;
  @media screen and (max-width: 370px) {
    font-size: 14px;
    line-height: 18px;
  }
}
.equivalentValue {
  display: flex;
  margin-left: 19px;
}

.rudValue {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  @media screen and (max-width: 1110px) {
    font-size: 24px;
    line-height: 26px;
  }
}
.rudValueMob {
  font-size: 20px;
  line-height: 24px;
  @media screen and (max-width: 370px) {
    font-size: 14px;
    line-height: 18px;
  }
}
.rudContent {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  margin-left: 5px;
  @media screen and (max-width: 1110px) {
    font-size: 24px;
    line-height: 26px;
  }
}
.rudContentMob {
  font-size: 20px;
  line-height: 24px;
  @media screen and (max-width: 370px) {
    font-size: 14px;
    line-height: 18px;
  }
}
.questionIconDiv {
  margin-right: 4px;
  margin-top: 2px;
}
.questionIconDivMob {
  margin-top: 0px;
  margin-left: 2px;
}
.questionIcon {
  width: 16px;
  height: 16px;
}
.questionIconMob {
  width: 10px;
  height: 10px;
}
.slippageToleranceDiv {
  display: flex;
  margin-left: 32px;
  margin-top: 27px;
  @media screen and (max-width: 1180px) {
    margin-left: 34px;
  }
}
.slippageToleranceDivMob {
  margin-left: 16px;
}
.slippageToleranceIconAndKeyDiv {
  display: flex;
  width: 50%;
  @media screen and (max-width: 350px) {
    width: 60%;
  }
}
.tooltipIconDiv {
  margin: auto 4px auto 0px;
}
.slippageTolerance {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
  margin-top: 5px;
}
.slippageToleranceMob {
  font-size: 14px;
  line-height: 16px;
  margin-top: 12px;
}
.rudPriceDiv {
  display: flex;
  margin-top: 10px;
}
.minimumRecievedDiv {
  margin-left: 50px;
}
.minimumRecievedDivMob {
  margin-left: 16px;
  margin-right: 27px;
  @media screen and (max-width: 370px) {
    margin-right: 15px;
  }
}
.minimumReceivedKey {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
}
.minimumReceivedKeyMob {
  font-size: 14px;
  line-height: 16px;
  @media screen and (max-width) {
    margin-top: -3px;
  }
  margin-top: 4px;
}
.minimumReceivedVal {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  @media screen and (max-width: 1110px) {
    font-size: 20px;
    line-height: 24px;
  }
}
.minimumReceivedValMob {
  font-size: 20px;
  line-height: 24px;
  @media screen and (max-width: 370px) {
    font-size: 14px;
    line-height: 18px;
  }
}
.priceImpactDiv {
  margin-top: 11px;
}

.priceImpactKey {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
}
.priceImpactKeyMob {
  font-size: 14px;
  line-height: 16px;
  margin-top: 4px;
}
.priceImpactVal {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #99b2c6;
}
.priceImpactValMob {
  font-size: 16px;
  line-height: 19px;
  margin-top: 4px;
}
.radeFeesDiv {
  margin-top: 17px;
  @media screen and (max-width: 375px) {
    margin-top: 12px;
  }
}

.radeFeesKey {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
}
.radeFeesKeyMob {
  font-size: 14px;
  line-height: 16px;
  margin-top: 3px;
}
.radeFeesVal {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #99b2c6;
}
.radeFeesValMob {
  font-size: 16px;
  line-height: 19px;
  margin-top: 3px;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
  padding-bottom: 20px;
  margin-right: 24px;
}
.buttonsMob {
  margin-right: 21px;
  margin-left: 21px;
  justify-content: center;
  margin-top: 20px;
}
.cancelButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: white;
  padding: 7px 52px;
  border-radius: 4px;
  margin-right: 18px;
  @media screen and (max-width: 1180px) {
    padding: 6px 40px;
  }
}
.cancelButtonMob {
  padding: 3px 20px 6px 20px;
  width: 50%;
  font-size: 19px;
  line-height: 21px;
  margin-right: 10px;
  color: #fd5252;
}
.cancelButton:hover {
  color: #fd5252;
}
.swapButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: white;
  padding: 7px 46px;
  border-radius: 4px;
  @media screen and (max-width: 1180px) {
    padding: 6px 33px;
  }
}
.swapButtonMob,
.swapButtonMob path {
  color: #02aab0;
  fill: #02aab0;
  padding: 6px 35px;
  width: 50%;
  font-size: 19px;
  line-height: 21px;
}
.swapButton:hover,
.swapButton:hover path {
  color: #02aab0;
  fill: #02aab0;
}
.proceedIcon {
  padding-left: 7px;
}
.proceedIconMob {
  width: 5px;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 40%;
  height: auto;
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
.minimumReceivedDiv {
  display: flex;
}
.iconAndKeyDiv {
  display: flex;
}
input[type="radio"] {
  appearance: none;
  content: "";
  width: 11px;
  height: 11px;
  border: 1px solid #02aab0;
  border-radius: 50%;
  opacity: 0.6;
  transition: 0.3s;
}

input[type="radio"]:hover {
  background: #02aab0;
  border-radius: 50%;
  border: 1px solid #ffffff;
  width: 11px;
  cursor: pointer;
  height: 11px;
}
input[type="radio"]:checked {
  background: #02aab0;
  border-radius: 50%;
  display: block;
  border: 1px solid #ffffff;
  width: 11px;
  height: 11px;
}
.tolranceError {
  display: flex;
  justify-content: flex-end;
}
.alertTolerance {
  font-size: 12px;
  font-weight: 400px;
  color: #fd5252;
  margin-right: 30px;
  margin-bottom: 10px;
}
.alertToleranceMob {
  margin-left: 20px;
  margin-bottom: 8px;
}
.slippageToleranceInfoKeys {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  margin-top: 34px;
  width: 50%;
  @media screen and (max-width: 1180px) {
    margin-left: 34px;
  }
  @media screen and (max-width: 350px) {
    width: 60%;
  }
}
.slippageToleranceInfoVal {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 15px;
  // @media screen and (max-width: 1110px) {
  //   margin-top: 47px;
  // }
  @media screen and (max-width: 350px) {
    width: 40%;
    // margin-top: 55px;
  }
}
.slippageToleranceInfoKeysMob {
  margin-left: 16px;
}
.slippageToleranceInfoDiv {
  display: flex;
}
.minimunReceivedIconAndKeyDiv {
  display: flex;
  margin-top: 18px;
}
.priceImpactIconAndKeyDiv {
  display: flex;
  margin-top: 14px;
}
.radeFeesIconAndKeyDiv {
  display: flex;
  //margin-top: 17px;
}
.priceImpactIconAndKeyDivMob {
  margin-top: 10px;
}
.tooltip {
  background-color: #384455;
  opacity: 1;
  white-space: normal;
  border-radius: 12px;
  width: 176px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  padding: 10px 4px;
  font-family: "Roboto";
}
.activeLabelClassOptionOne {
  padding: 10px 17px 8px 4px;
  display: flex;
  gap: 5px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 8px;
  border: 1px solid #02aab0;
  cursor: pointer;
  background: linear-gradient(
    47.47deg,
    rgba(2, 170, 176, 0.2) 5.98%,
    rgba(0, 205, 172, 0.2) 85.82%
  );
  @media screen and (max-width: 375px) {
    gap: 2px;
    padding: 8px 10px 5px 8px;
    line-height: 17px;
  }
}
.paymentOptionOneWeb {
  display: flex;
  gap: 5px;
  padding: 10px 17px 8px 4px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #02aab0;
  @media screen and (max-width: 375px) {
    gap: 0px;
    padding: 8px 10px 5px 8px;
    line-height: 17px;
  }
}
.swapButtonContentDiv {
  display: flex;
}
.swapImage {
  margin-top: 3px;
}
.swapImageMob {
  margin-top: 1px;
}

.toolTipStyle1 {
  background-color: #384455;
  border-radius: 12px;
  width: 170px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}
.toolTipStyle2 {
  background-color: #384455;
  border-radius: 12px;
  width: 215px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}
.toolTipStyle3 {
  background-color: #384455;
  border-radius: 12px;
  width: 250px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}
.toolTipStyle4 {
  background-color: #384455;
  border-radius: 12px;
  width: 242px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}
.slippageToleranceMainDiv {
  margin-bottom: 30px;
}
.questionIcon:hover {
  cursor: pointer;
}
