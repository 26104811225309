.form {
  font-family: "Metrophobic";
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.submit {
  padding: 10px;
  font-family: "Metrophobic";
  width: 100%;
  margin: 0;
  color: #00cdac;
  border: 2px solid rgba(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 4px;
}

.cancel {
  font-family: "Metrophobic";
  padding: 10px;
  width: 100%;
  margin: 0;
  color: #fd5252;
  border: 2px solid rgba(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 4px;
}

.label {
  display: block;
  font-size: 21px;
}
.labelCard {
  display: block;
  font-size: 16px;
  color: #cecece;
}

.flex {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
