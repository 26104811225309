.currencyChooseDiv {
  display: flex;
  flex-direction: row;
  gap: 24px;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    gap: 20px;
    margin-right: 20px;
  }
}
.alertSpan {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  color: #fd5252;
  margin-top: 8px;
}
.label {
  margin-left: 6px;
  cursor: pointer;
}
.radioButton {
  border: 1px solid #02aab0;
  border-radius: 8px;
  font-family: "Metrophobic";
  font-size: 16px;
  display: flex;
  gap: 4px;
  line-height: 17px;
  cursor: pointer;
  padding: 10px 11px;
  @media only screen and (max-width: 992px) {
    width: 93%;
  }
}

.activeRadioButton {
  display: flex;
  gap: 4px;
  border: 1px solid #02aab0;
  border-radius: 8px;
  line-height: 17px;
  font-family: "Metrophobic";
  font-size: 16px;
  cursor: pointer;
  padding: 10px 11px;
  background: linear-gradient(
    47.47deg,
    rgba(2, 170, 176, 0.2) 5.98%,
    rgba(0, 205, 172, 0.2) 85.82%
  );
  @media only screen and (max-width: 992px) {
    width: 93%;
  }
}
input[type="radio"] {
  appearance: none;
  content: "";
  width: 11px;
  height: 11px;
  border: 1px solid #02aab0;
  border-radius: 50%;
  opacity: 0.6;
  transition: 0.3s;
}
