.personalInformationMainDiv {
  background: #06152b;
}
.butttonContent {
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 30.7%;
}
.butttonContentMob {
  margin-right: 0%;
}
.stepsButton1 {
  border: 1px solid #02aab0;
  background: transparent;
  padding: 3px 26px;
  border-radius: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: #ffffff;
  margin-left: 16px;
}
.stepsButton1Mob {
  padding: 1px 17px;
}
.stepsButton2 {
  border: 1px solid #02aab0;
  background: transparent;
  padding: 3px 26px;

  border-radius: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: #ffffff;
}
.stepsButton2Mob {
  padding: 1px 17px;
}
.stepsButton3 {
  padding: 3px 26px;
  background: transparent;
  border: 1px solid #02aab0;
  border-radius: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: white;
}
.stepsButton3Mob {
  padding: 1px 17px;
}
.line {
  border-bottom: 1px solid #02aab0;
  width: 100%;
}
.stepsButton4 {
  padding: 3px 26px;
  background: transparent;
  border: 1px solid #02aab0;
  border-radius: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: white;
}
.stepsButton5 {
  padding: 3px 26px;
  border: 1px solid #02aab0;
  background: #02aab0;
  border-radius: 5px;
  margin-right: 21px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
}
.stepsButton4Mob,
.stepsButton5Mob {
  padding: 1px 17px;
}
.gasTrackerContainer {
  position: fixed;
  bottom: 10px;
  right: 10px;
  left: 10px;
}
.step1Text {
  font-family: "Jura";
  font-weight: 400;
  color: #99b2c6;
}
.step1TextWeb {
  margin-left: 21px;
  margin-top: 22px;
  font-size: 16px;
  line-height: 19px;
}
.step1TextMob {
  margin-left: 25px;
  margin-top: 25px;
  font-size: 15px;
  line-height: 18px;
}
.personalInfo {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  color: #02aab0;
}
.personalInfoWeb {
  margin-left: 21px;
  margin-top: 9px;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 37px;
}
.personalInfoMob {
  margin-top: 3px;
  margin-left: 25px;
  margin-bottom: 0px;
  font-size: 13px;
  line-height: 16px;
}
.firstName,
.lastName,
.email,
.phoneNum {
  font-family: "Metrophobic";
  font-weight: 500;
  color: #ffffff;
}

.firstNameWeb,
.lastNameWeb,
.emailWeb,
.phoneNumWeb,
.DOBWeb {
  margin-top: 28px;
  margin-left: 36px;
  font-size: 14px;
  line-height: 112.4%;
  @media screen and (max-width: 1156px) {
    margin-left: 16px;
  }
}
.firstNameMob,
.lastNameMob,
.phoneNumMob,
.emailMob,
.DOBMob {
  font-size: 12px;
  line-height: 112.4%;
  margin-left: 25px;
  margin-bottom: 6px;
  margin-top: 20px;
}
.firstNameInputWeb,
.lastNameInputWeb,
.emailInputWeb,
.PhoneNumberInputWeb,
.DOBInputWeb {
  padding-left: 36px;
  border-radius: 8px;
  margin-top: 6px;
  padding-bottom: 16px;
  width: 306px;
  background: transparent;
  @media screen and (max-width: 1381px) {
    width: 250px;
  }
  @media screen and (max-width: 1156px) {
    width: 200px;
    padding-left: 16px;
  }
}
.firstNameInputMob,
.lastNameInputMob,
.emailInputMob,
.PhoneNumberInputMob,
.DOBInputMob {
  padding: 17px 0px 15px 15px;
  width: 100%;
}
.firstNameInput::placeholder,
.lastNameInput::placeholder,
.emailInput::placeholder,
.PhoneNumberInput::placeholder,
.DOBInput::placeholder,
.DOBInputField::placeholder {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 112.4%;
  color: #8d98aa;
}

.inputDOBDiv {
  border: 1px solid rgba(2, 170, 176, 1);
  border-radius: 10px;
  margin-left: 21px;
  width: 347px;
  @media screen and (max-width: 1381px) {
    width: 289px;
  }
  @media screen and (max-width: 1156px) {
    width: 218px;
  }
}
.inputDOBDivMob {
  width: auto;
}
.inputNameDiv,
.emailPhoneDiv {
  display: flex;
  gap: 29px;
  margin-bottom: 30px;
}
.emailPhoneDiv {
  margin-top: 25px;
}
.inputNameDivMob,
.emailPhoneDivMob {
  flex-direction: column;
  gap: 0px;
  margin-bottom: 0px;
  margin-right: 20px;
}

.inputFirstNameDiv,
.inputLastNameDiv,
.emailDiv,
.inputPhoneDiv {
  border: 1px solid rgba(2, 170, 176, 1);
  border-radius: 10px;
  margin-left: 21px;
}
.inputField {
  padding-top: 18px;
}
.calenderButton {
  background: transparent;
  border: none;
  margin-top: 10px;
}
.calenderIcon {
  bottom: 96px;
  position: absolute;

  @media screen and (max-width: 992px) {
    position: inherit;
    width: 13px;
    height: 13px;
  }
}
.DOBdiv {
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
}
.cancelButton,
.nextButton {
  font-family: "Metrophobic";
  font-weight: 400;
  color: #ffffff;
}
.cancelButtonWeb {
  font-size: 16px;
  line-height: 20px;
  padding: 11px 60px;
  border-radius: 4px;
}
.cancelButtonWeb:hover {
  color: #fd5252;
}
.nextButtonWeb {
  padding: 11px 61px;
  font-size: 16px;
  border-radius: 4px;
}
.nextButtonWeb:hover {
  color: #02aab0;
}
.cancelButtonMob {
  padding: 11px 0px;
  width: 50%;
}
.nextButtonMob {
  padding: 11px 0px;
  width: 50%;
}
.bottomButtonDiv {
  display: flex;
  justify-content: flex-end;
  margin-right: 30.7%;
  gap: 20px;
  margin-top: 29px;
  margin-bottom: 30px;
}
.bottomButtonDivMob {
  margin-left: 20px;
  margin-right: 20px;
}
.arrowInNextButton {
  margin-left: 6px;
}
.calendarDiv {
  position: absolute;
  margin-bottom: 30px;
}
.error {
  margin-top: 16px;
  margin-left: 22px;
  font-size: 14px;
  line-height: 17px;
  color: #fd5252;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
.DOBInputField::placeholder,
.DOBInputWeb::placeholder,
.DOBInputFieldWeb::placeholder {
  font-family: "Metrophobic";
  font-weight: 500;
  color: #8d98aa;
  font-size: 14px;
  line-height: 112.4%;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}
.inputBoxContainerEmail {
  display: flex;
  gap: 29px;
  @media screen and (max-width: 992px) {
    flex-direction: column;
    margin-right: 20px;
    gap: 0px;
  }
}
.thisWillBeYourUniqueUserName {
  color: var(--orange, #f9b959);
  font-family: "Metrophobic";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 136.4%;
  margin-top: -26px;
  margin-bottom: 18px;
  margin-left: 24px;
  width: 347px;
  @media screen and (max-width: 1381px) {
    width: 298px;
  }
  @media screen and (max-width: 1214px) {
    // width: 226px;
  }
  @media screen and (max-width: 992px) {
    margin-top: 6px;
    margin-right: 10px;
  }
}
input {
  font-family: "metrophobic";
}
.CountryCodeSelect {
  background: transparent;
  border: none;
  width: auto;
  margin-left: 10px;
  font-family: "Metrophobic";
  font-weight: 500;
  color: #ffffff;
  font-size: 12px;
}
.chooseYourBankACcountType {
  font-family: "Metrophobic";
  font-weight: 500;
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 10px;
}
.PhoneNumberInputWeb {
  padding-left: 6px;
  width: 265px;
  @media screen and (max-width: 1381px) {
    width: 211px;
  }
  @media screen and (max-width: 1156px) {
    width: 146px;
  }
}
.PhoneNumberInputMob {
  width: 65%;
}
option {
  padding: 0;
}
.radioButton {
  margin-bottom: 25px;
  margin-left: 21px;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popupContent {
  border-radius: 10px;
  height: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
