.getSignerMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.getSignerMainContainerWeb {
  padding-top: 143px;
  padding-bottom: 119px;
  padding-left: 65px;
  padding-right: 65px;
}
.getSignerMainContainerMob {
  padding-top: 100px;
  padding-bottom: 119px;
  padding-left: 45px;

  padding-right: 45px;
}
.confirmTheSignatureRequestText {
  font-family: "Jura";
  font-weight: 400;
  line-height: 30px;
  color: #ffffff;
  margin-top: 22px;
  text-align: center;
}
.confirmTheSignatureRequestTextWeb {
  font-size: 25px;
}
.confirmTheSignatureRequestTextMob {
  font-size: 20px;
}
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.confirmSignerTransactionIcon {
  opacity: 0;
  animation: appear 1s ease-in-out forwards;
}
