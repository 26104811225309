.stakeTitleContainer {
  display: flex;
  flex-direction: row;
}
.stakeHeadingText {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
  padding-top: 9px;
  padding-left: 32px;
  padding-bottom: 7px;
}
.stakeHeadingTextMob {
  font-size: 21px;
  line-height: 26px;
  line-height: 28px;
  padding-top: 10px;
  padding-left: 21px;
  padding-bottom: 5px;
}
.stakeContentText {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  color: #f9b959;
  padding-top: 25px;
  padding-left: 32px;
  padding-right: 30px;
}
.stakeContentTextMob {
  font-size: 13px;
  line-height: 16px;
  padding-left: 21px;
  padding-top: 16px;
  padding-right: 10px;
}
.stakeEarnReward {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  padding-top: 15px;
  padding-left: 32px;
  padding-right: 20px;
}
.stakeEarnRewardMob {
  font-size: 13px;
  line-height: 16px;
  padding-top: 16px;
  padding-left: 5px;
  padding-right: 8px;
  padding-bottom: 15px;
}
.stakeButtonsDiv {
  padding-top: 19px;
  display: flex;
  justify-content: space-between;
  margin-right: 18px;
  padding-bottom: 19px;
}
.stakeButtonsDivMob {
  padding-top: 0px;
  display: block;
  margin-right: 16px;
  margin-left: 16px;
  padding-bottom: 18px;
}
.buyRUDButton {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: white;
  padding: 10px 40px;
  border-radius: 8px;
  margin-right: 20px;
}
.buyRUDButton:hover {
  color: #02aab0;
}
.buyRUDButtonMob {
  font-family: "metrophobic";
  padding: 8px 0px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  color: #02aab0;
  margin-right: 16px;
  width: 50%;
}
.stakeButton {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: white;
  padding: 10px 50px;
  border-radius: 8px;
}
.stakeButton:hover {
  color: #02aab0;
}
.stakeButtonMob {
  font-family: "metrophobic";
  padding: 8px 0px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  color: #02aab0;
  width: 50%;
}
.questionIconDiv {
  margin: 17px auto 14px 11px;
}
.questionIconDivMob {
  margin: 15px auto 10px 8px;
}
.questionIcon {
  width: 20px;
  height: 20px;
}
.questionIconMob {
  width: 16px;
  height: 16px;
}
.questionIcon:hover {
  cursor: pointer;
}
.alertBeforeDeposit {
  font-size: 12px;
  line-height: 14px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  color: #fd5252;
  margin-top: 12px;
  margin-left: -30px;
}
.alertBeforeDepositMob {
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  color: #fd5252;
  margin: 11px 13px auto auto;
}
.alert {
  justify-self: flex-start;
  margin-left: 80px;
}
.mobButtonsDiv {
  display: flex;
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 30px;
  padding-bottom: 19px;
}
.gasTrackerContainer {
  position: fixed;
  bottom: 40px;
  margin: 0px;
  right: 28px;
  left: 28px;
  width: auto;
}
.cardComponent {
  margin-top: 0px;
}
.returnUserComponent {
  margin-top: 40px;
  margin-bottom: 40px;
}
.returnLiquidityProvider {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #f9b959;
  padding-top: 41px;
  padding-left: 32px;
  padding-right: 20px;
}
.returnLiquidityProviderMob {
  font-size: 12px;
  line-height: 19px;
  padding-left: 16px;
  padding-top: 27px;
  padding-right: 8px;
}
.toolTipStyle {
  background-color: #384455;
  border-radius: 12px;
  width: 290px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}
.buttons {
  display: flex;
  justify-content: flex-end;
}
.buttonsAfterStake {
  justify-content: flex-end;
  @media screen and (max-width: 992px) {
    justify-content: center;
  }
}
.buyRudButtonWithoutBalance {
  width: 100%;
  margin-right: 0px;
}
