.BorrowHeadingText {
  padding: 17px 32px;
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  font-family: "Metrophobic";
}

.BorrowHeadingTextMob {
  padding: 10px 14px;
  font-family: "Jura";
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}
.questionmarkImageInHeader {
  padding-left: 8px;
}
.contentText {
  padding: 21px 25px 0 32px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 22px;
  color: #f9b959;
  text-align: justify;
}
.LearnMoreConatiner {
  padding: 40px 0 0 32px;
}
.LearnMoreConatinerMob {
  padding: 30px 0 10px 0px;
}
.borrowButtonDiv {
  margin-bottom: 19px;
  margin-right: 21px;
}
.borrowButtonDivMob {
  margin-bottom: 14px;
  margin-right: 0px;
}
.borrowButton {
  margin-top: -10px;
  padding: 10px 34px;
  color: #ffffff;
  font-family: "Metrophobic";
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  border-radius: 4px;
}
.borrowButtonMob {
  padding: 6px 0px;
  width: 100%;
  font-size: 18px;
  border-radius: 5px;
  font-weight: 400;
  width: 100%;
  line-height: 30px;
  margin-left: 0px;
}
.borrowButton:hover {
  color: #02aab0;
}
.LearnMore {
  font-family: "Metrophobic";

  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  color: #ffffff;
}
.LearnMoreMob {
  font-size: 12px;
  line-height: 14px;
}
.LearnMoreConatinerDiv {
  display: flex;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 127.19%;
  flex-direction: row;
  justify-content: space-between;
}
.LearnMoreConatinerDivMob {
  display: block;
  font-size: 13px;
}
.nest {
  color: #00cdac;
  margin-left: 6px;
}
.questionmarkImage {
  margin-left: 9px;
  margin-top: 3px;
}
.questionmarkImage:hover {
  cursor: pointer;
}
.questionmarkImageMob {
  width: 14px;
  margin-top: 0px;
}
.nestText {
  color: #02aab0;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
}
.nestTextMob {
  font-family: "Metrophobic";

  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.BorrowContentMob {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 13px;
  padding-left: 13px;
  padding-top: 27px;
  padding-right: 16px;
}
.LearnMoreConatinerMob {
  padding-left: 16px;
  padding-right: 13px;
  justify-content: center;
}
.gasTrackerContainer {
  position: fixed;
  bottom: 20px;
  margin: 0px;
  right: 28px;
  left: 28px;
  width: auto;
}
.tooltip {
  background-color: #384455;
  opacity: 1;
  white-space: normal;
  border-radius: 12px;
  width: 176px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  padding: 10px 4px;
  font-family: "Roboto";
}
.toolTipStyle {
  background-color: #384455;
  border-radius: 12px;
  width: 192px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}
.learnMoreAndLink {
  display: flex;
}
