.toggleButtonDiv {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
}
.toggleButtonDivMob {
  margin-top: 11px;
  margin-bottom: 11px;
}
.toggleButton {
  background: transparent;
  border: 1px solid #02aab0;
  border-radius: 10px;
  padding: 6px 19px;
}
.toggleButtonMob {
  padding: 3px 19px;
}
.toggleButton:hover {
  background: #02aab0;
  cursor: pointer;
}
.toggleIcon {
  width: 16px;
  height: 16px;
}
.payETH {
  border: 1px solid #02aab0;
  border-radius: 10px;
  padding-left: 39px;
  padding-bottom: 11px;
  margin-top: 37px;
  margin-left: 32px;
  margin-right: 42px;
}
.payETHMob {
  padding-left: 20px;
  margin-top: 10px;
  margin-left: 22px;
  margin-right: 19px;
  border-radius: 5px;
  padding-bottom: 7px;
}
.payCryptoDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.payingEth {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
  padding-bottom: 4px;
  padding-top: 15px;
}
.payingEthMob {
  padding-top: 11px;
}
.maxButton {
  padding: 3px 17px;
  font-family: "Metrophobic";
  background: transparent;
  border: 1px solid #02aab0;
  box-shadow: 0 0 2px #02aab0;
  color: white;
  font-size: 16px;
  line-height: 19px;
  border-radius: 5px;
  font-weight: 400;
  margin-right: 31px;
  margin-top: 10px;
}
.maxButton:hover {
  background: #02aab0;
  cursor: pointer;
}
.maxButtonMob {
  font-size: 12px;
  line-height: 14px;
  margin-top: 11px;
  padding: 2px 12px;
  margin-right: 16px;
}
input {
  background: transparent;
  border: none;
  color: #ffffff;
}

.inputAmount {
  align-self: flex-start;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 37px;
  color: #ffffff;
  width: 100%;
}
.inputAmountMob {
  font-size: 24px;
  line-height: 34px;
}
.payingCrypto {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  margin-right: 39px;
}
.payingCryptoMob {
  font-size: 24px;
  line-height: 28px;
  margin-right: 16px;
}

.getRUD {
  border: 1px solid #02aab0;
  border-radius: 10px;
  padding-left: 39px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 11px;
  margin-left: 32px;
  margin-right: 42px;
  margin-bottom: 15px;
}
.getRUDMob {
  padding-left: 20px;
  padding-top: 11px;
  margin-left: 22px;
  margin-right: 19px;
  border-radius: 5px;
  padding-bottom: 11px;
}
.recievingRUD {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
  padding-bottom: 4px;
}
.recievingRUDVal {
  display: flex;
  flex-direction: row;
}
.equivalentRud {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #99b2c6;
  width: 100%;
}
.equivalentRudMob {
  font-size: 24px;
  line-height: 28px;
}
.cryptoRud {
  font-family: "roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  margin-right: 39px;
}
.cryptoRudMob {
  font-size: 24px;
  line-height: 28px;
  margin-right: 16px;
}
.dollarSymbol {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
}
.dollarSymbolMob {
  font-size: 24px;
  line-height: 28px;
}
.userEnteringField {
  display: flex;
}
.ethPriceInfoDiv {
  display: flex;
}
.price {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-right: 4px;
}

.rudPerEthPriceVal {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
.rudPerEthPriceKey {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-left: 5px;
}
.accordionDiv {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: 72px;
  margin-right: 42px;
  @media screen and (max-width: 992px) {
    margin-left: 45px;
    margin-right: 19px;
  }
}
.note {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #f9b959;
}
