.uploadIconDiv {
  display: flex;
  justify-content: center;
  margin-top: 75px;
}
.uploadIconDivMob {
  margin-top: 35px;
}
.uploadIcon {
  width: 92px;
  height: 92px;
}
.uploadIconMob {
  width: 40px;
  height: 40px;
}
.borrowSuccessful {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
  text-align: center;
  margin-top: 15px;
}
.borrowSuccessfulMob {
  font-size: 21px;
  line-height: 25px;
  margin-top: 14px;
}
.viewTransactionDetails {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #02aab0;
  margin-top: 4px;
  text-align: center;
}
.closeButtonDiv {
  margin-top: 48px;
  margin-bottom: 70px;
  display: flex;
  justify-content: center;
}
.closeButtonDivMob {
  margin-left: 26px;
  margin-right: 26px;
  margin-top: 36px;
  margin-bottom: 40px;
}
.closeButton {
  padding: 9px 85px;
  font-family: "Metrophobic";
  color: white;
  font-size: 24px;
  line-height: 27px;
  border-radius: 4px;
  font-weight: 400;
  letter-spacing: 0.035em;
}
.closeButton:hover {
  color: #02aab0;
}
.closeButtonMob {
  padding: 5px 70px;
  color: #02aab0;
}
.redirectToEtherScan:visited {
  color: #02aab0;
}
