.kycButton {
  height: 24px;
  width: 57px;
  border-radius: 5px;
  background: linear-gradient(90deg, #212d3d 0%, #0e1a2c 100%);
  border: none;
  font-family: "Metrophobic";
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  text-align: left;
  @media screen and (min-width: 1500px) {
    width: 65px;
  }
  @media screen and (min-width: 1680px) {
    width: 74px;
    font-size: 16px;
  }
  @media screen and (min-width: 1740px) {
    width: 80px;
    font-size: 22px;
    height: 30px;
  }
}

.kycPending {
  position: fixed;
  margin: auto auto auto 5px;
  height: 15px;
  width: 15px;
  @media screen and (min-width: 1500px) {
    margin: auto auto auto 7px;
  }
  @media screen and (min-width: 1680px) {
    margin: 2px auto auto 9px;
  }
  @media screen and (min-width: 1740px) {
    height: 20px;
    width: 19px;
    margin: 3px auto auto 5px;
  }
}
.toolTipStyle {
  background-color: #141515;
  border-radius: 12px;
  width: 120px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: rgba(253, 82, 82, 1);
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
  z-index: 5;
}
