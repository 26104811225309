.profileConfirmPopUp {
  background-color: #17263d;
}
.profileConfirmPopUpWeb {
  width: 577px;
  height: 280px;
}
.profileConfirmPopUpMob {
  width: 100%;
}
.confirmMessage {
  font-family: "Jura";
  font-weight: 700;
  color: #ffffff;
  text-align: center;
}
.confirmMessageWeb {
  padding-top: 80px;
  padding-bottom: 77px;
  font-size: 21px;
  line-height: 123.8%;
  letter-spacing: 0.035em;
}
.confirmMessageMob {
  padding-top: 30px;
  font-size: 12px;
  padding-bottom: 30px;
}
.buttonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 37px;
}
.cancelButton {
  font-family: "Jura";
  font-weight: 700;

  color: #fd5252;
}
.cancelButton:hover {
  background: #fd5252;
  border: 1px solid #fd5252;
  color: #ffffff;
}

.cancelButtonWeb {
  padding: 9px 72px;
  font-size: 24px;
  line-height: 123.8%;
  letter-spacing: 0.035em;
}
.cancelButtonMob {
  padding: 0px;
  height: 30px;
  width: 40%;
}
.submitButton {
  font-family: "Jura";
  font-weight: 700;
  color: #00cdac;
}
.submitButtonWeb {
  padding: 8px 71px;
  font-size: 24px;
  line-height: 123.8%;
  letter-spacing: 0.035em;
  margin-left: 19px;
}
.submitButtonMob {
  font-size: 20px;
  width: 40%;
  height: 30px;
  margin-left: 10px;
}
.submitButton:hover {
  background: #00cdac;
  border: 1px solid #00cdac;
  color: #ffffff;
}
