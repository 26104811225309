.getRUDAmountEnter {
  margin-left: 32px;
  padding-top: 25px;
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
}
.getRUDAmountEnterMob {
  padding-right: 10px;
  margin-left: 22px;
  padding-top: 12px;
  font-size: 14px;
  line-height: 16px;
}
.proceedButtonDiv {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 24px;
  margin-right: 42px;
  padding-top: 38px;
}
.proceedButtonDivMob {
  padding-bottom: 21px;
  margin-left: 21px;
  margin-right: 21px;
  padding-top: 20px;
}
.proceedButton {
  padding: 10px 38px;
  font-family: "Metrophobic";
  color: white;
  font-size: 21px;
  line-height: 26px;
  border-radius: 4px;
  font-weight: 400;
}
.proceedButton:hover path,
.proceedButton:hover {
  color: #02aab0;
  fill: #02aab0;
}
.proceedButtonMob,
.proceedButtonMob path {
  color: #02aab0;
  fill: #02aab0;
  font-size: 22px;
  line-height: 27px;
  padding: 7px 0px;
  width: 100%;
}
.alertBeforeSwap {
  font-size: 12px;
  font-weight: 400;
  color: #fd5252;
  margin-left: 86px;
}
.alertBeforeSwapMob {
  margin-left: 20px;
}
.proceedIcon {
  padding-left: 6px;
}
.rudBuyMainDiv {
  margin-bottom: 30px;
}
.backButton {
  padding: 10px 45px;
  font-family: "metrophobic";
  color: white;
  font-size: 21px;
  line-height: 26px;
  border-radius: 4px;
  font-weight: 400;
  margin-right: 15px;
}
.backButtonMob {
  color: #fd5252;
  font-size: 22px;
  line-height: 27px;
  padding: 7px 0px;
  width: 100%;
}
.backButton:hover {
  color: #fd5252;
}
