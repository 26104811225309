.crossIconDiv {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  margin-right: 23px;
}
.crossIconDivMob {
  margin-top: 16px;
  margin-right: 15px;
}
.crossIcon,
.crossIcon path {
  @media screen and (max-width: 992px) {
    transform: scale(0.8);
  }
}
.crossIcon:hover path {
  fill: #fd5252;
}
.uploadIconDiv {
  display: flex;
  justify-content: center;
  margin-top: 44px;
}
.uploadIconDivMob {
  margin-top: 10px;
}
.uploadIcon {
  width: 92px;
  height: 92px;
}
.uploadIconMob {
  width: 40px;
  height: 40px;
}
.transactionSubmit {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
  text-align: center;
  margin-top: 15px;
}
.transactionSubmitMob {
  font-size: 21px;
  line-height: 25px;
  margin-top: 14px;
}
.viewTransactionDetails {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #02aab0;
  margin-top: 4px;
  text-align: center;
}
a:link {
  text-decoration: none;
  color: #02aab0;
}
.redirectToEtherScan:visited {
  color: #02aab0;
}
.addRudButtonDiv {
  margin-top: 40px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
}
.addRudButtonDivMob {
  margin-top: 31px;
  margin-top: 30px;
}
.addRudButton {
  padding: 9px 64px;
  font-family: "Metrophobic";
  color: white;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  font-weight: 400;
}
.addRudButton:hover {
  color: #02aab0;
}
.addRudButtonMob {
  padding: 8px 33px;
  color: #02aab0;
}
.addTokenInWallet {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 50px;
  text-align: center;
  color: #d1d1d1;
}
.addTokenInWalletMob {
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 21px;
  margin-left: 43px;
  margin-right: 43px;
}
.alertDeniedTransaction {
  font-size: 12px;
  font-weight: 400px;
  color: #fd5252;
  text-align: center;
  margin-bottom: 10px;
}
.alertDeniedTransactionMob {
  margin-bottom: 15px;
}
.mintSuccessfulDiv {
  display: flex;
  margin-top: 15px;
  justify-content: center;
}
.mintedRudAmount {
  font-family: "Metrophobic";
  color: white;
  font-size: 22px;
  line-height: 25px;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
}
.mintedRudAmountMob {
  font-size: 15px;
  line-height: 19px;
}

.uploadIconDiv {
  opacity: 0;
  animation: appear 1s ease-in-out forwards;
}
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
