.KYCInProgressContainer {
  padding-left: 32px;
  padding-top: 40px;
}
.KYCInProgressContainerMob {
  padding-top: 48px;
  padding-left: 0;
  display: flex;
  flex-direction: column;
}
.KYCApplicationSubmitted {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  background: linear-gradient(47.47deg, #02aab0 5.98%, #00cdac 85.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 16px;
}
.KYCApplicationSubmittedMob {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
.submittedAndWaiting,
.yourAccountVerified {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  color: rgba(249, 185, 89, 1);
}
.yourAccountVerified {
  color: #00cdac;
}
.weWillLetYouKnow {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 34px;
  margin-right: 30.6%;
}
.weWillLetYouKnowMob {
  font-size: 14px;
  line-height: 17px;
  padding-left: 10px;
  margin-bottom: 49px;
}
.KYCButton {
  background: transparent;
  border: 0.5px solid rgba(255, 255, 255, 1);
  padding: 7px 13px;
  margin-bottom: 22px;
  color: #ffffff;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  border-radius: 3px;
  cursor: default;
  width: 70px;
}
.KYCButtonMob {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
}
.statusContainer {
  display: flex;
  gap: 3px;
}
.KYCInProgess {
  margin-left: 11px;
  margin-bottom: -3px;
}
.statusOfKYC {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.statusOfKYCMob,
.submittedAndWaitingMob {
  font-size: 16px;
  line-height: 20px;
}
.KYCButtonDivMob {
  align-self: center;
}
.submittedKYCDetails {
  margin-top: 30px;
  margin-bottom: 50px;
  padding: 20px;
  border-radius: 10px 10px 10px 10px;
  height: auto;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  margin-right: 32.7%;
  border: 1px solid #212c3d;
  margin-left: -20px;
  @media screen and (max-width: 992px) {
    margin-right: 18px;
    display: flex;
    gap: 13px;
    flex-direction: column;
  }
}
.kycDetails {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.kycDetails {
  display: flex;
  justify-content: space-between;
}
.kycHeading,
.kycHeadingMob {
  color: #99b2c6;
  font-family: "Metrophobic";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.kycHeadingMob {
  text-align: right;
}
.kycValue,
.kycValueMob {
  color: #fff;
  font-family: "Roboto";
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.kycValueMob {
  text-align: right;
}
.kycDetails {
  display: grid;
  grid-template-columns: 40% 40% 33%;
  margin-bottom: 10px; /* Add spacing between each set */
  gap: 5px; /* Add a gap between grid items */
  width: 100%; /* Ensure the container takes the full width */
  @media screen and (max-width: 992px) {
    grid-template-columns: 50% 50%;
  }
}
.kycDetailsEmail {
  display: grid;
}
.ID {
  width: 70px;
  height: 70px;
}
.kycDetailContainer {
  display: grid;
  grid-template-columns: 40% 40%;
  @media screen and (max-width: 992px) {
    grid-template-columns: 50% 50%;
  }
}
