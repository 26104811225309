.notificationDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25%;
}
.notificationDivMob {
  justify-content: center;
  margin-right: 0px;
  margin-top: 40px;
}
.alertImageAndMsg {
  display: flex;
  flex-direction: row;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 10px;
  width: 420px;
}
.alertImageAndMsgMob {
  width: 360px;
}
.transactionConfirmContentsDiv {
  display: flex;
  flex-direction: column;
}
.confirmContent {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  margin-top: 30px;
  margin-left: 50px;
}
.confirmContentMob {
  font-size: 21px;
  line-height: 25px;
  margin-top: 20px;
  @media screen and (max-width: 390px) {
    font-size: 16px;
    line-height: 19px;
  }
}
.transactionConfirm {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 17px;
  line-height: 19px;
  color: #d1d1d1;
  margin-left: 50px;
  margin-top: 4px;
  margin-bottom: 30px;
}
.transactionConfirmMob {
  font-size: 14px;
  line-height: 17px;
  margin-top: 8px;
  margin-bottom: 25px;
  @media screen and (max-width: 420px) {
    font-size: 12px;
    line-height: 15px;
  }
}
.alertDeniedTransaction {
  font-size: 15px;
  font-weight: 400px;
  color: #fd5252;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 8px;
}
.alertDeniedTransactionMob {
  margin-top: 8px;
  font-size: 12px;
}
.waitingConfirmation {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  margin-top: 35px;
  margin-left: 50px;
  margin-bottom: 40px;
}
.loaderList {
  display: flex;
  flex-flow: row wrap;
  position: relative;
}
.loaderList li {
  display: block;
}
.loaderCircle span {
  position: absolute;
  top: 30px;
  left: 20px;
  bottom: 30px;
  right: 25px;
  margin: auto;
  height: 32px;
  width: 32px;
}
.loaderCircle span::before,
.loaderCircle span::after {
  content: "";
  display: block;
  position: absolute;
  margin: auto;
  height: 40px;
  width: 40px;
  border: 3px solid #02aab0;
  border-radius: 50%;
  opacity: 0;
  animation: loader-6-1 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
}
.loaderCircleMob span::before,
.loaderCircleMob span::after {
  height: 35px;
  width: 35px;
}

@keyframes loader-6-1 {
  0% {
    transform: translate3d(0, 0, 0) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1.5);
    opacity: 0;
  }
}
.loaderCircle span::after {
  -webkit-animation: loader-6-2 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s
    infinite;
  animation: loader-6-2 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s infinite;
}
@keyframes loader-6-2 {
  0% {
    transform: translate3d(0, 0, 0) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 0;
  }
}
