.crossIconDiv {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  margin-right: 23px;
}
.crossIconDivMob {
  margin-top: 16px;
  margin-right: 15px;
}
.crossIcon {
  width: 27px;
  height: 27px;
}
.crossIconMob {
  width: 17px;
  height: 17px;
}
.uploadIconDiv {
  display: flex;
  justify-content: center;
  margin-top: 70px;
}
.uploadIconDivMob {
  margin-top: 43px;
}
.uploadIcon {
  width: 92px;
  height: 92px;
}
.uploadIconMob {
  width: 40px;
  height: 40px;
}
.transactionSubmit {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
  text-align: center;
  margin-top: 25px;
}
.transactionSubmitMob {
  font-size: 21px;
  line-height: 25px;
  margin-top: 14px;
}
.viewTransactionDetails {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #02aab0;
  margin-top: 8px;
  text-align: center;
}
.closeButtonDiv {
  margin-top: 58px;
  margin-bottom: 69px;
  display: flex;
  justify-content: center;
}
.closeButtonDivMob {
  margin-top: 34px;
  margin-bottom: 42px;
}
.closeButton {
  width: 235.96px;
  height: 48px;
  font-family: "Metrophobic";
  color: #fd5252;
  font-size: 24px;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  line-height: 19px;
  border-radius: 4px;
  font-weight: 400;
}
.closeButtonMob {
  width: 100.96px;
  height: 38px;
}
.redirectToEtherScan:visited {
  color: #02aab0;
}
