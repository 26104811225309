.errorMessageToSwitchNetwork {
  font-family: "Jura";
  padding-bottom: 43px;
  width: 100%;
  position: fixed;
  padding-top: 43px;
  padding-left: 10%;
  background-color: #1b293c;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #fd5252;
  @media screen and (max-width: 1200px) {
    padding-left: 8%;
  }
}
.arrowIcon {
  margin-left: 8px;
}
.switchNetworkButton {
  border: none;
  background: transparent;
  color: #ffffff;
  font-family: "Jura";
  font-style: normal;
  font-weight: 400;
  margin-left: 42px;
  font-size: 16px;
  line-height: 19px;
}
