.ethTable {
  margin-top: 300px;
  margin-right: 300px;
}
.collateralizationheading {
  padding: 12px 0 12px 29px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
}
.collateralizationheadingMob {
  padding: 10px 14px;
  font-family: "Jura";
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}
.questionmarkInHeader {
  margin-left: 8px;
  margin-top: 6px;
  width: 20px;
  height: 20px;
}
.questionmarkInHeader:hover {
  cursor: pointer;
}
.ButttonContent {
  padding-top: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.stepsButton1 {
  padding: 3px 26px;
  background: transparent;
  border-radius: 5px;
  margin-left: 29px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: #99b2c6;
  border: 1px solid #02aab0;
}
.stepsButton1Mob {
  padding: 1px 17px;
  font-size: 16px;
}
.stepsButton2 {
  background: linear-gradient(47.47deg, #02aab0 5.98%, #00cdac 85.82%);
  padding: 3px 26px;
  border-radius: 5px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: #ffffff;
}
.stepsButton2Mob {
  padding: 1px 17px;
  font-size: 16px;
}
.stepsButton3 {
  border: 1px solid #02aab0;
  padding: 3px 26px;
  background: transparent;
  border-radius: 5px;
  margin-right: 21px;
  font-family: "Jura";
  font-weight: 500;
  font-size: 21px;
  color: #99b2c6;
}
.stepsButton3Mob {
  padding: 1px 17px;
  font-size: 16px;
}
.step1 {
  margin-top: 22px;
  margin-left: 29px;
  font-family: "Jura";
  font-weight: 400;
  font-size: 16px;
  color: #99b2c6;
}
.step1SelectBorrow {
  align-items: center;
  gap: 3px;
  line-height: 23px;
  margin-left: 29px;
  margin-top: 9px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
}
.step1SelectBorrowMob {
  font-size: 15px;
  line-height: 23px;
  margin-right: 8px;
}
.collatButton {
  padding: 11px 17px;
  margin-top: 36px;
  margin-left: 29px;
  border-radius: 8px;
  font-family: "Jura";
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  background: transparent;
}
.memberVouchButton {
  padding: 11px 37px;
  margin-top: 36px;
  margin-left: 45px;
  border-radius: 8px;
  font-family: "Jura";
  font-weight: 600;
  font-size: 14px;
  color: #a5a2b8;
  background: transparent;
}
.paymentTerms {
  color: rgba(2, 170, 176, 1);
}
.questionmark {
  margin-left: 10px;
  margin-bottom: -4px;
}
.questionmark:hover {
  cursor: pointer;
}
.questionmarkMob {
  width: 16px;
  margin-bottom: -5px;
}
.arrowSymbol {
  margin-left: 6px;
}
.alertSpan {
  margin-top: 14px;
  margin-left: 50px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #fd5252;
}
.alertSpanMob {
  margin-top: 10px;
  margin-left: 18px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}
.alertSpanLoanTerm {
  margin-top: 14px;
  margin-left: 368px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #fd5252;
  @media screen and (max-width: 1245px) {
    margin-left: 313px;
  }
  @media screen and (max-width: 1150px) {
    margin-left: 264px;
  }
  @media screen and (max-width: 1036px) {
    margin-left: 211px;
  }
}
.alertSpanLoanTermMob {
  margin-top: 10px;
  margin-left: 170px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  @media screen and (max-width: 992px) {
    margin-left: 214px;
  }
  @media screen and (max-width: 382px) {
    margin-left: 152px;
  }
  @media screen and (max-width: 464px) {
    margin-left: 197px;
  }
  @media screen and (max-width: 430px) {
    margin-left: 175px;
  }
  @media screen and (max-width: 382px) {
    margin-left: 156px;
  }
}
.alertSpanPaymentTerm {
  margin-top: 14px;
  margin-left: 50px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #fd5252;
}
.alertSpanPaymentTermMob {
  margin-top: 10px;
  margin-left: 18px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}
.bottomSectionButton {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 150px;
  margin-right: 21px;
  padding-bottom: 21px;
  margin-left: 0;
}
.bottomSectionButtonMob {
  margin-left: 20px;
  margin-right: 20px;
}
.nextButton {
  padding: 10px 55px;
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #ffffff;
  border-radius: 6px;
}
.nextButtonMob {
  padding: 7px 0px;
  width: 50%;
}
.nextButton:hover {
  color: #02aab0;
  stroke: #02aab0;
}
.cancelButton {
  padding: 10px 52px;

  color: #ffffff;
  border-radius: 6px;
  margin-right: 12px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
}
.cancelButtonMob {
  padding: 7px 0px;
  width: 50%;
}
.cancelButton:hover {
  color: #fd5252;
}
hr {
  position: fixed;
  width: 52%;
  top: 206px;
}
.alertContainer {
  display: flex;
  flex-direction: row-reverse;
  width: 310px;
  font-weight: 400;
  line-height: 16px;
  margin: 12px 30.7% 30px auto;
  padding: 14px 10px 10px 8.6px;
  font-family: "Metrophobic";
  font-size: 14px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 10px;
}
.alertContainerMob {
  width: 239px;
  font-weight: 400;
  font-size: 11px;
  font-family: "Metrophobic";
  line-height: 12.89px;
  margin-right: auto;
  margin-left: auto;
  @media only screen and (max-width: 992px) {
    margin-right: 0;
    margin-left: auto;
  }
  @media only screen and (max-width: 450px) {
    margin-right: auto;
    margin-left: auto;
  }
}
.line {
  border-bottom: 1px solid #02aab0;
  width: 100%;
}
.bellIcon {
  margin-right: 8.73px;
}
.selectDiv {
  display: flex;
  margin-top: 30px;
  margin-left: 29px;
  gap: 15px;
}
.selectDivMob {
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;
  margin-left: 18px;
}
.tooltip {
  background-color: #384455;
  opacity: 1;
  white-space: normal;
  border-radius: 12px;
  width: 176px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  padding: 10px 4px;
  font-family: "Roboto";
}
.toolTipStyle {
  background-color: #384455;
  border-radius: 12px;
  width: 188px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}
