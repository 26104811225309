.popUpContent {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #f9b959;
  border-radius: 10px;
  margin-right: 0px;
  padding-top: 68px;
  padding-left: 49px;
  border: none;
  padding-right: 49px;
}
.youHaveToWaitUntilTheVestingPeriod {
  color: #ffffff;
}
.cancelButton {
  height: 48px;
  width: 50%;
  color: #ffffff;
  border-radius: 4px;
  font-family: "metrophobic";
  font-size: 24px;
}
.cancelButton:hover {
  color: #fd5252;
}
.cancelButtonMob {
  width: 100%;
  color: #fd5252;
  height: 40px;
  font-size: 20px;
}
.unstakeButton {
  height: 48px;
  width: 50%;
  color: #ffffff;
  border-radius: 4px;
  font-family: "metrophobic";
  font-size: 24px;
}
.unstakeButton:hover {
  color: #fd5252;
}
.unstakeButtonMob {
  color: #fd5252;
  height: 40px;
  font-size: 20px;
}
.popUpDivMob {
  width: 100%;
  font-size: 13px;
  font-family: "Jura";
}
.popUpContentMob {
  text-align: center;
  padding-top: 41px;
  font-size: 19px;
  font-family: "Metrophobic";
  padding-left: 28px;
  padding-right: 28px;
}
.popUpButtonDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 35px;
  margin-left: 58px;
  margin-right: 58px;
}
.popUpButtonDivMob {
  margin-top: 45px;
  padding-bottom: 26px;
  margin-left: 22px;
  margin-right: 22px;
}
