.popUpContent {
  color: #ffffff;
  font-family: "Metrophobic";
  font-weight: 500;
  border-radius: 0 0 10px 10px;
  margin-right: 0px;
  padding-top: 30.88px;
  width: 650px;
}
.KYCNotVerifiedContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding-top: 53px;
  padding-bottom: 56px;
}
.cancelButton {
  padding: 8px 72px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 123.8%;
  letter-spacing: 0.035em;
  border: 1px solid #212c3d;
  color: #ffffff;
  margin-right: 20px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
}
.cancelButton:hover {
  color: #fd5252;
}
.cancelButtonMob {
  padding: 10px 0px;
  width: 40%;
  font-size: 16px;
}
.completeKYCButton {
  padding: 8px 34px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 123.8%;
  letter-spacing: 0.035em;
  border: 1px solid #212c3d;
  color: #ffffff;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
}
.completeKYCButton:hover {
  color: #02aab0;
}
.completeKYCButtonMob {
  padding: 10px 0px;
  width: 40%;
  font-size: 16px;
}
.popUpDivMob {
  width: 100%;
  font-size: 13px;
  font-family: "Jura";
}
.popUpContentMob {
  text-align: left;
  font-size: 13px;
  font-family: "Metrophobic";

  width: 100%;
}
.popUpButtonDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 44px;
}
