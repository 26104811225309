.walletConnectDisplay {
  display: flex;
  justify-content: center;
  margin-top: 300px;
}
.logoNameContainer {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}
.radeLogo {
  width: 33px;
  height: 45px;
}
.radeLogo:hover {
  cursor: pointer;
}
.radeHeading {
  width: 75px;
  height: 45px;
}
.radeHeading:hover {
  cursor: pointer;
}
.walletConnectContainer {
  width: 409px;
  height: 192px;
  border-radius: 10px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
}
.buttonContainer {
  display: flex;
  justify-content: center;
}
.button {
  background: transparent;
  border: none;
  cursor: pointer;
}
.connectMetamaskButton {
  border-radius: 10px;
  border: 1px solid var(--Green-grad, #02aab0);
  width: 207px;
  margin-top: 23px;
  padding: 10px 14px;
  display: flex;
  transition: box-shadow 0.3s ease;
}
.connectMetamaskButton:hover {
  box-shadow: 0 0 10px 0 #02aab0;
  border: 1px solid #02aab0;
}
.metamaskLogo {
  width: 26px;
  height: 23px;
}
.connectText {
  margin-left: 17px;
  color: #fff;
  font-family: "Metrophobic";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
