.titleContainer {
  background: #212c3d;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 2px solid #02aab0;
}

.bodyContainer {
  border-radius: 0px 0px 10px 10px;
  height: auto;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
}
.rudMainDiv {
  margin-right: 30.7%;
}
.rudMainDivMob {
  margin-right: 0px;
}
