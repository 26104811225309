.homescreenDiv {
  margin-bottom: 30px;
  //to be Removed
}
.homescreenDivMob {
  width: auto;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 0px;
}
.mainHeading {
  font-family: jura;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #e2e2e2;
  margin-top: 10px;
  margin-bottom: 40px;
  margin-right: 342px;
}
.mainheadMob {
  margin-top: 80px;
  margin-bottom: 30px;
  margin-right: 0px;
  text-align: center;
}
.subHeading {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #e2e2e2;
  margin-right: 30.7%;
  @media screen and (min-width: 1900px) {
    font-size: 21px;
  }
}
.subheadMob {
  font-size: 16px;
  line-height: 22px;
  margin-right: 0px;
  text-align: left;
}
.titleMob::after {
  content: "\a";
  white-space: pre;
}
.buttonDiv {
  display: flex;
  margin-right: 30.7%;
  justify-content: space-between;
  margin-top: 26px;
  gap: 33px;
  @media screen and (max-width: 1160px) {
    gap: 18px;
  }
}
.buttonDivMob {
  margin-top: 20px;
  justify-content: center;
  margin-right: 0px;
  gap: 13px;
}
.borrow {
  background: url("../../assets/borrowButtonBgImage.svg");
  background-repeat: no-repeat;
  background-position: left -71px top -80px;
  backdrop-filter: blur(25px);
  border: 0.1px solid rgba(105, 105, 105, 0.44);
  border-radius: 5px;
  width: 100%;
  height: 80px;
  cursor: pointer;
}
.borrow:hover {
  box-shadow: 0px 0.4px 5px rgba(2, 170, 176, 0.7);
}
.borrowButtonContent {
  margin: auto;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #bdf3ff;
  @media screen and (max-width: 1160px) {
    font-size: 15px;
    line-height: 21px;
  }
}
.borrowButtonContentMob {
  font-size: 15px;
  line-height: 25px;
}
.borrowMob {
  width: 100%;
  height: 67px;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
}
.providor {
  background: url("../../assets/stakeButtonBgImage.svg");
  background-repeat: no-repeat;
  background-position: left -71px top -80px;
  backdrop-filter: blur(25px);
  border: 0.1px solid rgba(105, 105, 105, 0.44);
  border-radius: 5px;
  width: 100%;
  height: 80px;
  cursor: pointer;
}
.providor:hover {
  box-shadow: 0px 0.4px 5px rgba(2, 170, 176, 0.7);
}
.providerButtonContent {
  margin: auto;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #bdf3ff;
  @media screen and (max-width: 1160px) {
    font-size: 15px;
    line-height: 21px;
  }
}
.providerButtonContentMob {
  font-size: 15px;
  line-height: 25px;
}
.providorMob {
  width: 100%;
  height: 67px;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
}
.getRUD {
  background: url("../../assets/getRudButtonBgImage.svg");
  background-repeat: no-repeat;
  background-position: left -71px top -80px;
  backdrop-filter: blur(25px);
  border: 0.1px solid rgba(105, 105, 105, 0.44);
  border-radius: 5px;
  width: 100%;
  height: 80px;
  cursor: pointer;
}
.getRUD:hover {
  box-shadow: 0px 0.4px 5px rgba(2, 170, 176, 0.7);
}
.getRudButtonContent {
  margin: auto;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #bdf3ff;
  @media screen and (max-width: 1160px) {
    font-size: 15px;
    line-height: 21px;
  }
}
.protocolDiv {
  margin-top: 44px;
  margin-bottom: 30px;
  margin-right: 30.7%;
}
.protocolMob {
  margin: 53px auto 40px auto;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}
.protocolFirstRow {
  border-bottom: 2px solid #02aab0;
  background: #212c3d;
  border-radius: 10px 10px 0px 0px;
  font-size: 28px;
  font-family: "Metrophobic";
  line-height: 35px;
  color: #ffffff;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 23px;
}
.firstrowMob {
  font-size: 28px;
  line-height: 33px;
  padding-top: 10px;
  padding-left: 40px;
  padding-bottom: 6px;
  @media screen and (max-width: 355px) {
    font-size: 25px;
    line-height: 30px;
    padding-left: 30px;
  }
}
.protocolSecondRow {
  font-family: "metrophobic";
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  border-radius: 0px 0px 10px 10px;
  padding-top: 6px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media screen and (min-width: 1900px) {
    font-size: 19px;
  }
}
.secondrowMob {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  padding-bottom: 21px;
  padding-top: 9px;
  @media screen and (max-width: 355px) {
    font-size: 10px;
    line-height: 12px;
    padding-bottom: 25px;
    padding-top: 15px;
  }
}
.borrowDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.totalLockedValue {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 15px;
}
.nests {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.rudSupply {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.rudRadePool {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.stakedRud {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.collateralRatio {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column1 {
  padding-left: 23px;

  @media screen and (max-width: 355px) {
    padding-left: 18px;
  }
}
.column1Mob {
  padding-left: 14px;
}
.column2 {
  padding-right: 23px;
}
.column2Mob {
  padding-right: 14px;
}
.quesIcon {
  width: 16px;
  height: 16px;
  margin-bottom: -3px;
  padding-left: 2.99px;
  @media screen and (max-width: 375px) {
    width: 10px;
    height: 10px;
  }
}
.quesIcon:hover {
  cursor: pointer;
}
.firstContent::after {
  content: "\a";
  white-space: pre;
}
.returnUserAfterRudBuy {
  display: flex;
  margin-top: -8px;
  margin-bottom: 29px;
}
.userRudBalanceValue {
  font-family: "metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
}
.userRudBalanceKey {
  font-family: "Jura";
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  color: #387d6d;
  margin-right: 24px;
}
.portfolioDiv {
  margin-bottom: 30px;
}
.column1 .tooltip {
  background-color: #384455;
  opacity: 1;
  white-space: normal;
  border-radius: 10px;
  width: 176px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  padding: 10px 4px;
  font-family: "Roboto";
}
.column1Mob.tooltipMob {
  font-weight: 200;
  width: 168px;
  padding: 8px 15px;
}

.toolTipStyle1 {
  background-color: #384455;
  border-radius: 12px;
  width: 202px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}
.toolTipStyle2 {
  background-color: #384455;
  border-radius: 12px;
  width: 192px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}
.toolTipStyle3 {
  background-color: #384455;
  border-radius: 12px;
  width: 213px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}
.toolTipStyle4 {
  background-color: #384455;
  border-radius: 12px;
  width: 192px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}
.toolTipStyle5 {
  background-color: #384455;
  border-radius: 12px;
  width: 248px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}
