.slidebarContainer {
  width: 360px;
  margin-top: 7px;
  margin-right: 24px;
}

.slidebar {
  width: 100%;
  height: 7px;
  background-color: #ddd;
  position: relative;
  border-radius: 5px;
}

.slidebarThumb {
  width: 15px;
  height: 15px;
  background-color: #02aab0;
  border-radius: 50%;
  position: absolute;
  cursor: grab;
  user-select: none;
  margin-top: -4px;
}
.tooltip {
  position: absolute;
  top: -30px; /* Adjust the positioning as needed */
  background-color: #02aab0;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
}
.sliderValues {
  display: flex;
  justify-content: space-between;
}
.startValue,
.endValue {
  font-size: 15px;
  font-family: "Metrophobic";
  margin-top: 3px;
}
