.buttonTableDiv {
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}
.buttonTableDivMob {
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}
.buttonHeadingDiv {
  background: #334453;
  border: 1px solid #334453;
  border-radius: 10px 10px 0px 0px;
  height: 62px;
}
.buySellButtons {
  padding-left: 32px;
  padding-top: 17px;
  padding-bottom: 12px;
}
.buySellButtonsMob {
  padding-left: 22px;
  padding-top: 11px;
  padding-bottom: 10px;
}
.buyRudButton {
  border: 1px solid #02aab0;
  padding: 2.5px 42px;
  font-family: "Metrophobic";
  color: white;
  font-size: 26px;
  line-height: 31px;
  border-radius: 5px;
  font-weight: 400;
  margin-right: 23px;
  background: transparent;
  box-shadow: 0 0 2px #02aab0;
}
.buyRudButton:hover {
  cursor: pointer;
}
.buyRudButtonMob {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding: 5px 28px;
  margin-right: 15px;
}
.sellRudButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
  color: #ffffff;
  border-radius: 5px;
  border: 1px solid #02aab0;
  background: transparent;
  padding: 2.5px 42px;
  box-shadow: 0 0 2px #02aab0;
}

.sellRudButtonMob {
  font-size: 16px;
  line-height: 20px;
  padding: 5px 28px;
  margin-right: 15px;
}
.sellRudButton:hover {
  cursor: pointer;
}
.currencyChoose {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  padding-top: 25px;
  padding-left: 32px;
  padding-right: 10px;
}
.currencyChooseMob {
  font-size: 15px;
  line-height: 18px;
  padding-left: 22px;
  padding-right: 3px;
  padding-top: 20px;
}
.currencyOptionsDiv {
  padding-top: 25px;
  padding-left: 32px;
}
.currencyOptionsDivMob {
  padding-top: 16px;
  padding-left: 22px;
}

.proceedButtonDiv {
  margin-top: 21px;
  padding-bottom: 20px;
  margin-right: 24px;
  display: flex;
  justify-content: flex-end;
}
.proceedButtonDivMob {
  margin-top: 0px;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 20px;
  margin-left: 22px;
  margin-right: 19px;
}
.proceedButton {
  padding: 10px 38px;
  font-family: "Metrophobic";
  color: white;
  font-size: 21px;
  line-height: 26px;
  border-radius: 4px;
  font-weight: 400;
}
.proceedIcon {
  padding-left: 6px;
}
.proceedButton:hover path,
.proceedButton:hover {
  color: #02aab0;
  fill: #02aab0;
}
.proceedButtonMob,
.proceedButtonMob path {
  color: #02aab0;
  fill: #02aab0;
  font-size: 22px;
  line-height: 27px;
  padding: 7px 0px;
  width: 100%;
}
.liveEthRudBuy {
  margin-top: 422px;
  padding-right: 300px;
}
.liveEthRudBuyMob {
  padding-right: 0px;
  margin-top: 255px;
  margin-bottom: 36px;
}
::placeholder {
  color: #ffffff;
  font-family: "Metrophobic";
  font-size: 28px;
}

.alertSpan {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  color: #fd5252;
}
.KycAlertDiv {
  display: flex;
  margin: 12px 30.7% 30px auto;
  width: 296.36px;
  height: 66px;
  border-radius: 10px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  align-items: center;
  @media screen and (max-width: 992px) {
    margin-left: auto;
    margin-right: 0;
  }
  @media screen and (max-width: 474px) {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
}
.BellIconImage {
  width: 25.88px;
  height: auto;
  padding-left: 13.95px;
}
.BellIconSpan {
  padding-left: 8.17px;
  font-size: 14px;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  z-index: 2;
}
.popupContent {
  position: relative;
  border-radius: 15px;
  overflow: auto;
}
.cancelButton {
  padding: 10px 45px;
  font-family: "metrophobic";
  color: white;
  font-size: 21px;
  line-height: 26px;
  border-radius: 4px;
  font-weight: 400;
  margin-right: 15px;
}
.cancelButtonMob {
  color: #fd5252;
  font-size: 22px;
  line-height: 27px;
  padding: 7px 0px;
  width: 100%;
}
.cancelButton:hover {
  color: #fd5252;
}
.dummyMemberVouchRadioButton {
  display: flex;
  border-radius: 6px;
  border: 1px solid #02aab0;
  padding: 8px 50px 8px 11px;
  gap: 6px;
  margin-left: 30px;
  cursor: pointer;
}
.dummyMemberVouchRadioButtonMob {
  margin-left: 0px;
  width: 92%;
  padding: 8px 0px 8px 11px;
}
.circle {
  border-radius: 50%;
  border: 0.5px solid #02aab0;
  width: 10px;
  height: 10px;
  display: block;
  margin-top: 6px;
}
.memberVouchButton {
  color: #808fa5;
}
.toolTipStyleForMemberVouch {
  background-color: #384455;
  border-radius: 12px;
  width: 100px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}
.currencyOptionsDiv {
  display: flex;
}
