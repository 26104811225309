.popUpDiv {
  background: #334453;
  border-radius: 10px 10px 0px 0px;
  padding-top: 16px;
  padding-left: 28px;
  font-size: 15px;
  font-family: "Jura";
  font-weight: 500;
  padding-bottom: 13px;
  color: #ffffff;
}
.popUpContent {
  width: 424px;
  text-align: center;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 123.8%;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-right: 0px;
  padding-top: 68px;
  padding-left: 62px;
  padding-right: 62px;
  border-radius: 10px;
  padding-bottom: 40px;
}
.popUpContentDiv {
  background: rgba(8, 21, 39, 1);
  border-radius: 10px;
}
.cancelButton {
  padding: 9px 78px;
  font-family: "Metrophobic";
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  font-weight: 400;
  font-size: 24px;
  line-height: 123.8%;
  color: #ffffff;
  border-radius: 4px;
}
.cancelButtonMob {
  padding: 9px 0px;
  width: 50%;
  font-size: 20px;
  color: #fd5252;
}
.cancelButton:hover {
  color: #fd5252;
}
.completeKYCButton {
  padding: 9px 95px;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  color: #ffffff;
  border-radius: 4px;
  margin-left: 20px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 123.8%;
}
.completeKYCButton:hover {
  color: #02aab0;
}
.completeKYCButtonMob {
  padding: 9px 0px;
  width: 50%;
  font-size: 20px;
  color: #02aab0;
}
.popUpDivMob {
  width: 100%;
  font-size: 13px;
  font-family: "Jura";
}
.popUpContentMob {
  text-align: center;
  font-size: 13px;
  font-family: "metrophobic";
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 68px;
  width: auto;
}
.popUpButtonDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px 27px 35px 27px;
}
