.logoContainer {
  display: flex;
  justify-content: space-between;
  margin-right: auto;
  width: 79%;
  height: 140px;
  background-color: #081527;
}
.headerSpinner {
  justify-content: center;
  width: 100%;
  margin-right: 0px;
}
.logoNameContainer {
  cursor: pointer;
  display: flex;
}
.connectMetamask {
  position: fixed;
  right: 54px;
}
.logoContainerMob {
  height: 87px;
  width: 100%;
}
.addressContainer {
  display: flex;
}
.radeLogo {
  margin: 52px 0 auto 40px;
  width: 27px;
  height: 36px;
  transform: scale(1.8);
}
.logoM {
  width: 23px;
  height: 31px;
  margin: 35px 0 auto 32px;
}
.radeHeading {
  font-family: "jura";
  font-weight: 400;
  font-size: 2.5vw;
  line-height: 43px;
  margin: 47px 0 auto 13px;
}
.radeheadMob {
  font-size: 21px;
  line-height: 28px;
  margin: 28px 0 auto 9px;
}
.notificationBarEnterActive {
  display: block;
  transition: 2s ease;
  top: 0%;
}

.notificationBarEnter {
  display: block;
  top: 2px;
  transition: 0.5s ease;
}
.notificationBarExitActive {
  display: block;
  transition: 0.5s ease;
  top: 0px;
}
.notificationBarExit {
  display: block;
  transition: 0.5s ease;
  top: 0%;
}
.userStatsDiv {
  width: 21.3%;
  display: flex;
  position: fixed;
  right: 15px;
  border-left: 1px solid #343339;
  height: 100%;
}
.profileImage {
  margin: 39px 12px auto auto;
}
.userNameDiv {
  margin-top: 44px;
  display: flex;
  flex-direction: column;
}
.userKyc {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}
.redirectToLandingPage {
  text-decoration: none;
}
.redirectToLandingPage:visited {
  color: white;
}
.spinnerOverlay {
  height: 30vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinnerContainer {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid rgb(148, 148, 189);
  border-radius: 50%;
  border-top-color: rgb(64, 64, 64);
  animation: spin 1.5s ease-in-out infinite;
  -webkit-animation: spin 1.5s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}
