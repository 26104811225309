.profileName {
  font-family: "Metrophobic";
  font-weight: 400;
  line-height: 26px;
  color: #f3f2f8;
  margin-right: 7px;
  @media screen and (min-width: 993px) {
    font-size: 16px;
  }
  @media screen and (min-width: 1050px) {
    font-size: 18px;
  }
  @media screen and (min-width: 1180px) {
    font-size: 20px;
  }
  @media screen and (min-width: 1300px) {
    font-size: 21px;
  }
  @media screen and (min-width: 1500px) {
    font-size: 23px;
    margin-right: 12px;
  }
  @media screen and (min-width: 1680px) {
    margin-right: 15px;
  }
  @media screen and (min-width: 1740px) {
    margin-right: 24px;
  }
}
.profileNameMob {
  font-size: 16px;
  line-height: 20px;
}
.profileNameDiv {
  position: fixed;
  top: 206px;
  right: 6.9%;
  height: 19px;
  width: 5%;
}
.kycButton {
  height: 24px;
  width: 57px;
  left: 1270px;
  top: 235px;
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  background: rgba(6, 21, 43, 1);
  border: 0.5px solid #ffffff;
  font-family: Jura;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  margin-left: 0px;
  color: #ffffff;
}
.kycButtonDiv {
  width: 200px;
  margin-right: 20px;
  width: 4%;
}
.kycPending {
  position: fixed;
  top: 239px;
  z-index: 2;
  height: 17px;
  width: 17px;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popupContent {
  border-radius: 10px;
  height: auto;
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
.toolTipStyle {
  background-color: #384455;
  border-radius: 12px;
  width: 117px;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: #02aab0;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
  z-index: 3;
}
