.detailsIcon {
  margin-left: 2px;
  margin-top: -3px;
  width: 12px;
}

.detailsIcon2 {
  width: 14px;
  margin-top: 1.5px;
}
